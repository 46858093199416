import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';

export function ProcessingHero() {
  return (
    <section className="full-section background hero light-blue-bg">
      <div className="container">
        <div className="row px-2">
          <div className="col-lg-8 mx-auto bg-white rounded shadow-sm text-center p-4">
            <div id="blue-logo" className="my-4 mx-auto">
              <StaticImage src="../../images/shared/Milan-Logo-Blue.webp" imgClassName="" alt="Mlan Laser Logo" />
            </div>
            <h2 className="subheadsm">Grab Your Phone, We’re Calling You!</h2>
            <p className="my-4">We are so excited to speak with you and to answer any questions that you have about laser hair removal. During regular business hours you can expect to hear from us in about 15 minutes.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ProcessingHero