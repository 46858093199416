import React, { useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SearchResults from './SearchResults'
import { useClickOutside } from '../../hooks'

function SearchContainer({ hits, searchInput, searchView, setSearchView, searchHandler }) {

  const searchRef = useRef(null)
  const closeSearchView = () => setSearchView(false)
  const searchNode = useClickOutside(closeSearchView)

  useEffect(() => {
    if (searchView) searchRef.current.focus()
  }, [searchView])

  return (
    <section id="search-container" className={`d-none d-lg-block ${searchView ? 'show' : ''}`}>
      <div ref={searchNode} id="search-wrapper" className={`pt-2 pb-4 position-relative`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11 col-xxl-10">
              <div className="row justify-content-between align-items-center">
                <div className="col col-lg-6">
                  <Form.Control
                    value={searchInput} onChange={searchHandler} ref={searchRef}
                    className="header-search-input" type="search" placeholder="What Can We Help You Find?" />
                </div>
                <div className="col-auto">
                  <figure className="mb-0 pointer" onClick={closeSearchView}>
                    <FontAwesomeIcon icon={faXmark} />
                  </figure>
                </div>
              </div>
              <div className="row mt-4">
                {
                  hits.length > 0 ?
                    <div className="col">
                      {
                        hits[0].loading ? <p className="searching milan-text-primary">Searching</p>
                          : hits[0].message ?
                            <div>
                              <p className="milan-text-red milan-fp">{hits[0].message}</p>
                              <SearchResults type="Popular" clickHandler={closeSearchView} />
                            </div>
                            :
                            <SearchResults type="Suggested" hits={hits} clickHandler={closeSearchView} />
                      }
                    </div>
                    :
                    <div className="col">
                      <SearchResults type="Popular" clickHandler={closeSearchView} />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SearchContainer