import React from "react";

export function PostTreatmentHero() {
  return (
    <section className="hero background pb-0 pb-sm-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 offset-xl-1 col-xl-5">
            <h1 className="smalltitle milan-text-tur pb-0">Milan Laser</h1>
            <h1 className="milan-text-primary"> Post-Treatment Instructions</h1>
            <h2 className="milan-text-primary pt-3 subheadsm">Redness/Swelling</h2>
            <p>
              Immediately after treatment there may be redness and swelling of each hair follicle in the treatment site. Swelling may last 4 hours or
              longer, the redness may last up to 2-3 days. The treated area may feel warm similar to a mild sunburn for a few hours after treatment.
            </p>
            <h2 className="milan-text-primary subheadsm">Make-up</h2>
            <p>There are no restrictions on makeup but be gentle (as if you had a sunburn) for the first 24-48 hours.</p>
          </div>
        </div>
      </div>
      <div className="spacer d-sm-none"></div>
    </section>
  );
}

export default PostTreatmentHero;
