import React from 'react'
import { Formik, Form } from 'formik'
import { FormikControl, signUpInitialValues as initialValues, signUpSchema as validationSchema, submitSignUpForm as onSubmit, SignUpConsentMsg, FormSubmitting, FormFailed, SignUpSuccess, SubmitBtn } from '..'

export function AreasSignUpForm({ siteData }) {
  initialValues.location = siteData.currentMetro.stores[0].salesforceValue
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(user, props) => onSubmit(user, props)}
      validateOnChange={false}>
      {
        formik => {
          return (
            <Form className="w-100" >
              {formik.isSubmitting ? <FormSubmitting /> : formik.status === 'Success' ? <SignUpSuccess /> : formik.status === 'Failed' ? <FormFailed />
                :
                <div className="row">
                  <div className={`mb-3 ${siteData.currentMetro.stores.length > 1 ? 'col-12' : 'col-sm-8'}`}>
                    <FormikControl control="emailInput" name="email" placeholder="youremail@mailbox.com" />
                  </div>
                  {
                    siteData.currentMetro.stores.length > 1 &&
                    <div className="col-sm-8">
                      <FormikControl control="selectLocation" name="location" milanStores={siteData.milanStores} />
                    </div>
                  }
                  <div className="col-sm-4 mt-3 mt-sm-0 text-center">
                    <SubmitBtn formik={formik} />
                  </div>
                  <div>
                    <p className={`consent-msg text-center milan-text-white mb-0 ${siteData.currentMetro.stores.length > 1 ? 'mt-3' : ''}`}>
                      No Spam • Never Shared with 3rd Parties <br />  <SignUpConsentMsg />
                    </p>
                  </div>
                </div>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default AreasSignUpForm