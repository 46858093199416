import React from 'react';
const BE_READY_IMG =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FBeReady.webp?alt=media&token=c0e50340-85d4-4920-b1e3-e25a3a28670b';
const PERMANENT_RESULT_IMG =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FPermanentResults.webp?alt=media&token=8bd920a4-d3fe-4f7a-bbff-b6fcd2ddf7b6';
const UNLIMITED_PACKAGE_IMG =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FUnlimited-Package.webp?alt=media&token=25e0a8b8-51c5-4280-878b-64cc2ecb5d5e';
const GENTLE_IMG =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FGentle.webp?alt=media&token=b2e24e46-6fd3-483a-8488-0c518bfb603c';

export function WhyLHRIdeal() {
  return (
    <section
      id='why-lhr-ideal'
      className='milan-py-lg hr-divider hr-divider-turq-light'
    >
      <div className='container'>
        <h2 className='subheadlg milan-text-primary text-center pb-0'>
          Why Milan Laser Hair Removal Is Ideal
        </h2>
        <div className='row justify-content-center gy-5 g-4 g-lg-5 mt-0'>
          <div className='col-md-6 col-lg-5'>
            <div className='row justify-content-center gy-3'>
              <div className='col-md-5 col-xl-4 text-center'>
                <img
                  src={BE_READY_IMG}
                  alt='Be Ready. Always.'
                  className='mx-xxl-2'
                />
              </div>
              <div className='col-md-7'>
                <p className='mb-0 text-center text-md-start'>
                  <strong>Be Ready. Always.</strong>
                  <br /> Last minute appointments and the waxing-waddle will be
                  a distant memory. You’ll always be prepared with smooth skin
                  after laser hair removal.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-5'>
            <div className='row justify-content-center gy-3'>
              <div className='col-md-5 col-xl-4 text-center'>
                <img
                  src={PERMANENT_RESULT_IMG}
                  alt='Milan Laser Hair Removal Permanent Results'
                  className='mx-xxl-2'
                />
              </div>
              <div className='col-md-7'>
                <p className='mb-0 text-center text-md-start'>
                  <strong>Permanent Results.</strong>
                  <br /> No more shaving or painful waxing. Ever. Smooth skin is
                  here to stay.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-5'>
            <div className='row justify-content-center gy-3'>
              <div className='col-md-5 col-xl-4 text-center'>
                <img
                  src={UNLIMITED_PACKAGE_IMG}
                  alt='Milan Laser Unlimited Package'
                  className='mx-xxl-2'
                />
              </div>
              <div className='col-md-7'>
                <p className='mb-0 text-center text-md-start'>
                  <strong>Unlimited Package.</strong>
                  <br /> Have a rogue hair that didn’t get the memo about being
                  hair free? You’re covered with Milan! We’ll take care of it,
                  no questions asked, and no additional cost.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-5'>
            <div className='row justify-content-center gy-3'>
              <div className='col-md-5 col-xl-4 text-center'>
                <img
                  src={GENTLE_IMG}
                  alt='Hand with a heart above it in icon format pictured for a summary page about laser hair removal for folliculitis'
                  className='mx-xxl-2'
                />
              </div>
              <div className='col-md-7'>
                <p className='mb-0 text-center text-md-start'>
                  <strong>Gentle Hair Removal.</strong>
                  <br /> Because the laser targets the hair follicle, your skin
                  doesn’t suffer through the abrasive action that razors,
                  depilators, or hot waxes can cause so you don’t risk breaking
                  the protective surface of the skin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default WhyLHRIdeal;
