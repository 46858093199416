import { NETLIFY_FUNCTIONS_PATH } from "../constants";

export const callVerifyHuman = async (action, executeRecaptcha) => {
  if (!executeRecaptcha) {
    console.log(`‼️ ---- ${action} recaptcha not yet available ---- ‼️`);
    return 'recaptcha not available'
  }
  const token = await executeRecaptcha(action);
  console.log(`${action} recaptcha is available`)
  try {
    // Send the token to your backend for verification
    // const response = await fetch('/api/verifyHuman', { // For Gatsby Cloud Functions // It's not working though
    const response = await fetch(`${NETLIFY_FUNCTIONS_PATH}verifyHuman`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify({ token }),
    });

    const data = await response.json()
    console.log(data)
    // if Human
    if (data.action === action && data.success && data.score > 0.4) return 'human'
    else return 'bot'

  } catch (error) {
    console.error(error)
    return 'verifyHuman api error'
  }
}