import React from 'react'
import { Link } from 'gatsby'

export function CompetitionEasier() {
  return (
    <section id="competition" className="milan-py">
      <div className="container">
        <h2 className="subheadlg milan-text-primary">How Laser Hair Removal Makes Your <br className="d-none d-lg-inline" /> Competition Preparation Easier</h2>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="p-3 bg-transparent-5 rounded">
              <p>Your next bodybuilding competition is just around the corner. All of the hard work and discipline you’ve been pouring into your workout routine and diet is about to pay off&#8230; it’s contest prep time! </p>
              <p>Everyone’s competition preparation routine varies a little <span className="d-none d-sm-inline">(from what competition spray tan solution to use, how much cardio to do, what to eat, and even what bodybuilding posing trunks to buy)</span> beginning with adjusted workouts a few weeks out. However, there are a few things that everyone does outside of the gym &amp; the kitchen to get ready to hit their poses on stage.</p>
              <ul className="list-style-disc">
                <li>Body Hair Removal</li>
                <li>Posing Practice</li>
                <li>Spray Tan</li>
                <li>Glue &amp; Glaze</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 bg-transparent-5 rounded">
              <p>Besides your fitness routine and nutrition, getting rid of body hair is one of the most <strong>important things to do in the days and weeks prior to any bodybuilding competition</strong>. Our lasers permanently eliminate any hair that is treated, so you’ll never have to squeeze in a last second waxing appointment again. <strong><em>PLUS</em></strong> treatments are easy to fit into your schedule&#8230; most take less than 30 minutes.</p>
              <p>With your hair gone for good, you’ll have more time to focus on other preparations such as hitting your required poses perfectly!</p>
              <p>Have unanswered questions about how laser hair removal can help you stay competition-ready? Our friendly, knowledgeable staff are happy to answer any questions you may have.</p>
              <p className="mb-0"><Link to="#consult" className="milan-text-primary">Find out if laser hair removal is right for you at your free consultation.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompetitionEasier