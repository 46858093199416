import React from 'react'

function BurgerBtn({ handleClick }) {
  return (
    <div className="burger-bars" onClick={handleClick} role="button">
      <div className="bar bar1"></div>
      <div className="bar bar2"></div>
      <div className="bar bar3"></div>
      <div className="bar bar4"></div>
    </div>
  )
}

export default BurgerBtn