import React from 'react'
import { Field } from 'formik'

export function Subscription(props) {
  const { label, name, mailchimpID, ...rest } = props
  return (
    <>
      <Field name={name}>
        {
          props => {
            const { field } = props
            return (
              <input
                className="form-check-input mt-0"
                type="checkbox"
                id={mailchimpID}
                name={name}
                checked={field.value}
                {...field} {...rest} />
            )
          }
        }
      </Field>
      <label className={`milan-text-${rest.color ? rest.color : 'white'} mailchimp ps-2 lh-sm`} htmlFor={mailchimpID}><small>Get updates on laser hair removal deals. (no spam)</small></label>
    </>
  )
}

export default Subscription