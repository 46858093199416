import React from "react";
import { Link } from "gatsby";

export function Privacy() {
  return (
    <section className="hero milan-bg-off-white">
      <div className="container">
        <h1 className="milan-text-primary">Milan Laser Privacy Policy</h1>
        <div className="row mb-4">
          <div className="col">
          <p>
             <strong>Last Revised on: July 1, 2023</strong>
            </p>
            <p>
              This Privacy Policy for Milan Laser Corporate LLC (“<strong>Company”</strong>, “<strong>we</strong>”, “<strong>us</strong>” “
              <strong>our</strong>”) describes how we collect, use and disclose information about users of our website, applications, services, tools
              and features and when we interact with you online or in person (collectively, the “<strong>Services</strong>”). For the purposes of this
              Privacy Policy, “<strong>you</strong>” and “<strong>your</strong>” means you as the user of the Services. Please note that the Services
              are designed for users in the United States only and are not intended for users located outside the United States.
            </p>
            <p>
              Please read this Privacy Policy carefully. By using or accessing any of the Services or otherwise interacting with us, you agree to the
              collection, use, and disclosure of your information as described in this Privacy Policy. If you do not agree to this Privacy Policy,
              please do not use or access any of the Services or otherwise interact with us.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">1. Updating This Privacy Policy</p>
            <p>
              We may modify this Privacy Policy from time to time in which case we will update the “Last Revised” date at the top of this Privacy
              Policy. If we make material changes to the way in which we use information we collect, we will use reasonable efforts to notify you
              (such as by emailing you at the last email address you provided us, by posting notice of such changes on the Services, or by other means
              consistent with applicable law). If you do not agree to any updates to this Privacy Policy, please do not access or continue to use the
              Services.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">2. Company's Collection and Use of Information</p>
            <p>When you access or use the Services, we collect certain categories of information about you from a variety of sources. </p>
            <p>
              Some features of the Services may require you to directly enter certain information about yourself. You may elect not to provide this
              information, but doing so may prevent you from using or accessing certain of our Services. Information that you may provide to us
              includes:
            </p>
            <ul className="list-style-disc">
              <li>
                Basic contact details, as follows: name, address, phone number, and email. We collect basic contact details to communicate with you,
                provide you with Services, and market to you.
              </li>
              <li>
                Health-related information, such as the body part you would like to be treated, the condition of your skin, and any health conditions
                present. We collect health-related information as minimally required to provide you with the Services.
              </li>
              <li>
                Payment information, as follows: credit or debit card information and billing address. We use a third-party payment processor to
                collect payment information to process your payment and provide you with Services you have requested.
              </li>
              <li>
                Any other information you choose to include in communications with us, for example, when sending a message through the Contact Us web
                form, chatting with us through chat features on our website, calling us, or submitting opinions or reviews about our Services.
              </li>
            </ul>
            <p>
              We also automatically collect certain information about your interaction with the Services (“<strong>Usage Data</strong>”). To do this,
              we may use cookies and other technologies (“<strong>Tracking Technologies</strong>”). Usage Data may include:
            </p>
            <ul className="list-style-disc">
              <li>Unique device identifier</li>
              <li>Device type, such as your phone, computer, or tablet</li>
              <li>IP address</li>
              <li>Browser type</li>
              <li>Date and time stamps, such as the date and time you first accessed the Services</li>
              <li>Operating system</li>
              <li>Log data</li>
              <li>Precise location</li>
              <li>Other information regarding your interaction with the Services</li>
            </ul>
            <p>We use the information we collect automatically to, among other things, tailor features and content to you.</p>
            <p>We may obtain demographic information about you from outside sources.</p>
            <p>
              Any information we receive from outside sources will be treated in accordance with this Privacy Policy. We are not responsible or liable
              for the accuracy of the information provided to us by third parties and are not responsible for any third party’s policies or practices.
              See Section 6 below for more information.
            </p>
            <p>
              In addition to the foregoing, we may use any of the above information to comply with any applicable legal obligations, to enforce any
              applicable terms of service, and to protect or defend the Services, our rights, and the rights of our users or others.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">3. How the Company Discloses Your Information</p>
            <p>
              In certain circumstances, we may disclose your information to third parties for purposes consistent with this Privacy Policy. Such
              circumstances may include:
            </p>
            <ul className="list-style-disc">
              <li>With vendors or other service providers, such as</li>
              <ul className="list-style-circle">
                <li>Payment processors</li>
                <li>Marketing and data analytics vendors</li>
                <li>Cloud storage providers</li>
                <li>IT service management vendors</li>
                <li>Email marketing services vendors</li>
                <li>Security vendors</li>
              </ul>
              <li>With our affiliates or otherwise within our corporate group</li>
              <li>With third parties for marketing purposes</li>
              <li>
                To comply with applicable law or any obligations thereunder, including cooperation with law enforcement, judicial orders, and
                regulatory inquiries
              </li>
              <li>In connection with an asset sale, merger, bankruptcy, or other business transaction</li>
              <li>To enforce any applicable terms of service and ensure the safety and security of the Company and/or its users</li>
              <li>With professional advisors, such as auditors, law firms, or accounting firms</li>
              <li>
                No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. 
                All the above categories exclude text messaging originator opt-in data and consent; 
                this information will not be shared with any third parties.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <p className="milan-text-primary p-subhead mb-3">4. Cookies and Other Tracking Technologies</p>
            <p><strong>Do Not Track Signals</strong><br/>
              Your browser settings may allow you to transmit a “Do Not Track” signal when you visit various websites. Like many websites, our website
              is not designed to respond to “Do Not Track” signals received from browsers. To learn more about “Do Not Track” signals, you can visit{" "}
              <a href="https://allaboutdnt.com/" target="_blank" className="milan-text-primary">
                http://www.allaboutdnt.com/
              </a>
              .
            </p>
            <p><strong>Do Not Track Signals</strong><br/>
              Most browsers accept cookies automatically, but you may be able to control the way in which your devices permit the use of Tracking
              Technologies. If you so choose, you may block or delete our cookies from your browser; however, blocking or deleting cookies may cause
              some of the Services, including any portal features and general functionality, to work incorrectly.{" "}
            </p>
            <p>
              We also use Google Analytics, which is a web analytics tool that helps us understand how users engage with the Services. Like many
              services, Google Analytics uses first-party cookies to track user interactions, as in our case, where they are used to collect
              information about how users use our site. This information is used to compile reports and to help us improve the Services. The reports
              disclose website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our
              site – for more information on opting out of being tracked by Google Analytics across all websites you use, visit this Google page:
              https://tools.google.com/dlpage/gaoptout.
            </p>
            <p>
              If you have questions regarding the specific information about you that we process or retain, as well as your choices regarding our
              collection and use practices, please contact us using the information listed below.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">5. Social Features</p>
            <p>
              Certain features of the Services permit you to initiate interactions between the Services and third-party services or platforms, such as
              social networks (“<strong>Social Features</strong>”). Social Features include features that allow you to click and access our pages on
              certain third-party platforms, such as Facebook and Twitter, and from there to “like” or “share” our content on those platforms. Use of
              Social Features may entail a third party’s collection and/or use of your data. If you use Social Features or similar third-party
              services, information you post or otherwise make accessible may be publicly displayed by the third-party service you are using. Both the
              Company and the third party may have access to information about you and your use of both the Services and the third-party service. For
              more information on third-party websites and platforms, see Section 6 below.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">6. Third Party Websites and Links</p>
            <p>
              We may provide links to websites or other online platforms operated by third parties. If you follow links to sites not affiliated or
              controlled by us, you should review their privacy and security policies and other terms and conditions. We do not guarantee and are not
              responsible for the privacy or security of these sites, including the accuracy, completeness, or reliability of information found on
              these sites. Information you provide on public or semi-public venues, including information you share on third-party social networking
              platforms (such as Facebook or Twitter) may also be viewable by other users of the Services and/or users of those third-party online
              platforms without limitation as to its use by us or by a third party. Our inclusion of such links does not, by itself, imply any
              endorsement of the content on such platforms or of their owners or operators, except as disclosed on the Services.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">7. Children's Privacy</p>
            <p>
              Children under the age of 18 are not permitted to use the Services, and we do not seek or knowingly collect any personal information
              about children under 13 years of age. If we become aware that we have unknowingly collected information about a child under 13 years of
              age, we will make commercially reasonable efforts to delete such information from our database. If you are the parent or guardian of a
              child under 13 years of age who has provided us with their personal information, you may contact us using the below information to
              request that it be deleted.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">8. Data Security</p>
            <p>
              Please be aware that, despite our reasonable efforts to protect your information, no security measures are perfect or impenetrable, and
              we cannot guarantee “perfect security.” Any information you send to us electronically, while using the Services or otherwise interacting
              with us, may not be secure while in transit. We recommend that you do not use unsecure channels to communicate sensitive or confidential
              information to us.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">9. Colorado and Connecticut Rights</p>
            <p>
              If you are a user of the Services and a resident of Colorado or Connecticut, please see the addendum to this Privacy Policy for an
              overview of your rights.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <p className="milan-text-primary p-subhead mb-3">10. How to Contact Us</p>
            <p>
              Should you have any questions about our privacy practices or this Privacy Policy, please email us at{" "}
              <a href="mailto: privacy@milanlaser.com" className="milan-text-primary">
                privacy@milanlaser.com
              </a>{" "}
              or contact us at{" "}
              <a href="tel:18336672967" className="milan-text-primary">
                1-833-667-2967
              </a>
              .
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <h2 className="milan-text-primary text-center pt-3 subheadlg">Colorado and Connecticut Users</h2>
            <h2 className="milan-text-primary subheadsm">Colorado</h2>
            <p>
              <strong>Your Colorado Rights Regarding Your Personal Data.</strong> Colorado law provides some Colorado residents with the rights listed
              below. To exercise these rights see the “Exercising Your Colorado Privacy Rights” section below.
            </p>
            <p>
              <strong>Right to Know.</strong> You have the right to know and see what personal data we have collected about you in a portable format.
            </p>
            <p>
              <strong>Right to Correct.</strong> You have the right to request that we correct inaccurate personal data.
            </p>
            <p>
              <strong>Right to Delete.</strong> You have the right to request that we delete the personal data we have collected about you.
            </p>
            <p>
              <strong>Right to Object to Targeted Advertising, Profiling, or Selling.</strong> You have the right to opt-out of the processing of your
              personal information for the purposes of targeted advertising, the sale of your personal information, and the profiling of your personal
              information in furtherance of decisions that produce legal or similarly significant effects.
            </p>
            <p>
              <strong>Exercising Your Colorado Privacy Rights.</strong> To request access to or deletion of your personal data, or to exercise any
              other data rights under Colorado law, please contact us using one of the following methods:
            </p>
            <p>
              <span style={{ textDecorationLine: "underline" }}>Email:</span> You may email us at{" "}
              <a href="mailto: privacy@milanlaser.com" className="milan-text-primary">
                privacy@milanlaser.com
              </a>
              .
            </p>
            <p>
              <span style={{ textDecorationLine: "underline" }}>Phone:</span> You may call us at{" "}
              <a href="tel:18336672967" className="milan-text-primary">
                1-833-667-2967
              </a>
              .
            </p>
            <p>
              <span style={{ textDecorationLine: "underline" }}>Website:</span> You may visit our{" "}
              <Link to="/privacy" className="milan-text-primary">
                privacy page
              </Link>{" "}
              to exercise rights through our website.
            </p>
            <p>
              To appeal our decision regarding a request related to these rights, you may email us at{" "}
              <a href="mailto: privacy@milanlaser.com" className="milan-text-primary">
                privacy@milanlaser.com
              </a>
              .
            </p>
            <h2 className="milan-text-primary subheadsm">Connecticut</h2>
            <p>
              <strong>Your Connecticut Rights Regarding Your Personal Data.</strong> Connecticut law provides some Connecticut residents with the
              rights listed below. To exercise these rights see the “Exercising Your Connecticut Privacy Rights” section below.
            </p>
            <p>
              <strong>Right to Know.</strong> You have the right to know and see what personal data we have collected about you in a portable format.
            </p>
            <p>
              <strong>Right to Correct.</strong> You have the right to request that we correct inaccurate personal data.
            </p>
            <p>
              <strong>Right to Delete.</strong> You have the right to request that we delete the personal data we have collected about you.
            </p>
            <p>
              <strong>Right to Object to Targeted Advertising, Profiling, or Selling.</strong> You have the right to opt-out of the processing of your
              personal information for the purposes of targeted advertising, the sale of your personal information, and the profiling of your personal
              information in furtherance of decisions that produce legal or similarly significant effects.
            </p>
            <p>
              <strong>Exercising Your Connecticut Privacy Rights.</strong> To request access to or deletion of your personal data, or to exercise any
              other data rights under Connecticut law, please contact us using one of the following methods:
            </p>
            <p>
              <span style={{ textDecorationLine: "underline" }}>Email:</span> You may email us at{" "}
              <a href="mailto: privacy@milanlaser.com" className="milan-text-primary">
                privacy@milanlaser.com
              </a>
              .
            </p>
            <p>
              <span style={{ textDecorationLine: "underline" }}>Phone:</span> You may call us at{" "}
              <a href="tel:18336672967" className="milan-text-primary">
                1-833-667-2967
              </a>
              .
            </p>
            <p>
              <span style={{ textDecorationLine: "underline" }}>Website:</span> You may visit our{" "}
              <Link to="/privacy" className="milan-text-primary">
                privacy page
              </Link>{" "}
              to exercise rights through our website.
            </p>
            <p>
              To appeal our decision regarding a request related to these rights, you may email us at{" "}
              <a href="mailto: privacy@milanlaser.com" className="milan-text-primary">
                privacy@milanlaser.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Privacy;

{
  /* <section className="hero milan-bg-off-white">
<div className="container">
  <h1 className="milan-text-primary">Privacy Statement</h1>
  <div className="row mb-4">
    <div className="col">
      <h2 className="milan-text-primary">Introduction</h2>
      <p>Milan Laser respects each individual’s right to personal privacy. We will collect and use information through our website only in the ways disclosed in this statement. This statement applies solely to information collected at Milan Laser website.</p>
    </div>
  </div>
  <div className="row mb-4">
    <div className="col">
      <h3 className="milan-text-primary">Part I. Information Collection</h3>
      <p>Milan Laser collects information through our website during the product purchase process.</p>
      <p>We may also collect email addresses as well as opinions, which our users divulge about our services under the “Reviews” section. Milan Laser does not actively market to children, and we never knowingly ask a child under 18 to divulge personal information.</p>
      <p>We collect this information through registration forms, surveys, and voluntary, monitored comments. The information is collected voluntarily. To receive our products and services, name, email address, credit card information and billing address are mandatory by registering with PayPal for product purchases. Other information we request is optional.</p>
    </div>
  </div>
  <div className="row mb-4">
    <div className="col">
      <h3 className="milan-text-primary">Part II. Information Usage.</h3>
      <p>The information collected by Milan Laser will be used for billing and shipping purposes. Users who provide information will receive purchased products, confirmation emails, periodic specials.</p>
      <p>The information we collect will not be used to create customer profiles based on browsing or purchasing history. We will not supplement information collected at our website with data from other sources.</p>
      <p>We may share data with the following third parties: Visa, MasterCard, and Discover. These third parties will use the information as follows: to complete transactions for the purchase of products.</p>
      <p>We offer links to other websites. Please note: When you click on links to other websites, we encourage you to read their privacy policies. Their standards may differ from ours.</p>
      <p>Our website offers a “Comments” section. Please be advised that information posted in these venues becomes public knowledge but are monitored and deleted as deemed appropriate by Milan Laser.</p>
      <p>If our policy on information collection or uses changes, Milan Laser will advise you by posting the date of the policy change.</p>
    </div>
  </div>
  <div className="row mb-4">
    <div className="col">
      <h3 className="milan-text-primary">Part III. Access to Information</h3>
      <p>Milan Laser maintains the accuracy of our information by manually reviewing it on a regular basis.</p>
    </div>
  </div>
  <div className="row mb-4">
    <div className="col">
      <h3 className="milan-text-primary">Part IV. Problem Resolution</h3>
      <p>If problems arise, users may contact Milan Laser by calling or emailing us. We are committed to resolving disputes as soon as possible.</p>
    </div>
  </div>
</div>
</section> */
}
