import React from "react";
import { Link } from "gatsby"

export function LipChin() {
  return (
    <section id="areas-lipchin" className="milan-py-lg py-md-milan-100 py-lg-milan-150">
      <div className="container">
        <div className="row">
          {/* <div className="col offset-md-6 col-md-6 offset-lg-5 col-lg-7 offset-xl-6 col-xl-6"> */}
          <div className="col offset-md-6 col-md-6">
            <div className="row">
              <div className="col">
                <h2 className="subheadlg milan-text-primary">Lip &amp; Chin <span className="d-block d-sm-inline">Laser Hair Removal</span></h2>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col col-xl-10">
                <p >Tired of maintaining a mustache with painful waxes and toxic creams? Our lasers are gentle enough to treat delicate skin, including your face. We take special care of areas like the lip, chin and even the cheeks. Say goodbye to that five o’clock shadow and hello to a radiant face you can be confident with every day.</p>
                <p>It doesn’t matter what causes your unwanted facial hair to grow – <Link to="/pcos/" className="milan-text-primary">PCOS</Link>, hirsutism, hormone changes due to pregnancy or menopause – our lasers can get rid of it for you, FOREVER!</p>
                <p className="mb-0"><Link to="/areas/lipnchin/" className="milan-text-primary">Learn more about laser hair removal for lip &amp; chin</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stack-hero-bg d-md-none"></div>
    </section>
  )
}
export default LipChin
