import React from 'react'
import { Link } from 'gatsby'

export function IdealForFolliculitis() {
  return (
    <section id="folliculitis">
      <div className="spacer d-lg-none"></div>
      <div className="container milan-py">
        <div className="row justify-content-lg-end">
          <div className="col-lg-7 col-xxl-6">
            <h2 className="subheadlg milan-text-primary">Why Laser Hair Removal is <br /> Ideal for Folliculitis</h2>
            <p>Folliculitis—more commonly known as Barber’s itch, razor bumps, hot tub rash, or shaving rash—occurs when the root of your unwanted hair becomes infected. While folliculitis can happen to anyone, two things that increase your risk <span className="d-none d-sm-inline">for developing the embarrassing and often painful red bumps, boils &amp; blisters associated with this common condition </span>are shaving and waxing.</p>
            <p>That’s because in the process of removing your unwanted hair these methods damage and irritate your skin. Avoiding your razor or that molten wax may help keep those unsightly pimples and angry red bumps away for a little while, but then you’re just hairy and those ingrown hairs still show up eventually. <span className="d-none d-sm-inline">Even using expensive antibacterial soaps, special creams, and avoiding tight clothing can only do so much because they only treat the symptoms of folliculitis instead of the cause.</span></p>
            <p className="mb-0">With laser hair removal, <strong>the underlying cause of folliculitis</strong>—your unwanted hair—is gone for good, <span className="d-none d-sm-inline">so there’s no need to worry about unflattering stubble, red bumps, or pimples ruining your smooth, hair-free skin.</span> <Link className="milan-text-primary" to="#consult">Learn more about why laser hair removal is your best solution for preventing folliculitis.</Link></p>
          </div>
        </div>
      </div>
      <span id="cysts"></span>
    </section>
  )
}

export default IdealForFolliculitis