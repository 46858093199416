import React from 'react'
import { Link } from 'gatsby'

export function PreTreatmentSteps() {
  return (
    <section id="pre-tx-steps" className="background milan-py">
      <div id="instructions" className="container">
        <div className="row">
          <div className="col-sm-9 col-md-8 offset-lg-2 col-lg-7">
            <div className="row gy-4 align-items-center">
              <div className="col-sm-5 col-md-4 col-xl-3">
                <div className="circle-bg bg-white mx-auto mx-sm-0">
                <h5 className="text-uppercase text-center mb-0">10 Days <br /> Before</h5>
                </div>
              </div>
              <div className="col-sm-7 col-lg-5 col-xl-4">
                <p className="mb-0 text-center text-sm-start">The use of spray tans or self-tanning skin products must be discontinued.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-9 col-md-8 offset-lg-2 col-lg-7">
            <div className="row gy-4 align-items-center">
              <div className="col-sm-5 col-md-4 col-xl-3">
                <div className="circle-bg bg-white mx-auto mx-sm-0">
                <h5 className="text-uppercase text-center mb-0">The Day <br /> Before</h5>
                </div>
              </div>
              <div className="col-sm-7 col-lg-5 col-xl-4">
                <p className="mb-0 text-center text-sm-start">Shave the area to be treated on the day of, or day prior to treatment.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-9 col-md-8 offset-lg-2 col-lg-7">
            <div className="row gy-4 align-items-center">
              <div className="col-sm-5 col-md-4 col-xl-3">
                <div className="circle-bg bg-white mx-auto mx-sm-0">
                <h5 className="text-uppercase text-center mb-0">The Day <br /> Of</h5>
                </div>
              </div>
              <div className="col-sm-7 col-lg-5 col-xl-4">
                <p className="mb-0 text-center text-sm-start">Come to the clinic with clean skin (without lotion, makeup, or deodorant).</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer d-sm-none"></div>
      <div className="container pt-5">
        <div className="row gy-4 justify-content-center">
          <div className="col-lg-4 col-xl-3">
            <p className="text-center mb-0"><strong>Feel free to call us with any questions or concerns you may have!</strong></p>
          </div>
          <div className="col-lg-3 text-center">
            <Link className="milan-btn milan-primary-btn" to="/locations/contact/">CONTACT US</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PreTreatmentSteps