import React from 'react'
const STEP_ONE_IMG = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FStep-1.webp?alt=media&token=a0ac4256-95fc-4240-8219-59778e2e650e'
const STEP_TWO_IMG = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FStep-2.webp?alt=media&token=d85ec679-e64f-42fc-81ae-ddf751092948'
const STEP_THREE_IMG = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FStep-3.webp?alt=media&token=70f5fa06-139f-455d-b1fe-6ff9e47b53df'

export function HowLHRWorks() {
  return (
    <section id="lhr-steps" className="milan-bg-tur milan-py-lg position-relative">
      <div className="container milan-text-white">
        <h2 className="subheadlg text-center">How Laser Hair Removal Works</h2>
        <div className="row gy-4 mt-1">
          <div className="col-md-4 text-center">
            <figure>
              <img src={STEP_ONE_IMG} alt="Laser Hair Removal Process - Step One" />
              <figcaption className="mt-3">Lasers target hair at the root.</figcaption>
            </figure>
          </div>
          <div className="col-md-4 text-center">
            <figure>
              <img src={STEP_TWO_IMG} alt="Laser Hair Removal Process - Step Two" />
              <figcaption className="mt-3">The root is destroyed so hair <br /> never grows&nbsp;back.</figcaption>
            </figure>
          </div>
          <div className="col-md-4 text-center z-index-100">
            <figure>
              <img src={STEP_THREE_IMG} alt="Laser Hair Removal Process - Step Three" />
              <figcaption className="mt-3">Hair is gone forever.</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowLHRWorks