import React from 'react';
import { Link } from 'gatsby';
import { MilanLogo } from '../shared';

function HeaderLogo({ setIsOpen }) {
  return (
    <Link
      to='/'
      className='d-inline-block nav-logo'
      onClick={() => setIsOpen(false)}
    >
      <MilanLogo
        parentClasses='d-block d-lg-none'
        type='blue'
        altText='Tall Milan Laser Hair Removal logo with white and teal lettering. Milan is the largest laser hair removal company in the US'
      />
      <MilanLogo
        parentClasses='d-none d-lg-block d-xl-none'
        type='white'
        altText='Simple rectangular Milan Laser Hair Removal logo in green and white lettering'
      />
      <MilanLogo
        parentClasses='d-none d-xl-block me-lg-3'
        imgClasses=''
        type='white_wide'
        altText='Simple rectangular Milan Laser Hair Removal logo in green and white lettering'
      />
    </Link>
  );
}

export default HeaderLogo;
