import React from 'react'
import { Link } from 'gatsby'
const Underarm_BA_Img = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fareas%2Funderarm%2FUnderarm_Top-Bottom.jpg?alt=media&token=e3b42cab-7510-4cb5-8f19-e53d03f96fc2'

export function HowUnderarmWork() {
  return (
    <section id="how-underarm-work" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 milan-bg-tur milan-text-white py-milan-50 py-xxl-milan-100">
            <div className="row">
              <div className="col-auto">
                <h2 className="subheadlg">How Underarm Laser Hair Removal Works</h2>
              </div>
              <div className="col-12 col-md-8 z-index-100 order-2 order-md-1">                
                <p className="d-sm-none">Laser hair removal is the best solution to removing inconvenient underarm hair. With a fast treatment (less than 10 minutes!) and permanent results, you’ll transform your armpits to smooth underarms in no time.</p>
                <p className="d-sm-none">Soft, smooth underarms are here to stay.</p>
                <p className="d-none d-sm-block">Years of shaving can make the sensitive skin in the underarm rough. Problems like ingrown hairs, and razor bumps can be uncomfortable and even result in scarring. With laser hair removal, the protective skin is not disturbed.</p>
                <p className="d-none d-sm-block">Instead of ruining the surface of your skin to temporarily remove hair, laser hair removal will permanently target and eliminate the root by targeting the melanin in the follicle. This leaves the surface of your skin intact and undamaged. <Link to="/process/" className="milan-text-white">See a demo</Link></p>
                <p className="d-sm-none">Laser hair removal will permanently target and eliminate the root by targeting the melanin in the follicle. This leaves the surface of the skin of your armpit intact and undamaged.</p>
              </div>
              <div className="col-12 col-md-4 col-lg-3 col-xl-4 order-1 order-md-2 text-center z-index-100 pb-4 pb-md-0">
                <img src={Underarm_BA_Img} alt="Lip and Chin Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 vrt-divider vrt-divider-reversed vrt-divider-turq" />
        </div>
      </div>
      <div className="spacer d-xl-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
  )
}

export default HowUnderarmWork