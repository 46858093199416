import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

export function LPUnlimitedPackage({ setShowSignUpModal }) {
  return (
    <section
      id='lp-unlimited-package'
      className='background unlimited-package-bg'
      style={{ scrollMarginTop: '90px' }}
    >
      <div className='container milan-py'>
        <div className='row'>
          <div className='col offset-lg-1 offset-xl-2'>
            <div className='row'>
              <div className='col'>
                <h2 className='subheadlg milan-text-primary'>
                  The Unlimited Package&#8482;
                </h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 col-lg-6 col-xl-5'>
                <div className='pe-4'>
                  <p>
                    <span className='d-none d-sm-inline'>
                      Exclusive to Milan Laser,{' '}
                    </span>
                    the Unlimited Package™ is the only laser hair removal
                    package that includes <em>unlimited sessions</em> of laser
                    hair removal on an area for one
                    <strong> affordable price</strong>. The average person needs
                    7 to 10 sessions, and packages are typically sold in 6 or 9.
                    Too few, or not enough? Not with the Unlimited Package at
                    Milan.
                  </p>
                  <p>
                    No surprise costs, and no running out of sessions before
                    you’re{' '}
                    <span className='d-none d-sm-inline'>completely</span>{' '}
                    smooth!{' '}
                  </p>
                  <p>
                    Don’t forget to check out our{' '}
                    <Link to='/specials/' className='milan-text-primary'>
                      specials page
                    </Link>{' '}
                    or{' '}
                    <span
                      className='milan-text-primary pointer text-decoration-underline'
                      onClick={() => setShowSignUpModal(true)}
                    >
                      <strong>sign up for our newsletter</strong>
                    </span>{' '}
                    for amazing deals!
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4 col-xl-3'>
                <div className='text-center text-md-start my-4 my-lg-5'>
                  <StaticImage
                    src='../../images/shared/Unlimited-Package.png'
                    style={{ maxWidth: '200px' }}
                    alt='Exclusively at Milan Laser The Unlimited Package Lifetime Guarantee No Surprise Costs'
                  />
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col text-center text-md-start'>
                <Link
                  className='milan-btn milan-primary-btn'
                  to='/specials/estimate/'
                >
                  Get a Custom Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='background spacer d-lg-none'></div>
    </section>
  );
}
export default LPUnlimitedPackage;
