import React from "react";

export function PreTreatmentHero() {
  return (
    <section className="hero pb-0">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-sm-9 col-md-8 col-lg-7">
            <h1 className="smalltitle milan-text-primary pb-0">Milan Laser</h1>
            <h1 className=" milan-text-primary"> Pre-Treatment Instructions</h1>
            <p className="mb-sm-0">Below are instructions to ensure you achieve the best and safest results from your laser hair removal experience.</p>
          </div>
        </div>
      </div>
      <div className="spacer d-sm-none"></div>
      <div id="instructions" className="container milan-py">
        <div className="row justify-content-end">
          <div className="col-sm-9 col-md-8 col-lg-7">
            <div className="row gy-4 align-items-center">
              <div className="col-sm-5 col-md-4 col-xl-3">
                <div className="circle-bg bg-white mx-auto mx-sm-0">
                  <h5 className="text-uppercase text-center mb-0">4 Weeks <br /> Before</h5>
                </div>
              </div>
              <div className="col-sm-7 col-md-8 col-xl-9">
                <p className="mb-0 text-center text-sm-start">
                  You must avoid bleaching, plucking, tweezing or waxing hair for 4 weeks prior to treatment. The hair follicle must be present as it
                  is the “target” for the laser.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end mt-5">
          <div className="col-sm-9 col-md-8 col-lg-7">
            <div className="row gy-4 align-items-center">
              <div className="col-sm-5 col-md-4 col-xl-3">
                <div className="circle-bg bg-white mx-auto mx-sm-0">
                <h5 className="text-uppercase text-center mb-0">3 Weeks <br /> Before</h5>
                </div>
              </div>
              <div className="col-sm-7 col-md-8 col-xl-9">
                <p className="mb-0 text-center text-sm-start">
                  Avoid the sun before and after treatment. Use SPF 50 or higher if treatment area is exposed to the sun. <strong>Don’t forget to re-apply! Tanned
                  skin cannot be treated.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreTreatmentHero;
