import { useState, useEffect } from 'react';

function useCloudampData() {
  const [cloudampData, setCloudampData] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setCloudampData(localStorage.getItem('cloudamp__data__c'));
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return cloudampData;
}

export default useCloudampData;
