import React from "react";

export function PostTxSkinCare() {
  return (
    <section id="postSkinCare" className="background">
      <div className="container milan-py">
        <div className="row">
          <div className="offset-sm-6 col-sm-6 offset-md-5 col-md-7 col-lg-6 offset-lg-5">
            <h2 className="milan-text-primary">Soothe skin</h2>
            <p>A topical skin care product such as Aloe Vera gel may be applied following treatment if desired.</p>

            <h2 className="milan-text-primary">Avoid Sun</h2>
            <p>Avoid sun exposure for 4 weeks after treatment to reduce the chance of hyperpigmentation (darker pigmentation).</p>

            <h2 className="milan-text-primary">Use sunblock</h2>
            <p>Use a sunblock (SPF 50+) at all times throughout the course of treatment. Re-apply every 2 hours when in the sun.</p>
          </div>
        </div>
      </div>
      <div className="p-5 mt-5 d-sm-none"></div>
    </section>
  );
}

export default PostTxSkinCare;
