import React from "react";
import { Link } from "gatsby";

export function LHRForDarkerTones() {
  return (
    <section id="lhr-darker-tones" className="pt-milan-50  py-lg-milan-100">
      <div className="container">
        <div className="row">
          <div className=" offset-lg-6 col-lg-6">
            <h2 className="subheadlg milan-text-primary">
              Laser Hair Removal for Darker Skin Tones
            </h2>
            <p>When performed properly, laser hair removal is <strong>safe and effective for all skin tones</strong>. But do your research before choosing a laser hair removal provider.</p>
            <ul className="list-style-disc ul-list">
              <li><strong>Technology</strong>. Make sure the provider utilizes the right laser technology. The Nd:YAG used at Milan is best for treating darker skin tones.</li>
              <li><strong>Expertise</strong>. Read reviews and ensure the provider has experience treating all skin tones. Milan providers are highly trained medical professionals and Authorized Candela Practitioners™. </li>
              <li><strong>Life-long results</strong>. The Nd:YAG laser often requires more treatments to reach optimal results. Milan includes their exclusive Unlimited Package™ with every purchase, so you get all the treatments needed with no touch-up fees. </li>
            </ul>
            <Link to="#consult" className="milan-text-primary">
              Schedule your <span className='text-decoration-underline'><strong>FREE consultation</strong></span> to learn more about laser hair removal for dark skin tones today!
            </Link>
          </div>
        </div>
      </div>
    {/* bg when stacked */}
    <div className="d-lg-none stack-hero-bg" />
  </section>

  );
}
export default LHRForDarkerTones