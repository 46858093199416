import React from "react"
import { Link } from "gatsby"

export function ActiveLifestyleHero() {
  return (
    <section id="active-lifestyle-hero" className="hero">
      <div className="container py-lg-4 my-lg-5">
        <div className="row">
          <div className="col offset-lg-6 col-lg-6 col-xxl-5">
            <div className="row">
              <div className="col-md-11 col-lg-12">
                <h1 className="milan-text-primary me-md-4 me-lg-0">Laser hair removal for active lifestyles</h1>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <p className="pb-3">You're busy and on the go - you don't have time for shaving and waxing appointments! Reclaim hours with laser hair removal so you can spend more time cycling and less time on endless shaving cycles.</p>
                <ul className="ps-lg-4 list-style-disc">
                  <li class="pb-2"><Link to="/process/beforeafter" className="milan-text-primary"><strong>Laser hair removal results</strong></Link> are permanent making it the perfect solution for those with active lifestyles.</li>
                  <li class="pb-2">Treatments are <Link to="/process" className="milan-text-primary"><strong>less painful and quicker than waxing</strong></Link> appointments.</li>
                  <li class="pb-2">For those who run, hike, swim, or hit the gym, being stubble-free means no irritation, bumps, or stubble!</li>                    
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ActiveLifestyleHero