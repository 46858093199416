import React from 'react';
import { Link } from 'gatsby';

import xs_sm_image from '../../images/bundles/ELDP-S3-XS-SM.webp';
import md_image from '../../images/bundles/ELDP-S3-MD.webp';
import lg_xl_image from '../../images/bundles/ELDP-S3-LG-XL.webp';
import xxl_image from '../../images/bundles/ELDP-S3-XXL.webp';

export function BreakShavingHabit() {
  return (
    <>
      <section id='break-shaving-habit' className='milan-bg-tur'>
        <div className='spacer d-lg-none hr-divider hr-divider-reversed-flipped hr-divider-turq-blue'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 milan-bg-tur milan-text-white py-milan-50 py-lg-milan-150'>
              <div className='row'>
                <div className='col-auto'>
                  <h2 className='subheadlg'>
                    Break your shaving habit, not the bank.
                  </h2>
                </div>
                <div className='col-12 z-index-100 order-2 order-md-1'>
                  <p>
                    We believe that investing in yourself and feeling confident
                    in your skin should be accessible to everyone. That’s why we
                    offer easy payment plans so nothing can stand in your way.
                  </p>
                  <ul className='list-style-disc'>
                    <li>Everyone’s approved for one of our payment plans!</li>
                    <li>Plans fit anyone’s monthly budget</li>
                    <li>
                      Monthly payments less than the lifetime cost of waxing
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col text-center'>
                  <Link
                    className='milan-btn milan-navy-btn milan-btn-width my-4'
                    to='/locations'
                  >
                    Find A Location
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-5 vrt-divider vrt-divider-reversed vrt-divider-turq' />
          </div>
        </div>
      </section>
      <img src={xs_sm_image} alt='' className='w-100 d-md-none' />
      <img
        src={md_image}
        alt=''
        className='w-100 d-none d-md-block d-lg-none'
      />
      <img
        src={lg_xl_image}
        alt=''
        className='w-100 d-none d-lg-block d-xxl-none'
      />
      <img src={xxl_image} alt='' className='w-100 d-none d-xxl-block' />
    </>
  );
}

export default BreakShavingHabit;
