import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

export function WhyLHRTrans() {
  return (
    <section id="why-lhr-trans" className="milan-py">
      <div className="container">
        <div className="row">
          <div className="col-md-7 mb-3">
            <h2 className="subheadlg milan-text-primary">Why Include Laser Hair Removal <br className="d-none d-xl-inline" /> in Your Transition</h2>
            <p>Removing unwanted hair is an important part of realizing your true gender identity. In the past, that meant an endless cycle of waxing &amp; shaving and issues like embarrassing stubble, razor burn, bumps, and ingrown hairs. With laser hair removal, you can avoid these painful side effects. </p>
            <span className="d-md-none">
              <p>Never worry about shaving, waxing or tweezing again. </p>
            </span>
            <div className="d-none d-md-block">
              <p>Instead of irritating your skin to temporarily remove hair, laser hair removal will permanently remove the hair without damaging your skin. This is because our laser eliminates the root of the hair by targeting the melanin in the follicle so that it can never grow back. <Link to="/process/" className="milan-text-primary">See a demo.</Link></p>
              <p>Additionally, you <strong>DO NOT have to let the hair grow out</strong> in order for laser hair removal to be successful. In fact, it’s a requirement that areas being treated are closely shaven. </p>
            </div>
          </div>
          <div className="col-md-5 text-center mt-md-5">
            <StaticImage src="../../images/areas/lipnchin/Lip_Top-Bottom.jpg" alt="Lip &amp; Chin Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyLHRTrans