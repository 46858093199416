import React from 'react'
import { Link } from '../../utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { popularSearches } from './utils/popularSearches'

function SearchResults({ hits, type, clickHandler, isMobile }) {
  if (type === 'Popular') hits = popularSearches
  return (
    <>
      <h2 className={`fw-light h6 mb-3 ${isMobile ? 'milan-text-primary' : ''}`}>{type} searches</h2>
      <ul className={`mb-0 ps-0 ${hits.length > 6 ? 'row' : ''}`}>
        {
          hits.map((hit, h) => (
            <li key={h} className={`mb-lg-1 ${isMobile ? '' : 'fw-bold'} ${hits.length > 6 ? 'col-auto col-lg-6 col-xl-4' : ''}`}>
              <Link className={`py-2 py-lg-0 d-block`} to={`${hit.url}${hit.anchor && hit?.anchor !== '' ? '#' + hit?.anchor : ''}`} onClick={clickHandler} >
                {hit.title} <span className="caret-icon caret-icon--right"><FontAwesomeIcon icon={faAngleRight} /></span>
              </Link>
            </li>
          ))
        }
      </ul>
    </>
  )
}

export default SearchResults