import React from "react";
import { Link } from "gatsby";

export function ActiveLifestyleMoney() {
  return (
    <section id="activelifestyle-money" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-xl-1 col-xl-5 milan-bg-tur milan-text-white milan-py-lg">
            <div className="row">
              <div className="col-auto">
                <h2 className="subheadlg">Save money!</h2>
              </div>
              <div className="col-auto z-index-100">
                <p>
                  In addition to saving time, you’re also saving money but not wasting cash on shaving essentials and waxing appointments. Active
                  lifestyles can be costly sometimes, so why not save where you can and permanently ban that unwanted hair? Check out <Link to="/specials" className="">this month’s
                  specials!</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xl-6 vrt-divider vrt-divider-reversed vrt-divider-turq"></div>
        </div>
      </div>
      <div className="spacer d-lg-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
  );
}
export default ActiveLifestyleMoney;
