import React from 'react';
import { Link } from 'gatsby';
import BADGE_SALE from '../../images/clientsale/badge-sale.webp';
import BADGE_EXTENDED from '../../images/clientsale/badge-extended.webp';
import BADGE_END from '../../images/clientsale/badge-end.webp';

export function ClientSaleHero({ variant }) {
  return (
    <section id='clientsale'>
      <div className={`hero ${variant} py-4 py-md-milan-50 py-xl-milan-150`}>
        <div className='d-lg-none stack-hero-bg text-center'>
          <img
            src={
              variant === 'sale'
                ? BADGE_SALE
                : variant === 'saleExtended'
                ? BADGE_EXTENDED
                : BADGE_END
            }
            alt='Customer appreciation event sale badge'
            style={{ width: '100%', height: '100%' }}
            className='px-5'
          />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='offset-lg-7 col-lg-5 offset-xl-6 col-xl-6 col-xxl-5'>
              {variant === 'sale' ? (
                <>
                  <p>
                    We’re saying thank you with{' '}
                    <strong>an exclusive discount and 1 FREE body area</strong>{' '}
                    all month long! You can also{' '}
                    <Link to='/share' className='milan-text-primary'>
                      share your discount
                    </Link>{' '}
                    with your friends and family — they’ll get 60% OFF an
                    unlimited laser hair removal package* plus 1 select body
                    area FREE, and you’ll get a $100 gift card* to the retailer
                    of your choice if they purchase.
                  </p>
                  <p>
                    Head to your{' '}
                    <Link to='/locations' className='milan-text-primary'>
                      local Milan Laser
                    </Link>{' '}
                    or reply to your email or text message by October 20 to get
                    your exclusive 60% OFF and FREE body area!
                  </p>
                  <p className='milan-fp fst-italic'>
                    *Full-body purchases are not eligible for discount. Earn one
                    gift card per each successful referral. There's no limit to
                    how many friends you can refer—-share the savings and keep
                    earning! We'll send your reward via email as soon as your
                    referral purchases.
                  </p>
                </>
              ) : variant === 'saleExtended' ? (
                <>
                  <p>
                    We’ve extended your{' '}
                    <strong>exclusive discount and 1 FREE body area</strong> as
                    our way of saying thank you! You can also{' '}
                    <Link to='/share' className='milan-text-primary'>
                      share your discount
                    </Link>{' '}
                    with your friends and family — they’ll get 60% OFF an
                    unlimited laser hair removal package* plus 1 select body
                    area FREE, and you’ll get a $100 gift card* to the retailer
                    of your choice if they purchase.
                  </p>
                  <p>
                    Head to your{' '}
                    <Link to='/locations' className='milan-text-primary'>
                      local Milan Laser
                    </Link>{' '}
                    or reply to your email or text message by October 31 to get
                    your exclusive 60% OFF and FREE body area!
                  </p>
                  <p className='milan-fp fst-italic'>
                    *Full-body purchases are not eligible for discount. Earn one
                    gift card per each successful referral. There's no limit to
                    how many friends you can refer—-share the savings and keep
                    earning! We'll send your reward via email as soon as your
                    referral purchases.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Customer Appreciation 2024</strong> is over, but
                    we’ll see you in 2025! Thank you to everyone who entered the
                    drawing, and remember to check your email to see if you won
                    a free laser hair removal package. 
                  </p>
                  <p>
                    <Link to='/specials' className='milan-text-primary'>
                      Click here
                    </Link>{' '}
                    to see our current laser hair removal specials and save big!
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientSaleHero;
