import React from 'react'
import { Link } from 'gatsby'

export function WelcomeToMilanHero() {
  return (
    <section className="hero milan-bg-off-white">
      <div className="container">
        <h1 className="milan-text-primary text-center">Welcome To Milan</h1>
        <div className="row gy-3 justify-content-center milan-py">
          <div className="col-md-4 col-lg-3 text-center">
            <Link className="milan-btn milan-primary-btn" to="/WelcomeToMilan/SkinCare/">Skin Care</Link>
          </div>
          <div className="col-md-4 col-lg-3 text-center">
            <Link className="milan-btn milan-primary-btn" to="/WelcomeToMilan/pre-treatment/">Pre Treatment</Link>
          </div>
          <div className="col-md-4 col-lg-3 text-center">
            <Link className="milan-btn milan-primary-btn" to="/WelcomeToMilan/post-treatment/">Post Treatment</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeToMilanHero