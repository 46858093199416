import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
// import { FaYelp, FaFacebookF, FaGooglePlusG } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYelp,
  faFacebookF,
  faGooglePlusG,
} from '@fortawesome/free-brands-svg-icons';

export function ReviewUsBtn({ currentMetro, link, platform, btn, imgAltText }) {
  const bgColor = {
    facebook: '#3E5A97',
    yelp: '#D82B2C',
    google: '#DB4E41',
  };
  return (
    <div className='col-md-4 col-lg-3 text-center pb-3 pt-md-3'>
      <span>Review Us!</span>
      {currentMetro.stores.length === 1 ? (
        <div>
          <a
            className='d-inline-block'
            href={`${
              currentMetro.stores[0].platforms[platform] !== '' ? link : ''
            }${currentMetro.stores[0].platforms[platform]}`}
          >
            {/* <img src={btn} width="180" height="60" alt={`milan laser ${platform} reviews`} /> */}
            <img
              src={btn}
              width='180'
              height='60'
              alt={
                imgAltText !== ''
                  ? imgAltText
                  : `milan laser ${platform} reviews`
              }
            />
          </a>
        </div>
      ) : (
        <Dropdown id={`${platform}-reviews`}>
          <Dropdown.Toggle style={{ backgroundColor: bgColor[platform] }}>
            <img
              src={btn}
              width='180'
              height='60'
              // alt={`milan laser ${platform} reviews`}
              alt={
                imgAltText !== ''
                  ? imgAltText
                  : `milan laser ${platform} reviews`
              }
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            className='py-0'
            style={{ backgroundColor: bgColor[platform] }}
          >
            {currentMetro.stores.map((store, x) => (
              <Dropdown.Item
                className='border-bottom'
                key={x}
                target='_blanc'
                href={`${store.platforms[platform] !== '' ? link : ''}${
                  store.platforms[platform]
                }`}
              >
                {platform === 'google' ? (
                  // <FaGooglePlusG className="fs-5 me-1" />
                  <FontAwesomeIcon icon={faGooglePlusG} className='fs-5 me-1' />
                ) : platform === 'yelp' ? (
                  // <FaYelp className="fs-5 me-1" />
                  <FontAwesomeIcon icon={faYelp} className='fs-5 me-1' />
                ) : platform === 'facebook' ? (
                  // <FaFacebookF className="fs-5 me-1" />
                  <FontAwesomeIcon icon={faFacebookF} className='fs-5 me-1' />
                ) : (
                  <></>
                )}
                {store.marketingCity}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}

export default ReviewUsBtn;
