import { Link } from "gatsby";
import React from "react";

export function PostTreatmentFinal() {
  return (
    <section id="txFinal">
      <div className="spacer d-sm-none"></div>
      <div className="container milan-py">
        <div className="row">
          <div className="offset-sm-6 col-sm-6 offset-md-5 col-md-7 col-lg-6 offset-lg-5">
            <h2 className="milan-text-primary">Exfoliate</h2>
            <p>Exfoliating is important to prevent ingrown hairs. We suggest a gentle facial scrub for your face and a wet cloth or loofah for your body.</p>
            <h2 className="milan-text-primary">Bathing</h2>
            <p>There are no restrictions on bathing but be gentle (as if you had a sunburn) for the first 24 hours.</p>

            <div className="text-center mt-5">
              <strong>Feel free to call us with any questions or concerns you may have!</strong>
              <div className="pt-3">
                <Link className="milan-btn milan-primary-btn" to="/locations/contact/">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PostTreatmentFinal;
