import React from 'react'
import { Link } from 'gatsby'

export function IdealForPCOS() {
  return (
    <section id="ideal-for-pcos" className="milan-bg-off-white">
      <div className="spacer d-md-none"></div>
      <div className="container milan-py">
        <h2 className="subheadlg milan-text-primary">Why Laser Hair Removal is <br /> Ideal for PCOS</h2>
        <div className="row">
          <div className="col-md-10 col-lg-7 col-xxl-6">
            <div className="bg-md-transparent-6 bg-xl-transparent p-md-4 p-xl-0 rounded">
              <p>The hormone fluctuations commonly associated with PCOS or polycystic ovarian syndrome cause hair follicles to become “active”. This side effect of PCOS is what causes unwanted hair to grow.</p>
              <p>While there is no cure for polycystic ovarian syndrome, it’s important to manage your symptoms to lower your risk for long-term health problems like heart disease or fertility issues. Since each case of PCOS is unique, it’s important to work closely with your physician to develop a plan to minimize the effects it can have on your health. Many physicians will <strong>include laser hair removal as part of your plan to manage your PCOS symptoms.</strong></p>
              <p>That’s because laser hair removal permanently removes the unwanted hair by eliminating it at the root so that it can never grow back. <strong>Managing the unwanted hair associated with PCOS</strong> by shaving, waxing, or plucking only yields temporary results, and electrolysis is extremely painful and time consuming.</p>
              <p className="mb-0"><Link className="milan-text-primary" to="/pcos/">Learn more about why laser hair removal is your best solution for managing PCOS hair growth.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IdealForPCOS