import React from 'react';
import { Link } from 'gatsby';
import GRAPHIC from '../../../../images/lp/anniversarysale/JoinTheParty.webp';
import HEROIMAGE from '../../../../images/lp/anniversarysale/AnniversarySaleHero-BG-Desktop.webp';
import './AnniversarySaleHero.css';

export function AnniversarySaleHero() {
  return (
    <section id='anniversarysale-hero' className='hero position-relative py-0'>
      <div className='d-lg-none stacked-hero-bg' />
      <div className='container py-lg-milan-50 position-relative'>
        <div className='row'>
          <div className='col-lg-6 col-xl-6'>
            <figure className='hide-on-mobile'>
              <img
                src={HEROIMAGE}
                alt='Milan Laser Social'
                style={{ maxWidth: '100%' }}
              />
            </figure>
          </div>
          <div className='col-lg-6 col-xl-6'>
            {/* only hide on md */}
            <figure className='pb-4 position-relative z-index-100 text-center d-lg-block figure-lower'>
              <img
                id='graphic'
                src={GRAPHIC}
                style={{ maxWidth: '100%' }}
                alt='Milan Laser Social'
              />
            </figure>
            <p>
              It may be our 12th anniversary, but we’re celebrating YOU! This
              month only, get 50% OFF unlimited laser hair removal on all body
              areas*, plus up to $1,000 OFF — the more you spend, the more you
              save!
            </p>

            <p>
              You can also{' '}
              <Link to='/share/' className='milan-text-primary'>
                share your discount
              </Link>{' '}
              with your friends and family. They’ll get 50% OFF + up to $1,000
              OFF an unlimited laser hair removal package, and you’ll get a $100
              gift card* to the retailer of your choice if they purchase.
            </p>

            <p>
              Head to your{' '}
              <Link to='/locations/' className='milan-text-primary'>
                local Milan Laser
              </Link>{' '}
              or reply to your email or text message by <strong>July 31</strong>{' '}
              to get your exclusive savings!
            </p>
            <div className='my-4 text-center'>
              <Link className='milan-btn milan-cta-btn' to='#consult'>
                Book My Free Consult
              </Link>
            </div>
            <p className='mb-0 milan-fp'>
              <span>
                *Terms apply. Full-body purchases are not eligible for discount.
                Only one reward per referral. $100 gift card received only when
                referral purchases. Reward links will be emailed only when
                referral purchases.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AnniversarySaleHero;
