import React from "react";
import Carousel from "react-bootstrap/Carousel";
import women1 from "../../images/bodymap/women1.webp";
import women2 from "../../images/bodymap/women2.webp";
import women3 from "../../images/bodymap/women3.webp";
import women4 from "../../images/bodymap/women4.webp";
import women5 from "../../images/bodymap/women5.webp";
import women6 from "../../images/bodymap/women6.webp";

export function BodymapWomenCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={women1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={women2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={women3} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={women4} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={women5} alt="Second slide" />
      </Carousel.Item> 
      <Carousel.Item>
        <img className="d-block w-100" src={women6} alt="Second slide" />
      </Carousel.Item> 
    </Carousel>
  );
}

export default BodymapWomenCarousel;
