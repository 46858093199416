import React from 'react';

export function SubmitSelfScheduleBtn({formik}) {
  return (
    <button className='milan-btn milan-red-btn w-100' type='submit' disabled={!formik.isValid}>
      See Available Times
    </button>
  );
}

export default SubmitSelfScheduleBtn;
