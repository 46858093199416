import React from 'react'

export const TimeRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <div className='mb-4'>Coupon Expired!</div>;
  } else {
    // Render a countdown
    return (
      <div className="row justify-content-center gx-4 gx-md-5 mb-4">
        <div className="col-auto">
          <time className="d-block p-subhead">{days}</time>
          <span className="d-block">Days</span>
        </div>
        <div className="col-auto">
          <time className="d-block p-subhead">{hours}</time>
          <span className="d-block">hours</span>
        </div>
        <div className="col-auto">
          <time className="d-block p-subhead">{minutes}</time>
          <span className="d-block">minutes</span>
        </div>
        <div className="col-auto">
          <time className="d-block p-subhead">{seconds}</time>
          <span className="d-block">seconds</span>
        </div>
      </div>
    );
  }
};

export default TimeRenderer