import React from 'react'
const AshleyQuoteSM = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FAshley_Blair_Mobile.webp?alt=media&token=090ecafe-a6d8-4e61-a660-f724a61d9ed5'
const AshleyQuoteLG = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FAshley_Blair.webp?alt=media&token=9445532c-c5f8-4495-8f9a-feac85a441b9'

export function AthleteReview() {
  return (
    <section id="athlete-review">
      <div className="container px-0">
        <img className="d-sm-none mw-100" src={AshleyQuoteSM} alt="Ashley Blair Milan Laser Review" />
        <img className="d-none d-sm-inline mw-100" src={AshleyQuoteLG} alt="Ashley Blair Milan Laser Review" />
      </div>
    </section>
  )
}

export default AthleteReview