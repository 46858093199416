import React from 'react'
import { MILAN_PHONE } from '../../utils'

export function Covid() {
  return (
    <section className="hero milan-bg-off-white">
      <div className="container">
        <h1 className="milan-text-primary">Our Response to the Coronavirus</h1>
        <div className="row my-4">
          <div className="col">
            <h2 className="subheadsm milan-text-primary">Protecting the Health of Our Clients and Staff</h2>
            <p>We’re happy to be open and performing treatments as we love helping our clients achieve their hair-free goals!</p>
            <p>However, <strong>your safety, and the safety of our team, remains a top priority for us.</strong> That’s why, in accordance with the recommendations of the Centers for Disease Control and Prevention <a className="text-decoration-underline milan-text-primary" href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank" rel="noreferrer">(CDC)</a> as well as the government, <strong>we continue to follow these COVID-19 precautions</strong> in order to ensure the safety and wellbeing of our clients, staff, and community.</p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <h2 className="subheadsm milan-text-primary">What We Ask Visitors To Do</h2>
            <p>To help us protect the health and well-being of everyone who enters the clinic, we ask that you do a few things when coming in for your free consultation or treatments.</p>
            <ul className="list-style-disc">
              <li><strong>Follow Local Regulations.</strong> If local officials deem it necessary, wear a mask or protective facial covering when you come to your appointment.</li>
              <li><strong>If Possible, Come Alone.</strong> This will help us keep the number of people in one place at a time to a minimum.</li>
              <li><strong>If You're Sick, Reschedule.</strong> We know that you're excited to continue your hair-free journey and will be happy to treat you when you're feeling better.</li>
            </ul>
            <p>If you have an upcoming appointment with us and are not feeling well, please call <a className="text-decoration-underline milan-text-primary" href={`tel:${MILAN_PHONE}`}>{MILAN_PHONE}</a> or email us at <a className="text-decoration-underline milan-text-primary" href="mailto:info@milanlaser.com">info@milanlaser.com</a>, and we will be happy to reschedule your appointment.</p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <h2 className="subheadsm milan-text-primary">What Our Team Is Doing</h2>
            <p>We continue to follow our usual cleaning and disinfecting procedures, which include:</p>
            <ul className="list-style-disc">
              <li>Having <strong>our providers wear gloves</strong> while performing your treatments.</li>
              <li>After each treatment, we <strong>remove and replace linens</strong> in the treatment room. This includes sheets and towels.</li>
              <li><strong>We also wipe down</strong> treatment tables, laser equipment, and skincare product bottles with disinfectant after treatment sessions.</li>
              <li>We <strong>regularly clean objects with disinfectant</strong> that are frequently handled such as workstations, countertops, and doorknobs.</li>
              <li>Our <strong>lasers are cleaned regularly</strong> according to the manufacturer’s directions.</li>
              <li><strong>Alcohol-based hand sanitizer</strong> is available at all of our front desks as well as in every consultation and treatment room.</li>
            </ul>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <h2 className="subheadsm milan-text-primary">In addition to those existing measures:</h2>
            <ul className="list-style-disc">
              <li>We are <strong>following local regulations</strong>, and our team will wear face masks when deemed necessary by local officials.</li>
              <li>We are also being <strong>extra diligent</strong> to ensure staff exhibiting any signs or symptoms of illness remain home.</li>
            </ul>
            <p>If you have any questions about our COVID-19 precautions, please contact us at <a className="text-decoration-underline milan-text-primary" href={`tel:${MILAN_PHONE}`}>{MILAN_PHONE}</a> or email us at <a className="text-decoration-underline milan-text-primary" href="mailto:info@milanlaser.com">info@milanlaser.com</a> prior to coming to the clinic. Thank you for doing your part to keep our community safe.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Covid