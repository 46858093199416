import React from "react";
const herotemplate = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2Fherotemplate.webp?alt=media&token=e1cd59e7-a859-4006-b2cf-a68b11336f21";
const hero_example1 = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2Fhero_example1.webp?alt=media&token=6a45689e-c5c4-4f00-ab16-a64816310493";
const Spring_Exterior = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FSpring_Exterior.webp?alt=media&token=b266bb12-afd4-40c0-a976-97946ed2d409";
const Spring_Exterior_example = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FSpring_Exterior_example.webp?alt=media&token=aff324fc-5218-4c60-accc-9726630dfbc8";
const door = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2Fdoor.webp?alt=media&token=cda6d8d0-af38-4d10-bdee-ece44a9a5246";

export function BestPracticesStoreFront() {
  return (
    <section id="store-front" className="milan-py">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="milan-text-primary subheadlg">Store Front</h2>
            <p>
              Ideally we are looking for a shot without any vehicles in front of our store. In the event that there are vehicles in the way, please
              still photograph. We will possibly schedule another date to do a reshoot of just the exterior at an earlier time of day before
              businesses open. Our store manager should have removed all grand opening signage from our windows prior to your arrival. If by chance
              they haven’t, please ask them to remove it before taking photos of the store front.
            </p>
            <p>We are looking for 3 different shots of our exterior.</p>
            <p className="p-subhead mb-2 milan-text-primary">Zoomed out photo</p>
            <ul className="list-style-disc">
              <li>
                This is used for our hero image for each store locations.{" "}
                <a href="https://milanlasernashville.com/locations/nashville/hendersonville/" target="_blank" rel="noreferrer" className="milan-text-primary">
                  Example Here
                </a>
              </li>
              <li>We want a photo that shows what businesses are near us. (See example on the right)</li>
              <li>If possible, either shoot the photo to have Milan be off-centered to the right or left.</li>
              <li>For consistency, we prefer the photo to not be at an angle.</li>
              <li>
                This shot could be used in a variety of ways, so we need a lot of background in the picture to make it easier to edit for different
                mediums.
              </li>
            </ul>
            <p className="p-subhead mb-2 milan-text-primary">Cropped photo of store front only</p>
            <ul className="list-style-disc">
              <li>This will be used on our location page and will be in portrait format. Please leave enough space for our store information.</li>
              <li>Straight-on shot. Could be the same as the zoomed out photo mentioned above but cropped.</li>
            </ul>
            <p className="p-subhead mb-2 milan-text-primary">Angled shot of the door</p>
            <ul className="list-style-disc">
              <li>Zoomed in angle shot of the door. (See example on the right)</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <img src={herotemplate} alt="hero" className="w-100 img-thumbnail" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <img src={hero_example1} alt="hero example" className="w-100 img-thumbnail" />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <img src={Spring_Exterior} alt="vertical exterior" className="w-100 h-100 img-thumbnail" />
              </div>
              <div className="col-6">
                <img src={Spring_Exterior_example} alt="vertical exterior example" className="w-100 h-100 img-thumbnail" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <img src={door} alt="front door" className="w-100 img-thumbnail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BestPracticesStoreFront;
