import React, { useState, useEffect } from 'react';
import { IframeLoader } from '../iframe-loader/iframe-loader';

export function ShareHero() {
  const [talkableLoaded, setTalkableLoaded] = useState(false);

  // Documentation for below implementation can be found here: https://docs.talkable.com/advanced_features/subscribing_to_events.html

  const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
    const checkTalkable = () => {
      if (isBrowser) {
        //check if window is available
        if (window.talkable) {
          //check if talkable is available
          setTalkableLoaded(true);
        } else {
          setTimeout(checkTalkable, 2000); // Check again after 1500ms
        }
      }
    };
    checkTalkable();
  }, []);

  return (
    <section
      className='hero'
      style={{
        backgroundColor: '#F5F5F5',
      }}
    >
      <div
        className='container'
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h1 className='milan-text-primary text-center'>Share Your Discount!</h1>
        <div
          className='row'
          style={{
            minHeight: '854px',
            position: 'relative',
          }}
        >
          {/* Show loading animation when talkable is being loaded */}
          <div style={{ position: 'absolute' }}>
            {!talkableLoaded && <IframeLoader />}
          </div>
          <div id='talkable-offer'></div>
        </div>
      </div>
    </section>
  );
}

export default ShareHero;
