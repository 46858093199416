import React from 'react';
import MILAN_LOGO_WHITE from '../../images/shared/Milan_Logo_White.svg';
import MILAN_LOGO_WHITE_WIDE from '../../images/shared/Milan_Logo_White_Wide.svg';
import MILAN_LOGO_BLUE from '../../images/shared/Milan-Logo-Blue.webp';

const logos = {
  white: MILAN_LOGO_WHITE,
  white_wide: MILAN_LOGO_WHITE_WIDE,
  blue: MILAN_LOGO_BLUE,
};

export function MilanLogo({ type, parentClasses, imgClasses, altText }) {
  return (
    <div className={`milan-logo ${parentClasses ? parentClasses : ''}`}>
      <img
        className={imgClasses ? imgClasses : ''}
        src={logos[type]}
        alt={altText}
      />
    </div>
  );
}

export default MilanLogo;
