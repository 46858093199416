import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// 2023 Firebase Config
// const firebaseConfig = {
//   apiKey: `${process.env.GATSBY_FB_CLIENT_SALE_API_KEY}`,
//   authDomain: 'milan-client-sale.firebaseapp.com',
//   projectId: 'milan-client-sale',
//   storageBucket: 'milan-client-sale.appspot.com',
//   messagingSenderId: '328333729403',
//   appId: '1:328333729403:web:f4fd25b6c49323ad89ccde',
// };

// 2024 Firebase Config
const firebaseConfig = {
  apiKey: `${process.env.GATSBY_FB_CLIENT_SALE_API_KEY}`,
  authDomain: 'milan-client-sale-2024.firebaseapp.com',
  projectId: 'milan-client-sale-2024',
  storageBucket: 'milan-client-sale-2024.appspot.com',
  messagingSenderId: '502331502293',
  appId: '1:502331502293:web:ae07d4cf24e6361d55fc5c',
};

const app = initializeApp(firebaseConfig, 'ClientSale');
export const auth = getAuth(app);
export const client_sale_db = getFirestore(app);
