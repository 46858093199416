import React from "react"
import { Link } from "gatsby"

export function LaserForBrazilian() {
  return (
    <section id="lhr-ideal-for-brazilian" className="full-section background thirdBG">
      <div className="container">
        <h2 className="text-center subheadsm">Why Use a Laser for Your Brazilian?</h2>
        <div className="row my-4 pt-3">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="bg-transparent-5 p-3 rounded">
              <p>Your skin protects your body from harm and deserves to be well-cared for. This means keeping hot waxes and rusty razors out of the picture. You’ll get long lasting results without the mess and save time without the weight of unwanted hair with our gentle hair removal.</p>
              <ul className="list-style-disc">
                <li className="mb-2"><strong>Always Ready.</strong> Stop the acrobatic reaching routine and get rid of unwanted Brazilian area hair forever.</li>
                <li className="mb-2"><strong>Results are Forever.</strong> Smooth skin is here to stay with laser hair removal. In 7-10 treatments, say goodbye to those pesky hairs forever.</li>
                <li className="mb-2"><strong>Lifetime Results.</strong> Have a rogue hair that doesn’t want to go? Don’t worry, we’ll take care of it at no additional cost, for life. Our Unlimited Package™, included with every purchase, means we’ll take care of your purchased area forever, no fees, no questions asked.</li>
                <li className="mb-2"><strong>Gentle Results.</strong> Our medical professionals take extra care to make sure your treatments are gentle, effective, and most of all, safe. Their extensive training means you’ll get the treatments you want, with the comfort you deserve without sacrificing quality.</li>
              </ul>
              <p>Laser hair removal is the best way to remove hair from your body. It’s the safest, most effective process for getting permanently smooth skin.</p>
              <Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LaserForBrazilian