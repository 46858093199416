import React from 'react';
import './milan-loader.css';
import MLogoSvg from '../../images/share/M.svg';

export function MilanLoader() {
  return (
    <div className='circle-wrapper-ml'>
      <div className='circle-container-ml'>
        <div className='circle-fill-ml'></div>
        <img src={MLogoSvg} alt='Milan Logo' className='m-logo' />
      </div>
    </div>
  );
}
