import React from "react";

export function PcosHero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="bg-transparent-5 p-4 bg-lg-transparent p-lg-0 rounded">
              <h1 className="milan-text-primary ">Laser Hair Removal <span className="d-none d-md-inline"> <br /> </span> For PCOS</h1>
              <p>
                {" "}
                Get the smooth, hair-free skin you’ve been wanting by <strong>
                  permanently removing polycystic ovarian syndrome hair growth
                </strong>{" "}
                with laser hair removal.<sup>&dagger;</sup>
              </p>
              <ul className="list-style-disc">
                <li className="mb-2">
                  No need for embarrassing hair growth — <strong>treatments require the area to be cleanly shaven.</strong>
                </li>
                <li className="mb-2">
                  Our lasers are much <strong>less painful than electrolysis or waxing.</strong>
                </li>
                <li className="mb-2">
                  All treatments are <strong>performed by highly-trained medical professionals</strong> under the supervision of a medical doctor.
                </li>
              </ul>
              <p className="mb-0">No more endless shaving or waxing!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PcosHero;
