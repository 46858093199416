import React from 'react';
import SpecialsHeroContent from './SpecialsHeroContent';
import './SpecialsHero.css';

export const HOME_PAGE_COPY = {
    default: 'Winter Wondersale: | $250 OFF + an extra 50% OFF!',
    grandOpening: 'Grand Opening Sale: | $250 OFF + an extra 50% OFF unlimited laser hair removal!',
};

export function SpecialsHero({ setShowSignUpModal }) {
    return (
        <section id="specials-hero" className="hero position-relative py-0">
            <div className="d-lg-none stacked-hero-bg" />
            <div className="d-block hero-bg">
                <SpecialsHeroContent setShowSignUpModal={setShowSignUpModal} />
            </div>
        </section>
    );
}
export default SpecialsHero;
