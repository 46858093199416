import { Link } from 'gatsby';
import React from 'react';

import MAP_WITH_BADGE from '../../images/shared/map_with_badge.webp';

export function LargestLHRCompany() {
  return (
    <section id='largest-lhr' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <h2 className='subheadlg milan-text-primary'>
              The Nation’s Leading Laser Hair Removal Experts
            </h2>
            <p>
              With over 380 locations in 37 states, Milan Laser Hair Removal
              makes getting smooth skin more accessible than ever. Every
              purchase comes with our exclusive{' '}
              <Link to='/specials/#guarantee' className='milan-text-primary'>
                Unlimited Package™
              </Link>
              , offering all the treatments you need at no additional cost.
              Plus, everyone qualifies for our flexible financing options,
              available at any of our convenient locations. At Milan Laser, we
              make it simple to say goodbye to unwanted hair — guaranteed.
            </p>
            <p>
              Founded in 2012 by board-certified medical doctors, Milan Laser
              performs over 75,000 treatments each month. We provide
              state-of-the-art laser treatments in a safe, relaxing environment.
            </p>
            <div className='text-center text-md-start mb-4'>
              <Link to='/locations' className='milan-btn milan-cta-btn w-auto'>
                Find Your Nearest Location
              </Link>
            </div>
          </div>
          <div className='d-none d-lg-block col-12 col-lg-6'>
            <div className='text-center mb-4 mt-md-4 mt-lg-5'>
              <img
                src={MAP_WITH_BADGE}
                className='mw-100'
                alt='Milan Laser Locations Map'
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LargestLHRCompany;
