import React, { useRef, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { FormikControl, ConsentMsg } from '..';
import {
  CORPORATE_SITE,
  CONTACT_FORM_HANDLER,
  hardTrim,
  callVerifyHuman,
} from '../../../utils';
import { LocationsDropdownWrapper } from '../shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { contactInitialValues as initialValues, contactSchema } from '.';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useCloudampData from '../../../hooks/useCloudampData';

export function ContactForm({ siteData }) {
  const formRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const user = await callVerifyHuman('contactForm', executeRecaptcha);
    return user;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const onSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    const user = await handleReCaptchaVerify();
    console.log('reCAPTCHA user contact form: ', user);
    helpers.setSubmitting(false);
    if (user === 'bot') {
      helpers.setErrors('bot', true);
      return;
    } else {
      formRef.current.oid.value = process.env.GATSBY_OID;
      formRef.current.submit();
    }
  };

  const cloudampData = useCloudampData();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form
            id='contact-form'
            className='w-100 pb-4'
            ref={formRef}
            method='POST'
            action={CONTACT_FORM_HANDLER}
          >
            <input
              type='hidden'
              name='cloudamp__data__c'
              value={cloudampData}
            />
            <input type='hidden' name='oid' />
            <input
              type='hidden'
              name='retURL'
              value={`${CORPORATE_SITE}/form/success/`}
            />
            <input
              className='lead_source'
              type='hidden'
              name='lead_source'
              id='lead_source'
              value='Website'
            />

            <div className='row justify-content-center mx-auto pt-md-2 gx-2'>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='input'
                  name='first_name'
                  placeholder='First Name*'
                  type='text'
                  role='input'
                  aria-required
                />
              </div>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='input'
                  name='last_name'
                  placeholder='Last Name*'
                  type='text'
                  role='input'
                  aria-required
                />
              </div>
            </div>
            <div className='row justify-content-center mx-auto gx-2'>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='phoneInput'
                  name='phoneMask'
                  placeholder='Phone Number*'
                  type='tel'
                  role='input'
                  aria-required
                />
                <input
                  type='hidden'
                  name='phone'
                  value={`+${hardTrim(formik.values.phoneMask)}`}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <FormikControl
                  control='emailInput'
                  name='email'
                  placeholder='Your Email*'
                  role='input'
                  aria-required
                />
              </div>
              <div className='col-md-12 mb-3'>
                <label htmlFor='00N1L00000F9eBV'>
                  Location{' '}
                  <sup>
                    <FontAwesomeIcon icon={faAsterisk} />
                  </sup>
                </label>
                <LocationsDropdownWrapper
                  {...{ siteData, formik }}
                  fieldName='00N1L00000F9eBV'
                />
              </div>
            </div>
            <div className='row justify-content-center mx-auto overflow-hidden gx-2'>
              <div className='mb-2'>
                <FormikControl
                  control='textarea'
                  name='description'
                  placeholder='Hello...'
                  rows='3'
                  role='input'
                />
              </div>
            </div>
            <div className='row justify-content-center mx-auto gx-2'>
              <div className='custom-checkbox d-flex'>
                <FormikControl
                  control='mailchimp'
                  name='updates'
                  color='primary'
                  mailchimpID='mailchimp-contact'
                />
              </div>
            </div>

            <div id='actions-btns' className='row justify-content-center mt-4'>
              <div className='col-10 col-lg-6 text-center'>
                <button
                  className='milan-btn milan-red-btn w-100'
                  type='submit'
                  disabled={!formik.isValid}
                >
                  {' '}
                  Submit
                </button>
              </div>
              <div className='mt-3'>
                <ConsentMsg />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactForm;
