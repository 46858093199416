import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from '../../utils'
import { SubMenu } from '.'

export function Navbar({ menu }) {

  return (
    <nav className={`navbar-menu h-100`}>
      <ul className="mb-0 d-flex h-100 ps-0">
        {
          menu.map((menuItem, i) => (
            <li key={i} className="menu-item">
              <div className="menu-item-link-wrapper h-100 d-flex align-items-center">
                {/*👇 Main menu links go here */}
                {/* Specials / The Process / Areas / Locations  */}
                <Link
                  to={menuItem.pathname}
                  activeClassName="active-link"
                  partiallyActive={menuItem.pathname === "/" ? false : true}
                >
                  {menuItem.link}
                </Link>
                {
                  menuItem?.items &&
                  <span className="caret-icon caret-icon--down"><FontAwesomeIcon icon={faAngleDown} /></span>
                }
              </div>
              {menuItem?.items && <SubMenu menuItem={menuItem} />}
            </li>
          )
          )}
      </ul>
    </nav>
  )
}

export default Navbar
