import * as Yup from 'yup'

export function consultSchema() {
  const schema = Yup.object({
    first_name: Yup.string().required('Required').matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
    last_name: Yup.string().required('Required').matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
    email: Yup.string().email('Invalid email').required('Required'),
    phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
    description: Yup.string(),
    '00N1L00000F9eBV': Yup.string().required('Required'),
    updates: Yup.boolean(),
    consultType: Yup.string()
  })
  return schema
}

export default consultSchema