import React from 'react'

export function ReviewsBox({ stores }) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="reviews-box px-3 py-2 m-2 col-md-10">
          {
            stores.map((store, x) => (
              <div key={x} className="pt-3" >
                <iframe src={`https://reviewsonmywebsite.com/embed/v2/${store.platforms.reviews.list_token}`} style={{ width: "1px", minWidth: "100%", minHeight: "800px", border: "none", overflow: "scroll" }} title="Reviews" id="iFrameResizer0"></iframe>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ReviewsBox