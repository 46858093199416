import React from 'react'

export function SkinCareHero() {
  return (
    <section className="hero background">
      <div className="container milan-py">
        <div className="row">
          <div className="col-sm-8 offset-xl-1 col-xl-7">
            <h1 className=" pb-0">Have it made in the shade!</h1>
            <h1 className="milan-text-primary smalltitle">Be sun-smart.</h1>
            <p className="mt-4">Ultraviolet rays are the same year&#8209;round, so stay protected!</p>
          </div>
        </div>
      </div>
      <div className="p-5"></div>
    </section>
  )
}

export default SkinCareHero