import React from 'react'
import { Link } from 'gatsby'
import CostHeroGraphic from '../../../images/lp/cost/Cost_Hero_Graphic.webp'
import ArrowScroll from '../../../images/lp/waxing/3-arrows-scroll.webp'

export function CostHero() {
  return (
    <section className="hero pt-0">
      <div id="hero-top" className="container-fluid background">
        <div className="row justify-content-center">
          <div className="col-auto">
            <img src={CostHeroGraphic} alt="Cost Hero Graphic" />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <p className="text-center p-subhead">When we think of how much time and money is wasted on shaving & waxing, we cringe. You deserve to have smooth, hair-free skin at a price you can afford. That’s why we offer affordable payment plans for laser hair removal!</p>
            <figure className="text-center mt-5">
              <Link to="#unlimited_pkg"><img src={ArrowScroll} alt="Arrow Scroll" style={{ maxWidth: '100px' }} /></Link>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CostHero