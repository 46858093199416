import React from 'react'
import { Link } from 'gatsby'
import Lip_BA_Img from "../../images/areas/lipnchin/Lip_Top-Bottom.jpg";

export function IdealForPcos() {
  return (
    <section id="ideal-for-pcos" className="milan-bg-off-white">
      <div className="container milan-py">
        <h2 className="subheadlg milan-text-primary">Why Laser Hair Removal is Ideal for PCOS</h2>
        <div className="row">
          <div className="col-md-8">
            <div className="bg-transparent-6 bg-md-transparent p-3 p-md-0 rounded">
              <p>Laser hair removal is the best treatment for PCOS hair growth.</p>
              <ul className="list-style-disc">
                <li><strong>Go With Confidence.</strong> Hair is destroyed at the root<span className="d-none d-md-inline"> and will never grow back</span>, so there’s no need to worry about shaving or tweezing before leaving the house. You’re always ready to go! </li>
                <li><strong>Comfortable Treatments.</strong> Unlike painful electrolysis, you’ll comfortably remove unwanted hair without having to let it grow out first.<span className="d-none d-md-inline"> Laser treatments, on average, take less than 30 minutes, while electrolysis typically takes several hours to complete.</span></li>
                <li><strong>Medically-based.</strong> Managing the symptoms of PCOS &#8212; including hair growth &#8212; is a delicate matter that should be done under the guidance of medical professionals to achieve your best results.</li>
              </ul>
              <p>You’ve struggled with the embarrassing stubble &amp; ingrown hairs of polycystic ovarian syndrome for far too long. Take the first step toward getting permanent results.</p>
              <p className="mb-0"><Link to="#consult" className="milan-text-primary">Schedule your free consultation to learn more about laser hair removal today!</Link></p>
            </div>
          </div>
          <div className="col-md-4 text-center mt-md-5">
            <img src={Lip_BA_Img} alt="Lip &amp; Chin Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default IdealForPcos