import React from 'react'
import { TextField } from '@mui/material'

export function CouponInput({ coupon, setCoupon }) {
  const handleCouponSubmit = () => {
    if (coupon.value.trim().length !== 6) {
      setCoupon({ ...coupon, valid: false, errorMsg: 'Coupon code must be 6 character' })
    } else {
      setCoupon({ ...coupon, fetchedData: null, submit: true })
    }
  }
  return (
    <>
      <h2 className="mt-4 subheadsm">Enter Your Code</h2>
      <div>
        <TextField
          style={{ width: 240 }}
          inputProps={{
            maxLength: 6,
            style: {
              fontSize: 24,
              letterSpacing: 20,
              textTransform: "uppercase",
              backgroundColor: '#fff'
            }
          }}
          variant="outlined"
          label="Code"
          value={coupon.value}
          onChange={e => setCoupon({ ...coupon, value: e.target.value.toUpperCase(), submit: false, valid: true, errorMsg: '' })}
          error={!coupon.valid}
          helperText={coupon.errorMsg}
        />
      </div>
      <button type='button' className="milan-btn milan-cta-btn mt-4" onClick={handleCouponSubmit}>{coupon.isSubmitting ? 'Submitting...' : 'See Your Discount'}</button>
    </>
  )
}

export default CouponInput