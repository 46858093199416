import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {SignUpForm} from '../forms';

export function SignUpModal({siteData, showSignUpModal, setShowSignUpModal}) {
  return (
    <Modal centered id='signup-modal' show={showSignUpModal} onHide={() => setShowSignUpModal(false)} aria-labelledby='signup-modal-title' contentClassName='milan-bg-white'>
      <Modal.Header className='justify-content-center border-bottom-0 py-0' closeButton>
        <Modal.Title className='text-center' id='signup-modal-title'>
          <h3 className='subhead-sm mb-0 mt-4 milan-text-primary'>Get Milan Laser Updates</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='mb-2'>
        <SignUpForm {...{siteData}} />
      </Modal.Body>
    </Modal>
  );
}

export default SignUpModal;
