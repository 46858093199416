import React from "react";
const frontDesk = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FFront-Desk.webp?alt=media&token=2c9bc221-3e85-4d35-8fe2-64d3a834f36f";
const frontDesk2 = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FFront-Desk-Incorrect.webp?alt=media&token=bad03a2d-cfad-456c-92a1-b1666cf72d5d";
const lobby = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FLobby.webp?alt=media&token=466d45af-1646-4f2b-9949-7093b39b7bec";
const lobby2 = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FLobby2.webp?alt=media&token=6c4c080b-d755-474a-bef5-4126bc4eaf3a";
const consultationRoom = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FConsult.webp?alt=media&token=0a695744-55ff-4a89-9233-48e7557033be";
const treatmentRoom = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2FTreatment.webp?alt=media&token=765a087a-8d0b-460a-8ab1-1606826be1f5";

export function BestPracticesInteriorPhotos() {
  return (
    <section id="interior" className="milan-py">
      <div className="container">
        <h2 className="pt-3 milan-text-primary">INTERIOR PHOTOS</h2>
        <p>
          All four of our interior photographs are used primarily for our About Us page found here and are 461x388. Please shoot all interior photos
          in landscape and send us the full resolution files. Feel free to photograph as you see fit however please include at least one shot like the
          examples shown here.
        </p>
        <div className="row pb-5">
          <div className="col-12">
            <h2 className="pt-3 milan-text-primary subheadsm">Front Desk</h2>
            <ul className="list-style-disc">
              <li>Include our logo.</li>
              <li>Shot from the perspective of someone walking up to the front desk.</li>
              <li>Prefer photos shot at an angle if possible.</li>
            </ul>
          </div>

          <div className="col-12 col-md-6">
            <img src={frontDesk} alt="front desk" className="w-100 h-100 img-thumbnail" />
          </div>
          <div className="col-12 col-md-6">
            <img src={frontDesk2} alt="front desk" className="w-100 h-100 img-thumbnail" />
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-12">
            <h2 className="pt-3 milan-text-primary subheadsm">Lobby</h2>
            <ul className="list-style-disc">
              <li>Include the whole seating area.</li>
              <li>Prefer photos shot at an angle from hallway.</li>
            </ul>
          </div>

          <div className="col-12 col-md-6">
            <img src={lobby} alt="lobby" className="w-100 img-thumbnail" />
          </div>
          <div className="col-12 col-md-6">
            <img src={lobby2} alt="lobby" className="w-100 img-thumbnail" />
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-12 col-md-6">
            <h2 className="pt-3 milan-text-primary list-style-disc">Consultation Room</h2>
            <ul className="list-style-disc">
              <li>Booklet with Milan logo should be included in the shot.</li>
              <li>Prefer photos that include the TV.</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <img src={consultationRoom} alt="consultation room" className="w-100 img-thumbnail" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="pt-3 milan-text-primary subheadsm">Treatment Rooms(s)</h2>
            <p>Most of our locations only have one treatment room. Our store manager will communicate if we have more than one.</p>
            <ul className="list-style-disc">
              <li>Our store manager is instructed to not have a sheet on the treatment table.</li>
              <li>Note some of our chairs my be discolored and that’s okay. We will color correct.</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <img src={treatmentRoom} alt="treatment room(s)" className="w-100 img-thumbnail" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BestPracticesInteriorPhotos;
