import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import CONSULT_ICON from '../../../images/process/home/icon-consult.webp'

export function YourFreeConsult() {
  return (
    <section id="your-free-consult" className="background bg-center position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="arrow-left" src="../../../images/process/home/arrow-left.png" imgClassName="middle-icon" />
        <div className="row">
          <div className="col-md-6 border-right">
            <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">              
            <h3 className='subpointsm'>
               Your <strong>Free</strong></h3>
              <h3 className="subpointlg">Consult</h3>
              <p className="my-4">We know this is a big deal, so your consultation is on us! We’ll discuss the area(s) you want to have
                treated, answer your questions, and help you gain a better understanding of the procedure. Our team of
                laser hair removal experts will also assess your skin tone and hair type
                to establish which laser would be used.</p>
              <p className="mb-0">We’ll also go over pricing options and payment plans with you.</p>
            </div>
          </div>
          <div className="col-md-6">
            <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
              <img alt="Milan Laser Hair Removal Process - Your Free Consult" src={CONSULT_ICON} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default YourFreeConsult