import React from "react";
import { Link } from "gatsby"
// import Bikini_BA_Img from '../../../images/areas/bikini/Bikini_Top-Bottom.webp'
const Bikini_BA_Img = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fareas%2Fbikini%2FBikini_Top-Bottom.webp?alt=media&token=13e60faf-2aa5-4a72-af48-99efc0e30ca3'

export function HowBikiniWork() {
  return (
    <section id="how-bikini-works" className="full-section background secondBG">
      <div className="container">
        <h2 className="text-center subheadsm">How Bikini Laser Hair Removal Works</h2>
        <div className="row mt-lg-4 pt-3">
          <div className="col-md-7 mb-3">
            <div className="bg-transparent-5 bg-xl-transparent p-3 p-xl-0 rounded anchor-blue">
              <p className="d-none d-sm-block">Your bikini line includes 3 gauge-widths (about 2  inches) below the hip crease and one gauge below the hip line. In a fraction of the time, you can say goodbye to blades and hot waxes invading your personal space.</p>
              <p className="d-sm-none">The laser targets your hair follicle to eliminate the root of the hair without harming the skin. A quick pulse, a cooling mist, and your hair is destroyed forever (<Link to="/process/">See a demo</Link>).</p>
              <p className="d-none d-sm-block">The laser targets your hair follicle to eliminate the root of the hair without harming the skin. In a fraction of a second, the laser pulses, and it’s followed by a cooling mist to immediately ease any discomfort. With the root of the hair destroyed, it will never regrow so you’re hair-free forever.</p>
              <div className="d-none d-sm-block">
                <p>Treatments are quick and don’t require any time for recovery so you can be treated over lunch and go on with your day.</p>
                <ul className="list-style-disc">
                  <li>You can shave prior to your treatment, so no need to keep your bikini line wild!</li>
                  <li>The laser is gentle enough to use all over the body, including the sensitive bikini area.</li>
                </ul>
              </div>
              <p className="mb-0"><strong>Razor bumps</strong> and <strong>ingrown hairs</strong> are a problem of the past with laser hair removal.</p>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 col-xl-4 text-center text-md-start">
            <img src={Bikini_BA_Img} alt="Bikini Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
          </div>
        </div>
      </div>
    </section>
  );
}
export default HowBikiniWork
