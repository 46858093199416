import React from 'react'
import { Field, ErrorMessage } from 'formik'
import InputMask from 'react-input-mask'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons";
import { TextError } from '.'

export function PhoneInput(props) {
  const { label, name, placeholder, optional, ...rest } = props
  return (
    <>
      {label && <label htmlFor={name}>{label} {!optional && <sup><FontAwesomeIcon icon={faAsterisk} /></sup>}</label>}
      <Field name={name} >
        { // These props are coming from the Formik Field compoenent and it contains: field, form, meta
          props => {
            const { field, meta } = props
            return <>
              <InputMask
                mask="+1\ 999-999-9999"
                maskChar={null}
                className={`form-control phone_input ${meta.error && meta.touched ? 'border-error' : ''}`}
                id={name}
                {...field} {...rest}
                placeholder={placeholder ? placeholder : '+1 999-999-9999'} />
            </>
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export default PhoneInput