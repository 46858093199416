import React from "react";

export function ActiveLifestyleTime() {
  return (
    <section id="activelifestyle-time" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-xl-1 col-xl-5 milan-bg-tur milan-text-white milan-py-lg">
            <div className="row">
              <div className="col-auto">
                <h2 className="subheadlg">Time, Time, Time!</h2>
              </div>
              <div className="col-auto z-index-100">
                <p>
                  Work, life commitments, and activities really add up. Worrying about unwanted hair is a nuisance, and no one wants to spend time
                  making waxing appointments. Laser hair removal appointments are fast and easy, plus the results are permanent so you get to spend
                  more time doing what you love. Learn more about why laser hair removal is the best hair removal method for those on the go!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xl-6 vrt-divider vrt-divider-reversed vrt-divider-turq"></div>
        </div>
      </div>
      <div className="spacer d-lg-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
  );
}
export default ActiveLifestyleTime;
