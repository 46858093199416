import React from 'react';

export function CareersMilanMagic() {
  return (
    <>
      <section
        id='milan-magic'
        className='milan-magic-bg d-flex align-items-center hr-divider hr-divider-careers-mirror pt-5 pb-4'
      >
        <div className='container section-width'>
          <h2 className='text-center pb-3 pb-5-sm subheadlg milan-text-primary'>
            Do You Have The Milan Magic?
          </h2>
          <p>
            We’re looking for people who are passionate about making a
            difference. Laser hair removal isn’t just about smooth skin. It’s
            about changing people’s lives by offering a transformative service
            in a supportive environment. We’re a growing company with excellent
            benefits, a progressive culture, and endless opportunities.
          </p>
        </div>
      </section>
      <section className='d-flex justify-content-center container mb-5 pb-md-4'>
        <div className='row milan-magic-grid-section text-center text-white g-4'>
          <div className='col-12 col-md-6'>
            <div className='d-flex flex-column justify-content-center align-items-center magic-sqr-1'>
              <p className='mb-1 p-subhead text-uppercase'>
                <strong>Medical Professionals</strong>
              </p>
              <p className='px-4'>
                All procedures at Milan Laser are performed by highly-skilled
                medical professionals. If you crave a work-life balance (we’re
                serious - no overnights and no holidays!) and the opportunity to
                make a real impact on the lives of others, check out our open
                medical positions now.
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='d-flex flex-column justify-content-center align-items-center magic-sqr-2'>
              <p className='mb-1 p-subhead text-uppercase'>
                <strong>Sales Roles</strong>
              </p>
              <p className='px-4'>
                Set the tone for happy clients, exciting sales, excellent
                customer service, and the opportunity to grow your own team at
                Milan Laser. Our clinic managers cultivate lasting relationships
                with clients, driving sales for the company and opportunities
                for you, like uncapped commissions and room for growth within
                the organization.
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='d-flex flex-column justify-content-center align-items-center magic-sqr-3'>
              <p className='mb-1 p-subhead text-uppercase'>
                <strong>Clinic Operations</strong>
              </p>
              <p className='px-4'>
                Our client specialists are among the most influential roles at
                Milan as they are the first friendly faces our clients meet at
                their initial appointments and help them become the most
                confident versions of themselves as they embark on their
                hair-free journeys. The sky is the limit for client specialists
                — this role offers many opportunities for growth and internal
                promotions within Milan.{' '}
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='d-flex flex-column justify-content-center align-items-center magic-sqr-4'>
              <p className='mb-1 p-subhead text-uppercase'>
                <strong>Corporate Team</strong>
              </p>
              <p className='px-4'>
                Our corporate teams are expanding to meet the needs of our
                fast-growing organization. Whether you’re a financial genius, a
                marketing maven, an HR expert, a facilities pro, a technology
                wizard, a client service guru, or a warehouse and packaging
                master, we have the career for you. Our corporate teams enjoy an
                open, collaborative work environment and a fast-paced
                atmosphere.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CareersMilanMagic;
