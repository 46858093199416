import React from 'react'

export function CareersCulture() {
  return (
    <section id="our-culture" className="our-culture-bg py-5 hr-divider hr-divider-consult hr-divider-reversed">
      <div className="container py-lg-3">
        <h2 className="text-center pb-3 pb-5-sm milan-text-primary subheadlg">Our Culture</h2>
        <div className="row d-flex justify-content-center">
          <div className="col-xl-9">
            <div className="row">
              <div className="col">
                <p>
                  The Milan Laser team is comprised of the most talented, hard-working, and fun-to-be-around folks in the industry. We take pride in being the
                  largest laser hair removal company in the U.S., and our commitment to offering world-class customer service runs deep. At Milan Laser, we
                  believe in recognizing employees for their contributions and celebrating our accomplishments along the way.
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center text-center">
              <div className="col-12 col-md-3 px-1">
                <p className="mb-0  milan-text-tur">
                  <strong>Culture</strong>
                </p>
                <iframe title="culture-1" src="https://player.vimeo.com/video/259741775" className="careers-videos" allowFullScreen></iframe>
              </div>
              <div className="col-12 col-md-3 px-1">
                <p className="mb-0  milan-text-tur">
                  <strong>Clinic Manager</strong>
                </p>
                <iframe title="culture-2" src="https://player.vimeo.com/video/259924357" className="careers-videos" allowFullScreen></iframe>
              </div>
              <div className="col-12 col-md-3 px-1">
                <p className="mb-0  milan-text-tur">
                  <strong>Medical Provider</strong>
                </p>
                <iframe title="culture-3" src="https://player.vimeo.com/video/259740121" className="careers-videos" allowFullScreen></iframe>
              </div>
              <div className="col-12 col-md-3 px-1">
                <p className="mb-0  milan-text-tur">
                  <strong>Client Specialist</strong>
                </p>
                <iframe title="culture-4" src="https://player.vimeo.com/video/259926181" className="careers-videos" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareersCulture;
