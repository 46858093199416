import React from "react";
import { Link } from "gatsby";

export function FolliculitisSkinTones() {
  return (
    <section id="all-skin-tones">
      <div className="spacer d-sm-none"></div>
      <div className="container milan-py">
        <div className="row justify-content-end">
          <div className="col-sm-10 col-lg-8 col-xl-10">
            <h2 className="subheadlg milan-text-primary">
             Folliculitis Treatment for All Skin Tones
            </h2>
            <div className="bg-transparent-6 bg-xxl-transparent p-3 p-xxl-0 rounded">
              <p>
                There’s a misconception that laser hair removal is only safe for lighter skin tones. While that might have been true when the FDA
                first approved laser hair removal in the 90s, a lot has changed since then. Unlike IPL (intense pulsed light) devices, our lasers can
                safely and effectively treat all skin tones.
              </p>
              <p>
                That’s because it uses two laser technologies—one that’s geared toward fair skin and one that’s geared toward darker skin—which allows
                our highly-trained medical professionals to create customized treatment plans to fit the exact needs of each client’s skin tone and
                hair type. Creating a custom treatment plan for each individual helps ensure that every treatment eliminates as many hair follicles as
                possible… so each treatment will lower your risk of a folliculitis outbreak.{" "}
                <Link to="/process/" className="milan-text-primary">
                  See a demo.
                </Link>
              </p>
              <p>
                While most folliculitis treatments are safe for all skin tones they also usually only treat the symptoms rather than the cause of the
                condition. Laser hair removal treatment option that will truly prevent future outbreaks.
              </p>
              <p>
                <Link to="/process/compare/" className="milan-text-primary">
                  Learn more about why laser hair removal is the best solution for preventing folliculitis.
                </Link>
              </p>
              <p className="mb-0">
                Have unanswered questions about how laser hair removal relates to managing your folliculitis? Our&nbsp; knowledgeable staff are happy
                to answer any questions you may have.{" "}
                <Link to="#consult" className="milan-text-primary">
                  Find out if laser hair removal is right for you at your free consultation.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolliculitisSkinTones;
