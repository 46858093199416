import React from 'react';

export function MilitaryHero() {
  return (
    <section className='hero py-lg-milan-100 py-xxl-milan-150 milan-bg-white'>
      {/* bg when stacked*/}
      <div className='d-lg-none stack-hero-bg' />
      <div className='container'>
        <div className='row'>
          <div className='offset-lg-6 col-lg-6'>
            <h1 className='milan-text-primary'>Milan's Military Discount</h1>
            <p className='p-subhead milan-text-tur'>
              Milan Laser Hair Removal Has a Special Discount for Our Service
              Members!
            </p>
            <p className='pt-4'>
              Milan Laser is proud to support our military members. We offer an{' '}
              <strong>exclusive 60% OFF</strong> all unlimited laser hair
              removal packages for active duty, retirees, and veteran military
              personnel. Book your FREE consultation and treatment today to
              learn more about our monthly payments, unlimited treatments, FREE
              touch-ups, and guaranteed results for life!*
            </p>
            <p className='milan-fp'>
              *Discount may not be combined with other promotions. Not available
              for previous purchases.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MilitaryHero;
