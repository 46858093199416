import React from 'react';
import AmazonCard from '../../images/share/Amazon-Gift-Card.webp';
import AppleCard from '../../images/share/Apple-Gift-Card.webp';
import SephoraCard from '../../images/share/Sephora-Gift-Card.webp';
import StarbucksCard from '../../images/share/Starbucks-Gift-Card.webp';
import TargetCard from '../../images/share/Target-Gift-Card.webp';

export function RedeemableRewards(props) {
  const giftCardAmt = props.giftCardAmt ? props.giftCardAmt : 100;
  const copyRightYear = props.copyRightYear ? props.copyRightYear : 2022;
  return (
    <section id='rewards' className='milan-bg-off-white milan-py'>
      <div className='container'>
        <h2 className='subheadsm text-center milan-text-primary'>
          Redeem your rewards at any of these participating retailers.
        </h2>
        <div className='row mb-5 justify-content-center'>
          <div className='d-flex flex-wrap justify-content-center px-lg-5 px-xl-0 mx-4'>
            <div className='reward-card p-1 p-sm-3'>
              <img src={AmazonCard} alt='Milan Laser refer a friend reward' />
            </div>
            <div className='reward-card p-1 p-sm-3'>
              <img src={AppleCard} alt='Milan Laser refer a friend reward' />
            </div>
            <div className='reward-card p-1 p-sm-3'>
              <img src={SephoraCard} alt='Milan Laser refer a friend reward' />
            </div>
            <div className='reward-card p-1 p-sm-3'>
              <img
                src={StarbucksCard}
                alt='Milan Laser refer a friend reward'
              />
            </div>
            <div className='reward-card p-1 p-sm-3'>
              <img src={TargetCard} alt='Milan Laser refer a friend reward' />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <p className='milan-fp'>
              Restrictions apply, see amazon.com/gc-legal. Beware of gift card
              scams. Do not share your code. Valid only for U.S. transactions in
              Apple properties. For assistance, visit support.apple.com/giftcard
              or call 1-800-275-2273. Not redeemable at Apple resellers or for
              cash, and no resale, refunds, or exchanges, except as required by
              law. Apple is not responsible for unauthorized use. Terms apply;
              see apple.com/us/go/legal/gc. Issued by Apple Value Services, LLC
              (AVS). © 2020 Apple Inc. All rights reserved. Sephora is not a
              sponsor of this promotion or otherwise affiliated with this
              company. The logos and other identifying marks attached are
              trademarks of and owned by each represented company and/or its
              affiliates. Please visit each company’s website for additional
              terms and conditions. The Starbucks word mark and the Starbucks
              Logo are trademarks of Starbucks Corporation. Starbucks is also
              the owner of the Copyrights in the Starbucks Logo and the
              Starbucks Card designs. All rights reserved. Starbucks is not a
              participating partner or sponsor in this offer. Target and the
              Bullseye Design are registered trademarks of Target Brands, Inc.
              Terms and conditions are applied to gift cards. Target is not a
              participating partner in or sponsor of this offer.
            </p>
            <p className='mb-0 milan-fp'>
              *Only one reward per referral. ${giftCardAmt} gift card can be
              split between multiple stores. Reward links will be emailed only
              when referral purchases. ©{copyRightYear} Milan Laser Hair
              Removal. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RedeemableRewards;
