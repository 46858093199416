import React, { useState } from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

export function PressCustomToggle() {
  const [showMore, setShowMore] = useState(false);
  const decoratedOnClick = useAccordionButton(
    "0",
    () => setShowMore(!showMore)
  );
  return (
    <div className="text-center pb-4">
      <button type="button" className="milan-btn milan-navy-btn" onClick={decoratedOnClick}>
        {(!showMore) ? 'Show More' : 'Show Less'}
      </button>
    </div>
  );
}

export default PressCustomToggle