import * as Yup from 'yup';

export const contactSchema = Yup.object().shape({
  first_name: Yup.string().required('Required').matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
  last_name: Yup.string().required('Required').matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
  email: Yup.string().email('Invalid email').required('Required'),
  phoneMask: Yup.string().min(15, 'Invalid phone number').required('Required'),
  '00N1L00000F9eBV': Yup.string().required('Required'),
  description: Yup.string(),
  updates: Yup.boolean(),
});
