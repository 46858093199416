import React from 'react'
import { Link } from "gatsby"
import BenefitsBeforeAfter from '../../../images/lp/B-A_MensArea_250.webp'

export function LpMenLHRBenefits() {
  return (
    <section id="lp-men-benefits" className="milan-bg-tur">
      <div className="background spacer d-xl-none hr-divider hr-divider-reversed-flipped hr-divider-turq-light"></div>
      <div className="container pb-3 pb-md-0">
        <div className="row g-0">
          <div className="col-xl-5 col-xxl-6 vrt-divider vrt-divider-turq-alt"></div>
          <div className="col-xl-7 col-xxl-6 milan-bg-tur milan-text-white milan-py bg-extend-right">
            <h2 className="subheadlg">Benefits of Laser Hair Removal</h2>
            <div className="row flex-md-row-reverse">
              <div className="col">
                <p>Laser hair removal is a permanent solution to unwanted hair†. We can remove hair from nearly any part of the body without razor burn and ingrown hairs as a result.</p>
                <p>Laser hair removal is an FDA-cleared treatment for the removal of hair from the body. Unlike shaving, IPL or waxing, laser hair removal will produce amazing results without the mess and pain of outdated treatments.</p>
              </div>
              <div className="col-md-auto">
                <div className="text-center my-4 mt-md-1">
                  <img src={BenefitsBeforeAfter} style={{ maxWidth: '240px' }} alt="Men Laser Hair Removal Photo, Before & After" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
              <p>Whether it’s your underams, lip, chin, or back/chest, we can remove hair from any area on the body.</p>
                <p>Milan's staff is discreet and professional, so even the most sensitive areas will be well-cared for. <Link to="/locations/contact/"><strong>Got a question</strong> about a specific area? Don't be bashful, we've heard them all!</Link></p>
                <p className="mb-0"><Link to="#consult">Book your <strong>free consultation</strong></Link> to customize your hair removal so you can be smooth in all the right places.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LpMenLHRBenefits