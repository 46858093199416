export const variations = {
  "buffalo (amherst)": "Buffalo North",
  "buffalo (orchard park)": "Buffalo South",
  "davenport": "Quad Cities",
  "denver (aurora)": "Denver East",
  "denver (northglenn)": "Denver North",
  "denver (sheridan)": "Denver South",
  "denver (wheat ridge)": "Denver West (Wheat Ridge)",
  "fort wayne (west)": "Fort Wayne",
  "indianapolis (keystone)": "Indianapolis North",
  "indianapolis (greenwood)": "Indianapolis South (Greenwood)",
  "indianapolis (plainfield)": "Indianapolis West (Plainfield)",
  "iowa city (coralville)": "Coralville",
  "johnson city": "Tri-Cities",
  "kansas city (liberty)": "Kansas City",
  "lincoln (south)": "Lincoln",
  "louisville (east)": "Louisville",
  "minneapolis (maple grove)": "Maple Grove",
  "milwaukee (menomonee falls)": "Menomonee Falls",
  "milwaukee (brookfield)": "Milwaukee",
  "milwaukee (whitefish bay)": "Milwaukee NS",
  "st. louis (maplewood)": "Brentwood",
  "st. louis (o'fallon)": "OFallon",
  "st. louis (town and country)": "Town & Country",
}