import React from 'react'
import { Link } from "gatsby"

export function ComparingWaxing() {
  return (
    <section id="comparing-waxing" className="milan-py background">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-center pb-0">Comparing Waxing to Laser Hair Removal</h2>
        <div className="row justify-content-center mt-4 mt-lg-5">
          <div className="col-lg-10 col-xl-9">
            <p className="p-subhead mb-4">Waxing and laser hair removal both work on all skin tones, but that’s pretty much the only thing these hair removal methods have in common. </p>
            <ul className="list-style-disc">
              <li className="mb-3"><strong>Comfort.</strong> When compared to waxing – laser hair removal is a significantly less painful way of removing body hair. </li>
              <li className="mb-3"><strong>Results.</strong> You’ll get permanent results from laser hair removal treatments rather than the temporarily hair-free skin that waxing provides.</li>
              <li className="mb-3"><strong>Time.</strong> With laser hair removal, the average person is 95%+ hair free in 7 to 10 treatments – total! Waxing requires 10 or more appointments every year to maintain your results</li>
              <li className="mb-3"><strong>Cost.</strong> With laser hair removal, you’ll get permanent results without every worrying about tipping or touch-up fees. A monthly payment for laser hair removal costs less than a monthly appointment for waxing&#8230; a lifetime of leg waxing alone can cost over $15,000.</li>
            </ul>
            <p className="mb-0"><Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link></p>
          </div>
        </div>
      </div>
      <span id="guarantee"></span>
    </section>
  )
}

export default ComparingWaxing