import React from 'react'

export function MedicallyHero() {
  return (
    <section className="hero">
      <div className="spacer d-sm-none"></div>
      <div className="container">
        <div className="row justify-content-sm-end">
          <div className="col-sm-8">
            <div className="p-4 bg-transparent-6 rounded">
              <h1 className="milan-text-primary pb-2 smalltitle">Medically Recommended</h1>
              <h2 className="subheadsm mb-4">Laser Hair Removal</h2>
              <p>Many medical professionals recommend laser hair removal as a permanent solution to unwanted hair for individuals who:</p>
              <ul className="list-style-disc">
                <li>Have experienced an <strong>amputation.</strong></li>
                <li>Regularly develop <strong>folliculitis.</strong></li>
                <li>Have had <strong>pilonidal cysts.</strong></li>
                <li>Struggle with <strong>hidradenitis suppurativa.</strong></li>
              </ul>
              <p className="mb-0">Get <strong>permanent results</strong> with laser hair removal.</p>
            </div>
          </div>
        </div>
      </div>
      <span id="amputee"></span>
    </section>
  )
}

export default MedicallyHero