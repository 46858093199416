import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import { pressAwardsList } from "./util/pressAwardsList";

//This is used for Desktop version.
//Divides the awards into chunks of three
const chunk = (xs, n) => (xs.length <= n ? [[...xs]] : [xs.slice(0, n)].concat(chunk(xs.slice(n), n)));
const awardChunks = chunk(pressAwardsList, 3);

export function PressAwards() {
  return (
    <section id="awards" className="milan-py hr-divider hr-divider-consult hr-divider-reversed">
      <div className="container">
        <h2 className="subheadlg milan-text-primary">Milan Awards</h2>
        {/* Desktop Only */}
        <Carousel className="d-none d-sm-block">
          {awardChunks.map((slide, i) => (
            <Carousel.Item key={i}>
              <div className="row pb-5">
                {slide.map((award, j) => (
                  <div className="col-sm-4 text-center d-flex justify-content-center item-height" key={j}>
                    <Card style={{ width: "13rem" }}>
                      <a href={award.link} target="_blank">
                        <Card.Img variant="top" src={Object.values(award.image)[0]} />
                      </a>
                      <Card.Body>
                        <Card.Title>
                          <a href={award.link} className="milan-text-navy">{award.title}</a>
                        </Card.Title>
                        <Card.Text className="milan-text-navy">{award.location}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        {/* Mobile */}
        <Carousel className="d-block d-sm-none">
          {pressAwardsList.map((award, i) => (
            <Carousel.Item className="pb-5" key={i}>
              <div className="text-center d-flex justify-content-center item-height">
                <Card style={{ width: "13rem" }}>
                  <a href={award.link} target="_blank">
                    <Card.Img variant="top" src={Object.values(award.image)[0]} />
                  </a>
                  <Card.Body>
                    <Card.Title>
                      <a href={award.link} className="milan-text-navy">{award.title}</a>
                    </Card.Title>
                    <Card.Text className="milan-text-navy">{award.location}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default PressAwards;