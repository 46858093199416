import React, { useEffect } from 'react';
import { FormikControl } from '../formik';
import { usePosition, useNearbySearch } from '../../../hooks';

export function LocationsDropdownWrapper({ siteData, formik, fieldName }) {
  const { latitude, longitude, errorMsg, isGeoEnabled, setIsGeoEnabled } =
    usePosition(false);
  const { nearbyMilanStores, loadingNearbyStores, setLoadingNearbyStores } =
    useNearbySearch(
      latitude,
      longitude,
      siteData,
      setIsGeoEnabled,
      isGeoEnabled,
      undefined
    );
  useEffect(() => {
    // Update locations dropdown
    if (nearbyMilanStores?.length > 0) {
      formik.setFieldValue(fieldName, nearbyMilanStores[0].salesforceValue);
    }
  }, [nearbyMilanStores]);

  useEffect(() => {
    // Update locations dropdown
    if (siteData?.selectedStore.store) {
      formik.setFieldValue(
        fieldName,
        siteData?.selectedStore.store.salesforceValue
      );
      formik.validateField(fieldName);
    } else {
      formik.setFieldValue(fieldName, '');
      formik.validateField(fieldName);
    }
  }, [siteData?.selectedStore]);

  return (
    <>
      <FormikControl
        control='selectLocation'
        id={fieldName}
        name={fieldName}
        title='Location'
        siteData={siteData}
        aria-required='true'
        role='combobox'
        aria-label='Locations'
      />
      <div
        className={`position-relative ${errorMsg === 'User denied Geolocation' ? 'show-tooltip' : ''}`}
      >
        {loadingNearbyStores &&
        isGeoEnabled &&
        errorMsg !== 'User denied Geolocation' ? (
          <small>
            <em>Searching...</em>
          </small>
        ) : (
          <small className='d-inline-block mt-1'>
            <em
              className={`text-decoration-underline ${errorMsg !== 'User denied Geolocation' ? 'pointer' : 'help'}`}
              onClick={() => {
                setIsGeoEnabled(true);
                setLoadingNearbyStores(true);
              }}
            >
              Find Milan Near You?
            </em>
            {errorMsg === 'User denied Geolocation' && (
              <em className='milan-fp'>
                {' '}
                (Geolocation is disabled in your browser)
              </em>
            )}
          </small>
        )}
      </div>
    </>
  );
}

export default LocationsDropdownWrapper;
