import awardsBuffalo from '../../../images/press/press-awards-buffalo.png';
import awardsCincinnati from '../../../images/press/press-awards-cinci.webp';
import awardsDallas from '../../../images/press/press-awards-dallas.webp';
import awardsDetroit from '../../../images/press/press-awards-bestOfDetroit.webp';
import awardsDenver from '../../../images/press/press-awards-ExpertiseDenver.jpg';
import awardsDesmoines from '../../../images/press/press-awards-desmoines.jpg';
import awardsDesmoines2 from '../../../images/press/press-awards-DesMoines.webp';
import awardsHartford from '../../../images/press/press-awards-hartford.png';
import awardsJanesville from '../../../images/press/press-awards-janesville.png';
import awardsLansing from '../../../images/press/press-awards-lansing.webp';
import awardsMinnesota from '../../../images/press/press-awards-minnesota.png';
import awardsOmaha from '../../../images/press/press-awards-omaha.webp';
import awardsOmaha2023 from '../../../images/press/press-awards-omaha-2023.webp';
import awardsPortland from '../../../images/press/press-awards-portland.webp';
import awardsReaders from '../../../images/press/press-awards-readers.webp';
import awardsSumner from '../../../images/press/press-awards-sumner-county.webp';
import awardsUtica from '../../../images/press/press-awards-utica.png';
import awardsWausau from '../../../images/press/press-awards-wausau.png';
import awardsMiddletown from '../../../images/press/press-award-middletown.webp';
import awardsWilliamsonCounty from '../../../images/press/press-award-williamsonCounty.webp';
import awardsBuffaloSpree from '../../../images/press/press-award-buffaloSpree.webp';
import awardsRegionalsMidwest2024 from '../../../images/press/press-awards-Inc-Regionals-Midwest-2024.webp';
import awardsRegionalsBPTW from '../../../images/press/press-awards-bptw-Omaha.webp';
import awardsInc5000 from '../../../images/press/press-awards-Inc-5000.webp';
import awardsRegionalsMidwest2023 from '../../../images/press/press-awards-Inc-Regional Midwet-2023.webp';
import awardsBom from '../../../images/press/Bom-2024_logo_Sign.webp';

export const pressAwardsList = [
  {
    link: '',
    image: { awardsRegionalsMidwest2024 },
    title: 'Inc. Regionals Midwest - 2024 (No. 43)',
    location: '',
  },
  {
    link: 'https://www.miaminewtimes.com/best-of/2024/readers-choice/best-laser-hair-removal-20458931',
    image: { awardsBom },
    title: 'Best of Miami - 2024',
    location: 'Miami, FL',
  },
  {
    link: '',
    image: { awardsInc5000 },
    title: 'Inc. 5000 - 2023 (No. 1125)',
    location: '',
  },
  {
    link: '',
    image: { awardsRegionalsBPTW },
    title: 'Best Places to Work in Omaha - 2024',
    location: 'Omaha, NE',
  },
  {
    link: '',
    image: { awardsRegionalsMidwest2023 },
    title: 'Inc. Regionals Midwest - 2023 (No. 51)',
    location: '',
  },
  {
    link: 'https://www.buffalospree.com/best_of_wny/winners/body-basics/article_d4bb190a-2730-11ee-9f4f-8b196c7e1af8.html',
    image: { awardsBuffaloSpree },
    title: 'Best of WNY 2023',
    location: 'Buffalo, NY',
  },
  {
    link: 'https://recordonline.gannettcontests.com/2023-Readers-Choice-Middletown-Winners/gallery/?group=465074',
    image: { awardsMiddletown },
    title: 'Best of Middletown 2023',
    location: 'Middletown, NY',
  },
  {
    link: 'https://tennessean.gannettcontests.com/2023-Best-of-the-Best-Williamson-Winners/gallery/?group=465500',
    image: { awardsWilliamsonCounty },
    title: 'Best of Williamson County 2023',
    location: 'Nashville, TN',
  },
  {
    link: 'https://tennessean.gannettcontests.com/2023-Best-of-the-Best-Sumner-Winners/gallery/?group=462795',
    image: { awardsSumner },
    title: 'Best of Sumner 2023',
    location: 'Nashville, TN',
  },
  {
    link: 'https://greensboro.com/special_sections/page-q1/page_142a0ecb-59fa-5d86-849f-f158b18303ec.html',
    image: { awardsReaders },
    title: 'Readers Choice 2023',
    location: 'Greensboro, NC',
  },
  {
    link: 'https://issuu.com/omaha-world-herald/docs/oca_2023_issuu_conversion',
    image: { awardsOmaha2023 },
    title: 'Best of Omaha 2023',
    location: 'Omaha, NE',
  },
  {
    link: 'https://www.citybeat.com/best-of/2023/shops-and-services',
    image: { awardsCincinnati },
    title: 'Best of Cincinnati 2023',
    location: 'Cincinnati, OH',
  },
  {
    link: 'https://desmoines.communityvotes.com/2022/12/wellness-hair-and-beauty/hair-removal-and-waxing',
    image: { awardsDesmoines2 },
    title: 'Best of Des Moines 2023',
    location: 'Des Moines, IA',
  },
  {
    link: 'https://www.bestofthe207.com/listing/2022/customer-service',
    image: { awardsPortland },
    title: 'Best of Portland 2023',
    location: 'Portland, ME',
  },
  {
    link: 'https://www.dfwfavorites.com/listing/hair-removal',
    image: { awardsDallas },
    title: 'Best of Dallas 2023',
    location: 'Dallas, TX',
  },
  {
    link: 'https://www.courant.com/best-of/',
    image: { awardsHartford },
    title: 'Best of Hartford 2023',
    location: 'Hartford, CT',
  },
  {
    link: 'https://www.metrotimes.com/best-of/2022',
    image: { awardsDetroit },
    title: 'Detroit Metro Times 2022',
    location: 'Detroit, MI',
  },
  {
    link: 'https://www.expertise.com/co/denver/laser-hair-removal',
    image: { awardsDenver },
    title: 'Denver Expertise',
    location: 'Englewood, CO',
  },
  {
    link: 'https://votedminnesotasbest.com/',
    image: { awardsMinnesota },
    title: 'Star Tribune Minnesotas Best 2021',
    location: 'All 5 Minnesota Locations',
  },
  {
    link: 'https://www.metrotimes.com/best-of/2022',
    image: { awardsDesmoines },
    title: "Cityview's Best Of Des Moines 2021",
    location: 'Des Moines, IA',
  },
  {
    link: 'https://www.expertise.com/co/denver/laser-hair-removal',
    image: { awardsOmaha },
    title: 'Omaha Magazine Best Of Omaha 2016-2023',
    location: 'Omaha, NE',
  },
  {
    link: 'https://www.metrotimes.com/best-of/2022',
    image: { awardsUtica },
    title: 'Observer-Dispatch Best Of The Best 2019',
    location: 'Utica, NY',
  },
  {
    link: 'http://contests.gazettextra.com/2019-Gazette-Readers-Choice-2/gallery?group=313839',
    image: { awardsJanesville },
    title: 'The Gazette Readers Choice Awards 2019',
    location: 'Janesville, WI',
  },
  {
    link: 'https://www.buffalospree.com/features/best-of-wny-2020/article_217350e4-0283-11eb-a93b-9ba6a342b335.html',
    image: { awardsBuffalo },
    title: 'Buffalo Spree Best Of WNY 2020',
    location: 'Buffalo, NY',
  },
  {
    link: 'https://www.wausaudailyherald.com/story/life/2019/03/15/best-marathon-county-vote-your-favorite-places-and-entertainment/3143930002/',
    image: { awardsWausau },
    title: 'Best Of Marathon 2019',
    location: 'Wausau, WI',
  },
  {
    link: 'https://www.lansingcitypulse.com/stories/tott-winners-list,29501',
    image: { awardsLansing },
    title: 'Best Of Lansing 2022',
    location: 'Lansing, MI',
  },
];
