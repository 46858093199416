import React from 'react'

export function MilanLaserClients() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <div className="container">
        <div className="row justify-content-center milan-text-white text-center pt-1">
          <div className="col-10 col-md-12">
            <h3 className="subpointsm mb-0">
              On average, Milan Laser clients are <span className="milan-text-tur"><strong>95%</strong></span> hair-free in 7-10 treatments.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MilanLaserClients