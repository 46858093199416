import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export function FolliculitisUnlimitedPackage() {
  return (
    <section className="milan-py background ocean-bg">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-lg-center">
          <span className="line-break">Benefits of the Unlimited Package</span> <span className="line-break">for Folliculitis</span>
        </h2>
        <div className="row mt-4">
          <div className="col-lg-6 text-center">
            <StaticImage src="../../images/shared/Unlimited-Package.png" alt="The Unlimited Package with Lifetime Guarantee" className="mw-100" />
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <div className="bg-transparent-5 bg-sm-transparent p-3 p-sm-0 rounded">
              <p>
                One of the most common triggers for folliculitis is shaving. Our exclusive{" "}
                <Link to="/specials/#guarantee" className="milan-text-primary">
                  Unlimited Package™
                </Link>{" "}
                (included, at no additional cost, with every area!) is your never shave again guarantee because it gives you{" "}
                <strong>unlimited treatments for life.</strong> So if a stray or new hair shows up 6 months, or even 10 years down the road… we’ll
                take care of it permanently for you. No questions asked. No touch-up fees, EVER.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
          <div>
            <Link className="milan-btn milan-navy-btn mb-3" to="/specials/">
              Current Specials
            </Link>
          </div>
          <div>
            <Link className="milan-btn milan-navy-btn mb-3" to="/process/">
              Learn How It Works
            </Link>
          </div>
          <div>
            <Link className="milan-btn milan-navy-btn mb-3" to="/areas/">
              <span className="d-sm-none d-lg-inline">See Treatable</span> Body Areas
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolliculitisUnlimitedPackage;
