export const popularSearches = [
  {
    title: 'Monthly Specials',
    url: '/specials/',
    anchor: ''
  },
  {
    title: 'Get a custom quote',
    url: '/specials/estimate/',
    anchor: ''
  },
  {
    title: 'Waxing/Shaving Calculator',
    url: '/areas/',
    anchor: 'shave-wax-calc'
  },
  {
    title: 'Am I a good candidate?',
    url: '/process/quiz/',
    anchor: ''
  },
  {
    title: 'Before/After Photos',
    url: '/process/beforeafter/',
    anchor: ''
  },
]