import { useState, useEffect } from 'react';
import { storage } from "../firebase/firebase"
import { ref, getDownloadURL } from 'firebase/storage'
import { hardTrim } from '../utils'


export function useThumbnails(currentState) {
  // const storagePath = `stores/${hardTrim(currentState.name).toLowerCase()}/`
  const [thumbnails, setThumbnails] = useState({})

  useEffect(() => {
    const getThumbnails = () => {
      let tempThumbnails = {}

      currentState.metros.forEach(metro => {
        const metroPath = `stores/${hardTrim(metro.state).toLowerCase()}/${metro.pathname}/`
        metro.stores.forEach(store => {
          if (!thumbnails[store.pathname]) { // To avoid repetition on Rerender
            const storePath = `${metroPath}${store.pathname}/store/${hardTrim(store.marketingCity)}_Thumbnail.webp`
            getDownloadURL(ref(storage, storePath)).then(url => {
              tempThumbnails[store.pathname] = url
              setThumbnails({
                ...tempThumbnails,
                [store.pathname]: url
              })
            }).catch(err => {
              console.log(`${hardTrim(store.marketingCity)}_Thumbnail img doesn't exist`)
            })
          }
        })
      })
    }
    getThumbnails()
  }, [])
  return thumbnails
}
export default useThumbnails