import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons";

export function TextArea(props) {
  const { label, name, required, ...rest } = props
  return (
    <>
      {label && <label htmlFor={name}>{label} {required && <sup><FontAwesomeIcon icon={faAsterisk} /></sup>} </label>}
      <Field name={name}>
        {
          props => {
            const { field } = props
            return (
              <textarea
                className="form-control" id={name}
                {...field} {...rest} />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export default TextArea