import React from "react";

export function UnderarmHero() {
  return (
    <section className="hero pt-milan-50 pb-0 py-md-milan-100 py-lg-milan-150 milan-bg-white">
      <div className="container">
        <div className="row">
          <div className="offset-md-6 col-md-6 offset-lg-5 col-lg-7 offset-xl-6 col-xl-6 col-xxl-5">
            <h1 className="milan-text-primary">Underarm Laser Hair Removal</h1>
            <p>Laser hair removal is the best solution to remove inconvenient underarm (a.k.a. armpit) hair.</p>
            <div>
              <ul className="list-style-disc">
                <li>
                  Treatments are fast &#8212; underarms take <strong>under 10 minutes!</strong>
                </li>
                <li>
                  Results are permanent so you’ll <strong>never shave again</strong>.
                </li>
                <li>
                  Beauty does not have to be painful. Especially since <strong>lasers are much less painful than waxing</strong>.
                </li>
              </ul>
            </div>
            <p className="mb-0">Soft, smooth underarms are here to stay.</p>
          </div>
        </div>
      </div>
      {/* bg when stacked*/}
      <div className="d-md-none stack-hero-bg mt-4" />
    </section>
  );
}
export default UnderarmHero