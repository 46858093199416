import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebookF,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { FACEBOOK_URL_START } from '../../utils';

export function FooterSocialIcons() {
  return (
    <div className='footer-social-icons d-flex align-items-center'>
      <a
        href='https://www.instagram.com/milanlaser/'
        className='me-4'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faInstagram} className='me-1 fs-3' />
      </a>
      <a
        href='https://www.tiktok.com/@milanlaserhairremoval'
        className='me-4'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faTiktok} className='me-1 fs-4' />
      </a>
      <a
        href={FACEBOOK_URL_START}
        className='me-4'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faFacebookF} className='me-1 fs-4' />
      </a>
    </div>
  );
}

export default FooterSocialIcons;
