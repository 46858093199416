import React from 'react'
import { Link } from 'gatsby'

export function NeckFaceEar() {
  return (
    <section id="men-neck-face" className="milan-bg-tur">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 milan-bg-tur milan-text-white py-milan-50 py-lg-milan-100">
          <div className="row">
            <div className="col-auto">
              <h2 className="subheadlg">Neck, Face, Unibrow, Ear Laser Hair Removal</h2>
            </div>
            <div className="col-12 z-index-100 order-2 order-md-1">
              <p>Save time on getting your day started by never shaving again because a clean, fresh face and neck is finally possible. Ingrown hairs and razor-cuts will be a distant memory after laser hair removal at Milan. Our lasers and treatments are strong enough to remove coarse hair, but gentle enough to use on the face without leaving scars or burns. </p>
              <p className="mb-0">Whether you’re a <Link to="/military/">military member</Link>, <Link to="/bodybuilding/">bodybuilder</Link>, or someone who is just tired of unwanted facial hair, we can take care of it forever at Milan.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-5 vrt-divider vrt-divider-reversed vrt-divider-turq" />
      </div>
    </div>
    <div className="spacer d-lg-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
  </section>
  )
}

export default NeckFaceEar


// <section id="men-neck-face" className="full-section background light-blue-bg">
//   <div className="container">
//     <div className="row mt-lg-4 pt-3">
//       <div className="col-md-5 col-lg-6"></div>
//       <div className="col-md-7 col-lg-6 anchor-blue">
//       <h2 className="subheadlg">Neck, Face, Unibrow, Ear Laser Hair Removal</h2>
//         <p>Save time on getting your day started by never shaving again because a clean, fresh face and neck is finally possible. Ingrown hairs and razor-cuts will be a distant memory after laser hair removal at Milan. Our lasers and treatments are strong enough to remove coarse hair, but gentle enough to use on the face without leaving scars or burns. </p>
//         <p className="mb-0">Whether you’re a <Link to="/military/" className="milan-text-primary">military member</Link>, <Link to="/bodybuilding/" className="milan-text-primary">bodybuilder</Link>, or someone who is just tired of unwanted facial hair, we can take care of it forever at Milan.</p>
//       </div>
//     </div>
//   </div>
// </section>