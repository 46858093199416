import React from 'react';
import { FIREBASE_STORAGE_PATH, CORPORATE_SITE } from '../../utils';

export function SEO({ origin, location, title, description, children }) {
    const MS_ICON = `${FIREBASE_STORAGE_PATH}general%2Fseo%2Fms-icon-128x128.webp?alt=media&token=afede360-ac33-424d-9f9d-629a0d41d8ca`;
    const MILAN_OG_IMG = `${FIREBASE_STORAGE_PATH}general%2Fseo%2Fmilan-og-img.webp?alt=media&token=06d51ff2-c1bc-4d11-980a-d267a9f33a4a`;

    // cloudamp campaign tracker
    // const cloudamp = {
    //   protocol: (typeof document !== `undefined`) ? document.location.protocol : '',
    //   httpsDomain: '1d5ef9e9369608f625a8-878b10192d4a956595449977ade9187d.ssl.cf2.rackcdn.com',
    //   httpDomain: 'trk.cloudamp.net',
    //   filename: 'ctk.js',
    // }
    // cloudamp.srcDomain = cloudamp.protocol === 'http:' ? cloudamp.httpDomain : cloudamp.httpsDomain
    return (
        <>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content="Laser hair removal, waxing, hair removal, shaving, razors" />
            <link rel="canonical" href={`${CORPORATE_SITE}${location.pathname}`} />

            <meta name="msapplication-TileColor" content="#F2FAFD" />
            <meta name="msapplication-TileImage" content={MS_ICON} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={`${CORPORATE_SITE}${location.pathname}`} />
            <meta property="og:image" content={MILAN_OG_IMG} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:site" content="@MilanLaser" />
            <meta name="twitter:creator" content="@MilanLaser" />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={MILAN_OG_IMG} />
            <meta name="twitter:card" content="summary_large_image" />

            {/* <script type="text/javascript" async="" src={cloudamp.protocol + '//' + cloudamp.srcDomain + '/' + cloudamp.filename}></script> */}
            {children}
        </>
    );
}
export default SEO;
