import React from 'react';
import { Link } from 'gatsby';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FormikControl, SubmitBtn, FormFailed } from '..';
import { LocationsDropdownWrapper } from '..';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, client_sale_db } from '../../../firebase/client_sale';
import { hardTrim } from '../../../utils';

import BadgeSale from '../../../images/enter/badge-enter-sale.webp';
import BadgeExtended from '../../../images/enter/badge-enter-extended.webp';
import BadgeEnd from '../../../images/enter/badge-enter-end.webp';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Required').matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
  last_name: Yup.string().required('Required').matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().min(15, 'Invalid phone number').required('Required'),
  location: Yup.string().required('Required'),
});

const Success = () => (
  <div className='text-center pt-4'>
    <h2 className='milan-text-white subheadlg'>You're Entered!</h2>
    <p className='milan-text-white subheadsm'>
      You have successfully entered to win one of ten unlimited laser hair
      removal packages through our {new Date().getFullYear()} Customer
      Appreciation Drawing. Don't stop there,{' '}
      <Link to='/share'>share your discount</Link> with your friends and family!
    </p>
  </div>
);

const Duplicated = () => (
  <div className='text-center pt-4'>
    <h2 className='milan-text-white subheadlg'>
      Congratulations, you've already entered to win!
    </h2>
  </div>
);

const Submitting = () => (
  <center className='mt-5'>
    <p className='h3 mb-4 milan-text-white'>Submitting...</p>
    <div className='spinner-border' role='status'>
      <span className='visually-hidden milan-text-white'>Loading...</span>
    </div>
  </center>
);

const Expired = () => (
  <div className='w-100 p-4'>
    <p className='subheadsm'>
      <strong>Customer Appreciation 2024 is over</strong>, but we'll see you in
      2025! Thank you to everyone who entered the drawing, and remember to check
      your email to see if you won a free laser hair removal package.
    </p>
    <p className='mb-0'>
      <Link to='/specials'>Click here</Link> to see our current laser hair
      removal specials and save big!
    </p>
  </div>
);

const onSubmit = async (values, helpers) => {
  helpers.setSubmitting = true;
  const { email, phone, first_name, last_name, location } = values;
  const FIREBASE_COLLECTION = 'subscribers';
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email.trim(),
      hardTrim(phone)
    );
    if (user) {
      const subscriber = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email,
        phone: phone,
        location: location,
        createdAt: Timestamp.now(),
      };

      try {
        await setDoc(
          doc(client_sale_db, FIREBASE_COLLECTION, user.uid),
          subscriber
        );
        helpers.setStatus('Success');
      } catch (err) {
        console.error('DB error: adding new subscriber failed', err);
        helpers.setStatus('Failed');
      }
    }
  } catch (error) {
    helpers.setStatus('Failed');
    if (error.code === 'auth/email-already-in-use') {
      console.log('Email already in use');
      helpers.setStatus('Email already in use');
    }
  }
};

export function ClientSaleForm({ siteData, variant }) {
  return (
    <div className='w-100 h-100 z-index-100 position-relative'>
      <div className='container enter-form'>
        <div className='row'>
          <div className='col-md-8 col-xl-7 col-xxl-6'>
            <img
              src={
                variant === 'sale'
                  ? BadgeSale
                  : variant === 'saleExtended'
                  ? BadgeExtended
                  : BadgeEnd
              }
              alt='Customer Appreciation event badge'
              style={{ width: '100%' }}
              className='pb-4'
            />

            {variant === 'sale' || variant === 'saleExtended' ? (
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  phone: '',
                  location: '',
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={false}
              >
                {(formik) => {
                  return (
                    <Form className='w-100 card p-3 milan-bg-primary rounded-3'>
                      <input type='hidden' name='cloudamp__data__c' />
                      {formik.status === 'Success' ? (
                        <Success />
                      ) : formik.status === 'Failed' ? (
                        <FormFailed />
                      ) : formik.status === 'Email already in use' ? (
                        <Duplicated />
                      ) : formik.isSubmitting ? (
                        <Submitting />
                      ) : (
                        <div className=''>
                          <div className='row'>
                            <div className='col-12 col-lg-6 mb-2 text-start'>
                              <FormikControl
                                control='input'
                                name='first_name'
                                placeholder='First Name'
                                type='text'
                              />
                            </div>
                            <div className='col-12 col-lg-6 mb-2 text-start'>
                              <FormikControl
                                control='input'
                                name='last_name'
                                placeholder='Last Name'
                                type='text'
                              />
                            </div>
                            <div className='col-12 col-lg-6 mb-2 text-start'>
                              <FormikControl
                                control='emailInput'
                                placeholder='Email'
                                name='email'
                              />
                            </div>
                            <div className='col-12 col-lg-6 mb-2 text-start'>
                              <FormikControl
                                control='phoneInput'
                                name='phone'
                                type='tel'
                              />
                            </div>
                            <div className='col-12 mb-2 text-start milan-text-white'>
                              <LocationsDropdownWrapper
                                {...{ siteData, formik }}
                                fieldName='location'
                              />
                            </div>
                          </div>
                          <SubmitBtn formik={formik} />
                          {variant === 'sale' ? (
                            <p className='mb-0 mt-3 milan-fp mx-auto milan-text-white'>
                              † Prize package awarded as a refund of any body
                              area or areas purchased between October 1-20,
                              2024. No purchase necessary to enter. If no
                              purchase was made between those dates, the winner
                              will receive a $500 gift card instead. See the
                              official contest rules.
                            </p>
                          ) : variant === 'saleExtended' ? (
                            <p className='mb-0 mt-3 milan-fp mx-auto milan-text-white'>
                              † Prize package awarded as a refund of any body
                              area or areas purchased between October 1-31,
                              2024. No purchase necessary to enter. If no
                              purchase was made between those dates, the winner
                              will receive a $500 gift card instead. See the
                              official contest rules.
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Expired />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientSaleForm;
