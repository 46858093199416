import React from 'react'

export function StateHero({ state, countToDisplay }) {
  return (
    <section className="w-100 hero light-gray-bg pt-0">
      <div className="container-fluid background"></div>
      <div id="state-description" className="container-lg px-0">
        <div className="row g-0 justify-content-center">
          <div className="col-lg-10 col-xxl-9">
            <div className="card rounded-0 border-0">
              <div className="card-body">
                <h1 className="card-title text-center pt-4 milan-text-primary pb-0">Our {state} Locations</h1>
                <p className="card-text p-4 lh-lg milan-text-navy">We are the largest laser hair removal company in the nation that offers unlimited treatments with every purchase. Our no interest payment plans and over <strong className="milan-text-primary">{countToDisplay}</strong> convenient locations make Milan the simplest solution to getting rid of unwanted hair today.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StateHero