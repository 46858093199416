import React from "react"
import { PLANS_START_AT } from '../../../utils'

export function NoInterestDivider({ globalData }) {
  return (
    <section className="w-100 py-3 divider-section">
      <div className="container">
        <div className="row flex-column align-items-center milan-text-white text-center">
          <h3 className="subpointsm mb-0">
            <div className="mb-2">Plans starting at</div>
            <div className="mb-2 fignum-lg"><span className="milan-text-tur"><strong>${globalData.plans_start_at ? globalData.plans_start_at : PLANS_START_AT}</strong></span> per month</div>
          </h3>
        </div>
      </div>
    </section>
  );
}
export default NoInterestDivider