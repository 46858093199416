import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: `${process.env.GATSBY_FB_COUPON_API_KEY}`,
  authDomain: "milan-coupon.firebaseapp.com",
  projectId: "milan-coupon",
  storageBucket: "milan-coupon.appspot.com",
  messagingSenderId: "12350733079",
  appId: "1:12350733079:web:56ccbc729c131e93cc9552"
};

const app = initializeApp(firebaseConfig, 'Coupons')
export const coupon_db = getFirestore(app)