import React from "react";

export function PostTreatmentSteps() {
  return (
    <section className="postTreatmentSteps milan-py pb-0 pb-md-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-xl-1 col-xl-5">
            <h2 className="milan-text-primary">Laser only</h2>
            <p>
              Do not use any other treatments (waxing, threading, hair removal creams, electrolysis or tweezing) in the treatment area for 4 to 6 weeks after the laser treatment is performed.
            </p>
            <h2 className="milan-text-primary">Shaving</h2>
            <p>You may shave the treatment area between treatments as many times as you see fit.</p>
            <h2 className="milan-text-primary">Shedding</h2>
            <p>
              Anywhere from 5 to 14 days after the treatment, shedding of the hair may occur and can appear as new hair growth. Don’t worry, this is
              NOT new hair! You can remove this hair by washing or wiping the area with a wet cloth or loofah sponge.
            </p>
          </div>
        </div>
      </div>
      <div className="spacer d-md-none"></div>
    </section>
  );
}

export default PostTreatmentSteps;
