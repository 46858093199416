import React, { useState, useEffect } from 'react';
import { YELP_URL_START, FACEBOOK_URL_START } from '../../../utils';
import { ReviewsBox, ReviewUsBtn } from '.';
const FACEBOOK_BTN =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flocations%2Freviews-facebook.png?alt=media&token=f51a01e7-77bc-41ff-9788-7bad6515aef8';
const GOOGLE_BTN =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flocations%2Freviews-google.png?alt=media&token=18943312-f556-4472-ac52-9975a192408d';
const YELP_BTN =
  'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flocations%2Freviews-yelp.png?alt=media&token=d36a8362-cb64-43aa-8ce6-63c0fb7b5b0b';

export function ReviewsHero({ siteData }) {
  const OMAHA_METRO = siteData.milanStores
    .find((state) => state.id === 'Nebraska')
    .metros.find((metro) => metro.name === 'Omaha');
  const OMAHA_WEST_STORE = OMAHA_METRO.stores.find(
    (store) => store.marketingCity === 'Omaha West'
  );
  const [currentMetro, setCurrentMetro] = useState(OMAHA_METRO);
  const [storesWithReviews, setStoresWithReviews] = useState([
    OMAHA_WEST_STORE,
  ]);

  useEffect(() => {
    if (siteData.selectedStore.store) {
      // if the selected store is NOT Omaha West
      if (siteData.selectedStore.store.salesforceValue !== 'Omaha West') {
        // Find the new metro
        const metro = siteData.milanStores
          .find((state) => state.id === siteData.selectedStore.state.name)
          .metros.find(
            (metro) => metro.pathname === siteData.selectedStore.metro.pathname
          );
        if (metro) setCurrentMetro(metro);
        // Find the selected Store
        const foundStore = metro
          ? metro.stores.find(
              (store) =>
                store.salesforceValue ===
                siteData.selectedStore.store.salesforceValue
            )
          : null;
        // if the selected store has reviews
        if (foundStore && foundStore.platforms.reviews.list_token !== '') {
          // if Omaha West is the only element in storesWithReviews
          if (storesWithReviews.length === 1) {
            // Add the selected store as a first element to storesWithReviews
            setStoresWithReviews([foundStore, ...storesWithReviews]);
          } else {
            // if Omaha West is NOT the only element in storesWithReviews
            // Replace the first element with the new selected store
            let storesWithReviewsClone = [...storesWithReviews];
            storesWithReviewsClone.shift();
            storesWithReviewsClone.unshift(foundStore);
            setStoresWithReviews(storesWithReviewsClone);
          }
        } else {
          // if the selected store does NOT have reviews
          setStoresWithReviews([OMAHA_WEST_STORE]);
        }
      } else {
        // if the selected store is Omaha West
        setStoresWithReviews([OMAHA_WEST_STORE]);
      }
    } else {
      // if no store is selected
      setStoresWithReviews([OMAHA_WEST_STORE]);
    }
  }, [siteData.selectedStore]);
  return (
    <section
      id='reviews-section'
      className='full-section background hero light-blue-bg'
    >
      <div className='container'>
        <div className='row justify-content-center text-center px-3'>
          <div className='col-md-10'>
            <h1 className='milan-text-primary'>Laser Hair Removal Reviews</h1>
            <p className='mt-3'>
              From permanent results to clean clinics and friendly staff, our
              clients love their experiences at Milan Laser. Read laser hair
              removal reviews from real clients and see for yourself!
            </p>
          </div>
        </div>

        <div className='row mb-4 justify-content-around'>
          <ReviewUsBtn
            currentMetro={currentMetro}
            link=''
            platform='google'
            btn={GOOGLE_BTN}
            imgAltText='Red rectangle with a G + 5 stars pictured in white lettering, highlighting why real customers love Milan laser hair removal enough to make it the largest laser hair removal company in the US'
          />
          <ReviewUsBtn
            currentMetro={currentMetro}
            link={YELP_URL_START}
            platform='yelp'
            btn={YELP_BTN}
            imgAltText=''
          />
          <ReviewUsBtn
            currentMetro={currentMetro}
            link={FACEBOOK_URL_START}
            platform='facebook'
            btn={FACEBOOK_BTN}
            imgAltText=''
          />
        </div>
      </div>
      {storesWithReviews.length > 0 && (
        <ReviewsBox stores={storesWithReviews} />
      )}
    </section>
  );
}

export default ReviewsHero;
