import React from 'react';
import { MilanLoader } from '../milan-loader/milan-loader';
import { BREAKPOINTS } from '../../utils';
import useBreakpoint from 'use-breakpoint';
import './iframe-loader.css';

// I am not liking the DRI here. I would like to see the MilanLoader and TalkableSkeleton refactored when we move to next.JS.

function TalkableSkeleton({ isDesktop }) {
  return (
    <div className='placeholder-glow'>
      <div className='px-3 pt-5 pb-3'>
        <span
          className='placeholder placeholder-lg col-12 pb-5 milan-gray'
          style={{ borderRadius: '5px' }}
        ></span>
      </div>
      <div className='p-3'>
        <span
          className='placeholder placeholder-lg col-12 pb-5 milan-gray'
          style={{ borderRadius: '5px' }}
        ></span>
      </div>
      <div className='p-3'>
        <span
          className='placeholder placeholder-lg col-12 pb-5 milan-gray'
          style={{ borderRadius: '5px' }}
        ></span>
      </div>
      <div className='pt-5'>
        <div className='px-3'>
          <span
            className='placeholder placeholder-sm col-12 milan-gray'
            style={{ borderRadius: '5px' }}
          ></span>
        </div>
        <div className='px-3'>
          <span
            className='placeholder placeholder-sm col-12 milan-gray'
            style={{ borderRadius: '5px' }}
          ></span>
        </div>
        <div className='px-3'>
          <span
            className='placeholder placeholder-sm col-12 milan-gray'
            style={{ borderRadius: '5px' }}
          ></span>
        </div>
        <div className='px-3'>
          <span
            className='placeholder placeholder-sm col-12 milan-gray'
            style={{ borderRadius: '5px' }}
          ></span>
        </div>
        <div className='px-3'>
          <span
            className='placeholder placeholder-sm col-12 milan-gray'
            style={{ borderRadius: '5px' }}
          ></span>
        </div>
      </div>
      {/* Inputs */}
      <div className='d-flex flex-column gap-2 justify-content-center pt-5'>
        <div className='px-3'>
          <span
            className='placeholder placeholder-lg col-12 milan-gray'
            style={{ borderRadius: '5px', height: '80px' }}
          ></span>
        </div>
        <div className='px-3'>
          <span
            className='placeholder placeholder-lg col-12  milan-gray'
            style={{ borderRadius: '5px', height: '80px' }}
          ></span>
        </div>
        {isDesktop && (
          <div className='px-3'>
            <span
              className='placeholder placeholder-lg col-12 milan-gray'
              style={{ borderRadius: '5px', height: '80px' }}
            ></span>
          </div>
        )}
      </div>
      <div className='px-3 pt-5'>
        <span
          className='placeholder placeholder-sm col-12 milan-gray'
          style={{ borderRadius: '5px' }}
        ></span>
      </div>
      <div className='px-3'>
        {isDesktop && (
          <span
            className='placeholder placeholder-sm col-12 milan-gray'
            style={{ borderRadius: '5px' }}
          ></span>
        )}
      </div>
    </div>
  );
}

export function IframeLoader() {
  const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs');
  const isDesktop = minWidth >= BREAKPOINTS.lg;

  const MLogoContainerBgColor = '#E8EBED';
  const getLogoContainerHeight = () => {
    if (minWidth >= BREAKPOINTS.md) {
      return '312px';
    } else {
      return '240px';
    }
  };

  return (
    <>
      {/* Visible for mobile and tablet only */}
      <div className='d-lg-none container-mobile'>
        {/* M-Logo Container */}
        <div
          className='col-12 d-flex justify-content-center align-items-center'
          style={{
            height: getLogoContainerHeight(),
            maxHeight: '312px',
            backgroundColor: MLogoContainerBgColor,
          }}
        >
          <MilanLoader />
        </div>
        <div className='container pt-4'>
          <TalkableSkeleton />
        </div>
      </div>
      {/* Visible for desktop only */}
      <div className='container-desktop d-lg-flex d-none justify-content-between'>
        {/* left side placeholder */}
        <div className='col-5 container px-0'>
          <TalkableSkeleton isDesktop={isDesktop} />
        </div>
        {/* right side placeholder */}
        <div
          className='col-7 d-flex justify-content-center align-items-center container-desktop'
          style={{
            backgroundColor: MLogoContainerBgColor, //using a different bg color for the logo container
          }}
        >
          <MilanLoader />
        </div>
      </div>
    </>
  );
}
