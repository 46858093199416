import React from 'react'

export function TannedSkin() {
  return (
    <section id="tanned-skin" className="background">
      <div className="container milan-py">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-6">
            <h2 className="text-center subheadsm">Your safety is our top priority! Please remember that it is unsafe for us to treat tanned skin.</h2>
          </div>
        </div>
        <div className="row my-5">
          <div className="offset-sm-3 col-sm-9 offset-md-4 col-md-8 col-lg-6 offset-lg-5">
            <ul className="list-style-disc">
              <li className="mb-3">Your skin must be is at its natural or baseline skin tone to resume treatments. Treating tanned skin can cause adverse skin reactions including burns and discoloration.</li>
              <li className="mb-3">If your summer involves outdoor activities, consider wearing protective clothing and apply sunscreen regularly.</li>
              <li className="mb-3">Questions or concerns? Don't hesitate to reach out to one of our medical providers. Milan is committed to safety and incredible results.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="p-5"></div>
    </section>
  )
}

export default TannedSkin