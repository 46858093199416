import { useState, useEffect } from 'react'
import axios from 'axios'
import { CORPORATE_SITE, findMilanInDB, NETLIFY_FUNCTIONS_PATH } from '../utils'
import nearbySort from 'nearby-sort'
import { getDistance, convertDistance } from 'geolib'

const sortMilanGeoCodes = async (coordinates, milanGeoCodes) => await nearbySort(coordinates, milanGeoCodes)

export const useNearbySearch = (lat, long, siteData, setIsGeoEnabled, isGeoEnabled, zipCode) => {
  const [nearbyMilanStores, setNearbyMilanStores] = useState([])
  const [loadingNearbyStores, setLoadingNearbyStores] = useState(true)
  const [feedback, setFeedback] = useState('')

  const findMilan = (lat, long) => {
    setFeedback('')
    sortMilanGeoCodes({ lat, long }, siteData.milanGeoCodes).then(res => {
      let nearbyStores = res.slice(0, 8).map(item => findMilanInDB(siteData.milanStores, item))
      nearbyStores.forEach(nearbyStore => {
        let distance = getDistance(
          { latitude: lat, longitude: long },
          { latitude: nearbyStore.geo.lat, longitude: nearbyStore.geo.long }
        )
        distance = +convertDistance(distance, 'mi').toFixed(1)
        nearbyStore.distance = distance < 1 ? (distance + (0.15 * distance)).toFixed(1) : distance < 10 ? (distance + (0.25 * distance)).toFixed(1) : distance < 20 ? (distance + (0.30 * distance)).toFixed(1) : (distance + (0.22 * distance)).toFixed(1)
      })
      setNearbyMilanStores([...nearbyStores])
      siteData.updateSelectedStore(nearbyStores[0].salesforceValue)
      setLoadingNearbyStores(false)
      setIsGeoEnabled(false) // This is helpfull when (Find Milan Near You) button clicked again
    }).catch(err => {
      console.log('Error sorting milanGeoCodes:', err)
      setFeedback('Error sorting milanGeoCodes')
    })
  }

  const handleZipCode = zipCode => {
    setLoadingNearbyStores(true)
    setFeedback('')
    axios.get(`${NETLIFY_FUNCTIONS_PATH}getGeoCode?zipcode=${zipCode.value}`)
      .then(res => {
        console.log(res)
        const { lat, lng } = res.data
        findMilan(lat, lng)
      }).catch(err => {
        console.error('Fetching lat, lng from server failed', err)
        setFeedback('Zipcode Failed')
      })
  }

  useEffect(() => {
    // If geolocation is permitted
    if (lat && long && isGeoEnabled && siteData.milanStores.length > 0) {
      findMilan(lat, long)
    }
    // if zipCode changed
    if (zipCode && zipCode.value.length === 5 && zipCode.valid) {
      handleZipCode(zipCode)
    }
  }, [lat, long, isGeoEnabled, zipCode])

  return { nearbyMilanStores, loadingNearbyStores, setLoadingNearbyStores, feedback }
}