import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

export function LHRIdealForLegs() {
  return (
    <section id="lhr-ideal-for-legs" className="pt-milan-50 py-md-milan-50 py-lg-milan-100">
      <div className="container">
        <div className="row">
          <div className="offset-md-5 col-md-7 offset-lg-6 col-lg-6">
              <h2 className="subheadlg milan-text-primary">Why Laser Hair Removal <br className="d-sm-none" />  is Ideal for&nbsp;Legs</h2>
              <p>Leg laser hair removal costs less than a lifetime of waxing and shaving and gives you silky, smooth legs. Permanently.</p>
              <ul className="list-style-disc ul-list">
                <li><strong>Shorts or skirt-ready. Always.</strong> No more last-minute shaving or wearing pants because you ran out of time to shave. You’re always ready to show off smooth legs!</li>
                <li><strong>Comfortable treatments.</strong> Say goodbye to the pain of tearing off hot wax strips and razor nicks. Our lasers are gentle enough to target the hair follicle without damaging or breaking the surface of the skin.</li>
                <li><strong>Never shave again!</strong> With Milan’s exclusive <Link to="/specials/#guarantee" className="milan-text-primary">Unlimited Package™</Link>, your results are guaranteed for life. Hormone changes down the road causing hair growth? No problem! We’ll take care of it for you with no touch-up fees.</li>
              </ul>
              <p className="d-none d-sm-block">Laser hair removal is the best way to remove leg hair and get permanently sleek, soft legs.</p>
              <Link to="#consult" className="milan-text-primary">Schedule your <span className='text-decoration-underline'><strong>free consultation</strong></span> to learn more about laser hair removal today!</Link>
          </div>
        </div>
      </div>
      {/* bg when stacked */}
      <div className="d-md-none stack-hero-bg" />
    </section>
  )
}

export default LHRIdealForLegs