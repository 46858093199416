import React from 'react'
import { Link } from 'gatsby'

export function LHRForCysts() {
  return (
    <section id="lhr-for-cysts" className="milan-bg-off-white">
      <div className="spacer d-md-none"></div>
      <div className="container milan-py">
        <h2 className="subheadlg milan-text-primary">Preventing Pilonidal Cysts <br /> With Laser Hair Removal</h2>
        <div className="row">
          <div className="col-md-8 col-lg-7 col-xxl-6">
            <p>Hair, particularly excessive hair, in the gluteal cleft near the tailbone plays a critical role in forming the painful boils and abscesses known as pilonidal cysts or Pilonidal Disease. The exact cause of pilonidal disease isn’t known, but most medical professionals agree that loose hair penetrating the skin is what appears to cause the cysts to develop. <span className="d-none d-md-inline">This loose hair is often forced down into the skin from the friction and pressure exerted by things like bicycling, wearing tight clothing, sitting for long periods, skin rubbing against skin, or other similar factors.</span></p>
            <p>Pilonidal cysts that don’t heal on their own usually require surgery<span className="d-none d-md-inline"> to drain and excise the infected area</span>. However, even with surgical intervention, if the hair in the area is not removed the chances of more cysts developing in the future are relatively high. <span className="d-none d-sm-inline">That’s why many medical professionals recommend laser hair removal treatments as a way to <strong>safely and effectively decrease your risk for a pilonidal cyst recurrence.</strong></span></p>
            <p>For a lot of men and women who suffer from these painful cysts, shaving isn’t enough to manage the unwanted hair that forms these boils. The permanent results that you get with laser hair removal make it <strong>your best option for preventing pilonidal cysts</strong><span className="d-none d-sm-inline"> because the hair is gone for good!</span></p>
            <p className="mb-0">Take the first step in preventing pilonidal cysts by scheduling <Link className="milan-text-primary" to="#consult">your free consultation</Link> today!</p>
          </div>
        </div>
      </div>
      <span id="hs"></span>
    </section>
  )
}

export default LHRForCysts