import React from 'react'
import { Link } from 'gatsby'
import FullChart from '../../images/pcos/Full-Chart.webp'
import ChartOne from '../../images/pcos/Chart-1-Mobile.webp'
import ChartTwo from '../../images/pcos/Chart-2-Mobile.webp'

export function PcosMethods() {
  return (
    <section id="pcos-methods" className="milan-py">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-lg-center">Laser Hair Removal vs. Other Methods for PCOS</h2>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="d-none d-md-inline"><img src={FullChart} className="mw-100" alt="" /></div>
            <div className="d-md-none">
              <img src={ChartOne} className="mw-100 d-block mx-auto" alt="" />
              <img src={ChartTwo} className="mw-100 d-block mx-auto" alt="" />
            </div>
            <div className="mt-md-4">
              <p>The hormone fluctuations associated with polycystic ovarian syndrome cause hair follicles to become “active”. This side effect of PCOS is what causes unwanted hair to grow.</p>
              <p>Your best option to permanently remove this hair growth is laser hair removal. That’s because our laser eliminates the root of the hair by targeting the melanin in the follicle so that it can never grow back. <Link className="milan-text-primary" to="/process/">See a demo.</Link> Managing this unwanted hair by shaving, waxing, or bleaching only yields temporary results, and electrolysis is extremely painful &amp; time consuming.</p>
              <p><Link className="milan-text-primary" to="/process/compare/">Learn more about why laser hair removal is your best solution for managing PCOS hair growth.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PcosMethods