import React from 'react';

export function LessThanTenDivider() {
  return (
    <section
      id='lip-underarm-divider'
      className='divider-section w-100 py-3 py-lg-4 milan-bg-primary'
    >
      <div className='container'>
        <div className='row justify-content-center milan-text-white text-center pt-1'>
          <div className='col-lg-10 col-xl-12'>
            <h3 className='subpointsm mb-0 d-none d-md-block'>
              Lip or underarms take{' '}
              <strong className='milan-text-navy'>less than 10 minutes</strong>{' '}
              per treatment.
            </h3>
            <h3 className='subpointsm mb-0 d-md-none'>
              Lip or underarms{' '}
              <span className='subpointlg d-block'>
                <strong className='milan-text-navy'>
                  take less than 10 minutes
                </strong>
              </span>{' '}
              per treatment.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LessThanTenDivider;
