import React from "react"
import { Link } from "gatsby"
const FrontNeck =
  "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Ffolliculitis%2FFrontNeck_BA.webp?alt=media&token=81d445c7-8c1a-4c80-815e-677647639639"

export function IdealFolliculitis() {
  return (
    <section id="ideal-for-folliculitis" className="milan-bg-off-white">
      <div className="container milan-py">
        <h2 className="subheadlg milan-text-primary">
          Why Laser Hair Removal is Ideal for Folliculitis
        </h2>
        <div className="row">
          <div className="col-md-8">
            <p>
              Folliculitis—more commonly known as Barber’s itch, razor bumps,
              hot tub rash, or shaving rash—occurs when the root of your
              unwanted hair becomes infected. While anyone can develop
              folliculitis, there are certain risk factors that increase your
              risk for developing the embarrassing and often painful red bumps,
              boils &amp; blisters.
            </p>
            <p>
              {" "}
              <strong>You’ve Tried Everything Else.</strong> There’s nothing you
              haven’t tried to prevent those&nbsp; angry red bumps from showing
              up—yet no matter what you do they keep coming back. You’ve avoided
              shaving and waxing, but then you’re just hairy and those ingrown
              hairs still show up. All of the expensive antibacterial soaps and
              special creams you’ve bought haven’t kept your folliculitis at bay
              either. Heck, you’ve even stopped wearing even remotely tight
              clothing in the hopes that would do the trick—all to no avail.
              That’s because these methods don’t remove the underlying cause of
              the problem; the hair follicle itself.
            </p>
            <p>
              <strong>Comfortable Treatments.</strong> Unlike painful acne
              extractions at dermatologist electrolysis, you’ll comfortably and
              quickly remove unwanted hair without irritation your skin. Laser
              treatments, on average, take less than 30 minutes, while
              electrolysis typically takes several hours to complete. Another
              great thing is that there is no downtime after a laser treatment
              whereas an extraction—can take 5-7 days to heal after you visit
              your dermatologist.{" "}
            </p>
            <p>
              {" "}
              <strong>Go With Confidence.</strong> The underlying cause of
              folliculitis is gone and so is your unwanted hair, so there’s no
              need to worry about red bumps, or pimples ruining your smooth
              skin.
            </p>
            <p className="mb-0">
              <Link to="#consult" className="milan-text-primary">
                Schedule your free consultation to learn more about laser hair
                removal today!
              </Link>
            </p>
          </div>
          <div className="col-md-4 text-center mt-5">
            <img
              src={FrontNeck}
              alt="Above/below stacked image depicting a man looking upward after getting laser hair removal on his front neck to showcase the before/after neck laser hair removal results"
              className="ba-top-bottom-img"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default IdealFolliculitis
