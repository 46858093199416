import React from 'react'
import SpecialsBanner from './SpecialsBanner'
import Navbar from './Navbar'
import { NO_RAZOR, MILAN_PHONE } from '../../utils'
import SearchIcon from '../../images/shared/SearchIcon.png'
import PhoneIcon from '../../images/shared/PhoneIcon.png'

function HeaderBottom({ menu, searchView, setSearchView }) {
  return (
    <section id="header-bottom">
      <div className="container h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-xl-11 col-xxl-10 h-100">
            <div className="row align-items-center h-100 position-relative gx-0">
              <div className="col d-lg-none text-center">
                <SpecialsBanner />
              </div>
              <div className="col-auto d-none d-lg-block h-100" style={{ zIndex: 99 }}>
                <Navbar menu={menu} />
              </div>
              <div className="col d-none d-lg-block h-100 milan-bg-white" style={{ zIndex: 100 }}>
                <div className="row justify-content-end align-items-center h-100 gx-4">
                  {
                    !searchView &&
                    <div className="col-auto">
                      <div id="header-search" className="py-2 pointer" onClick={() => setSearchView(true)}>
                        <i><img src={SearchIcon} alt="Search Icon" style={{ maxHeight: '20px' }} /></i><span className="ps-2">Search</span>
                      </div>
                    </div>
                  }
                  <div className="col-auto">
                    <div id="header-tel" className="py-2">
                      <a href={`tel:${MILAN_PHONE}`}>
                        <i><img src={PhoneIcon} alt="Phone Icon" style={{ maxHeight: '28px' }} /></i><span className="ps-2">{NO_RAZOR}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeaderBottom