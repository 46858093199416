import React from 'react'
import { Link } from 'gatsby'
const FullChart = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FFull-Chart.webp?alt=media&token=a321d00b-848f-470f-961b-95d5030f2131'
const ChartOne = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FChart-1.webp?alt=media&token=61fb9262-219e-4ff6-9224-840880ec9984'
const ChartTwo = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbodybuilding%2FChart-2.webp?alt=media&token=4ffc5473-3385-483a-abec-6761b644a4d4'

export function HairRemovalMethods() {
  return (
    <section id="hair-removal-methods" className="milan-py">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-lg-center">Laser Hair Removal vs. Other Hair Removal Methods</h2>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="d-none d-md-inline"><img src={FullChart} className="mw-100" alt="" /></div>
            <div className="d-md-none">
              <img src={ChartOne} className="mw-100 d-block mx-auto" alt="" />
              <img src={ChartTwo} className="mw-100 d-block mx-auto" alt="" />
            </div>
            <div className="mt-md-4">
              <p>Smooth, hair-free skin is a necessity for every bodybuilder<span className="d-none d-sm-inline">, whether you’re competing on stage or participate in recreational bodybuilding</span>. Removing unwanted body hair will help you highlight the muscle tone and definition that you’ve worked so hard to build.</p>
              <p>Your best option to permanently remove aesthetic-ruining stubble is laser hair removal. That’s because our laser eliminates the root of the hair by targeting the melanin in the follicle so that it can never grow back. <Link className="milan-text-primary" to="/process/">See a demo.</Link> Managing this unwanted hair by shaving, waxing, or hair removal creams only yields temporary results, <span className="d-none d-sm-inline">can leave you with razor burn or even chemical burns,</span> and is time-consuming.</p>
              <p><Link className="milan-text-primary" to="/process/compare/">Learn more about why laser hair removal is the best hair removal method for bodybuilding.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HairRemovalMethods