import React from "react";
import Carousel from "react-bootstrap/Carousel";
import men1 from "../../images/bodymap/men1.webp";
import men2 from "../../images/bodymap/men2.webp";
import men3 from "../../images/bodymap/men3.webp";
import men4 from "../../images/bodymap/men4.webp";
import men5 from "../../images/bodymap/men5.webp";

export function BodymapMenCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={men1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={men2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={men3} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={men4} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={men5} alt="Second slide" />
      </Carousel.Item> 
    </Carousel>
  );
}

export default BodymapMenCarousel;
