import React from "react";
const centersignage = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fbestpractices%2Fcentersignage.webp?alt=media&token=058baa89-355b-4aa0-8f84-869d68fd5bcd";

export function BestPracticesShoppingCenter() {
  return (
    <section className="milan-py">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <img src={centersignage} alt="shopping center signage" className="w-100 img-thumbnail" />
          </div>
          <div className="col-12 col-md-6">
          <h2 className="pt-3 milan-text-primary subheadlg">Shopping Center Signage</h2>
            <p>
              We are looking for a local landmark or shopping center signage to help our customers easily identify where our store is located in town.
              We typically use this in our description similar to our verbiage <a href="https://milanlaserhouston.com/locations/houston/cypress/" target="_blank" rel="noreferrer" className="milan-text-primary">here.</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BestPracticesShoppingCenter;
