import React from 'react'

export function CareersJoin() {
  return (
    <section className="expert-bg d-flex align-items-center pt-5 mb-5 hr-divider hr-divider-consult" id="open-positions">
      <div className="container section-width pt-3">
        <h2 className="text-center pb-3 pb-5-sm subheadlg milan-text-primary">Join Our Team Of Experts</h2>
        <p>
          Ready for a change? A growing company with excellent benefits and a progressive culture that can’t be beat are waiting for you at Milan
          Laser.
        </p>
        <div id="iframeHeightDiv" name="HRM Direct Career Site iFrame Container" align="center">
          <iframe
            id="inlineframe"
            className="positions-list"
            name="HRM Direct Career Site iFrame"
            src="//milanlaser.hrmdirect.com/employment/job-openings.php?search=true&nohd=&dept=-1&office=-1&cust_sort1=-1"
            allowtransparency="true"
            title="Career Site"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default CareersJoin;
