import React from 'react'
import SunScreen from '../../../images/WelcomeToMilan/Pretreatment-SunScreen.webp'

export function SafetyReminder() {
  return (
    <section id="safety-reminder" className="milan-bg-secondary">
      <div className="container py-5">
        <div className="row flex-sm-row-reverse">
          <div className="col-sm-6 col-lg-5">
            <figure className="text-center mb-0">
              <img src={SunScreen} alt="Pretreatment-SunScreen" />
            </figure>
          </div>
          <div className="offset-lg-1 col-sm-6 milan-py">
            <h2 className="subheadlg pb-0">Sun Safety Reminder</h2>
            <p className="mt-4">When summer rolls around, stay protected from sun exposure throughout your journey to smooth!</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SafetyReminder