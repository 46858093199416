import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { PressCustomToggle, PressMilanMediaCard } from ".";
import { pressVideos } from "./util/pressVideos";

const videoShow = pressVideos.slice(0, 3);
const videoHidden = pressVideos.slice(3);

export function PressMilanMedia() {
  return (
    <section id="milan-media" className="milan-bg-tur py-5">
      <div className="container">
        <h2 className="subheadlg text-white">Milan In The Media</h2>
        <Accordion defaultActiveKey="1">
          <div className="row">
            {/* It will always show the first three */}
            {videoShow.map((video, i) => (
              <PressMilanMediaCard video={video} key={i} />
            ))}

            {/* after the first three, it will be collapsable */}
            <Accordion.Collapse eventKey="0">
              <div className="row justify-content-md-center">
                {videoHidden.map((video, i) => (
                  <PressMilanMediaCard video={video} key={i} />
                ))}
              </div>
            </Accordion.Collapse>
          </div>
          <PressCustomToggle />
        </Accordion>
      </div>
    </section>
  );
}

export default PressMilanMedia;
