// Please add the trimmed string of a page

export const minLayout = {
  'bestpracticesphotos': true,
  'bodymap': true,
  'careers': true,
  'covid': true,
  'privacy': true,
  'press': true,
  'WelcomeToMilanSkinCare': true,
  'WelcomeToMilanpretreatment': true,
  'WelcomeToMilanpostreatment': true,
  'coupon': true,
  'sharereferral': true,
  'referreferral': true,
  // 'lpanniversarysale': true,
  'enter': true,
  'clientsale': true,
}