import { useState, useEffect } from 'react'
import { MILAN_SITE_START } from '../utils'

export function useDynamicMenu(siteData, menu) {
  const [dynamicMenu, setDynamicMenu] = useState(menu)

  useEffect(() => {
    if (siteData.selectedStore.store) { // if a store is selected
      let metro = siteData.selectedStore.metro
      let toBeInjected = {
        link: `${metro.name} Locations`,
        pathname: `${MILAN_SITE_START}${metro.pathname}.com/locations/${metro.metroPath}`
      }
      if (menu[3].items[0].pathname === '/locations/') {
        menu[3].items.unshift(toBeInjected) // Inject a new link
      } else {
        menu[3].items[0] = toBeInjected // Replace the current link
      }
      setDynamicMenu([...menu])
    } else if (!siteData.selectedStore.store && menu[3].items[0].pathname !== '/locations/') { // if no store is selected
      menu[3].items.shift()
      setDynamicMenu([...menu])
    }
  }, [siteData.selectedStore])

  return [...dynamicMenu]
}

export default useDynamicMenu