import { useState, useEffect } from 'react';
import { getDocs, query, where, collection, limit, getCountFromServer } from "firebase/firestore"
import { coupon_db } from '../firebase/coupon_fb'
import { hardTrim } from '../utils';

export function useCouponData(siteData, variations, type) {
  const salesforceValues = siteData.milanStores.map(state => state.metros.map(metro => metro.stores.map(store => store.salesforceValue))).flat(2)
  const [coupon, setCoupon] = useState({
    value: '',
    valid: true,
    errorMsg: '',
    submit: false,
    isSubmitting: false,
    fetchedData: null,
    isExpired: false
  })
  // type could be coupon OR offer
  const FIREBASE_COLLECTION = type === 'offer' ? 'milan_offer' : 'coupons_may_24'
  const couponsRef = collection(coupon_db, FIREBASE_COLLECTION);
  const q = query(couponsRef, where('couponCode', '==', coupon.value));

  // const docsCount = async () => {
  //   return await getCountFromServer(couponsRef);
  // }
  // docsCount().then(res => console.log(res.data())).catch(err => console.log(err))

  useEffect(() => {
    if (coupon.value.length === 6 && coupon.submit && coupon.valid && !coupon.fetchedData && !coupon.isSubmitting) {
      setCoupon({ ...coupon, isSubmitting: true })
      getDocs(q).then(response => {
        setCoupon({
          ...coupon,
          isSubmitting: false,
          fetchedData: { ...response.docs[0].data(), id: response.docs[0].id },
          isExpired: Date.now() > (response.docs[0].data().expDate.seconds * 1000)
        })
        const storeName = response.docs[0].data().storeName.toLowerCase()
        let salesforceValue = variations[storeName] ? variations[storeName] : salesforceValues.find(val => hardTrim(val).toLowerCase() === hardTrim(storeName))
        if (salesforceValue) siteData.updateSelectedStore(salesforceValue)
      }).catch(error => {
        console.log('Error fetching Coupons data', error)
        console.log('Default coupon is being used instead.')
        // setCoupon({ ...coupon, isSubmitting: false, valid: false, errorMsg: 'Please check the Coupon code entered' })
        // Fetch any 50% coupon
        const defaultCouponQuery = query(couponsRef, where('discount', '==', type === 'offer' ? 60 : 50), limit(1));
        getDocs(defaultCouponQuery).then(response => {
          setCoupon({
            ...coupon,
            isSubmitting: false,
            fetchedData: { ...response.docs[0].data(), id: response.docs[0].id },
            isExpired: Date.now() > (response.docs[0].data().expDate.seconds * 1000)
          })
        }).catch(err => {
          console.log('Error fetching default coupon', err)
        })
      })
    }
  }, [coupon])

  return [coupon, setCoupon]
}
export default useCouponData

