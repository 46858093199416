import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import './dropdownSection.css'

export function StatesDropdownSection({ siteData }) {
  return (
    <section id="dropdown-section" className="milan-py position-relative">
      <span id="states" className="anchor-tag"></span>
      <div className="container">
        <ol className="px-0 row justify-content-center gy-2 gy-md-4">
          {
            siteData.milanStores.length > 0 && siteData.milanStores.map(state => (
              <li key={state.id} className="col-6 col-md-4 col-xl-3 col-xxl-2">
                <DropdownButton
                  as={ButtonGroup}
                  drop="down"
                  title={state.name}
                  className="btn-group__state"
                >
                  {
                    state.metros.length > 0 && state.metros.map((metro, m) => (
                      <React.Fragment key={m}>
                        {
                          metro.stores.length > 1 ?
                            <Dropdown drop="end">
                              <Dropdown.Toggle>
                                {metro.name}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {
                                  metro.stores.map((store, s) => (
                                    <Dropdown.Item key={s} href={`${metro.origin}/locations/${metro.metroPath}/${store.pathname}`} >{store.marketingCity}</Dropdown.Item>
                                  ))
                                }
                              </Dropdown.Menu>
                            </Dropdown>
                            :
                            <Dropdown.Item href={`${metro.origin}/locations/${metro.stores[0].pathname}`}>{metro.name}</Dropdown.Item>
                        }
                      </React.Fragment>
                    ))
                  }
                  { // In case of a borrowed metro
                    state?.borrowed_metros?.length > 0 && state.borrowed_metros.map((borrowed, b) => {
                      let foundMetro = siteData.milanStores.find(state => state.abbreviation === borrowed.borrow_from).metros.find(metro => metro.pathname === borrowed.pathname)
                      return (
                        <React.Fragment key={b}>
                          {
                            foundMetro.stores.length > 1 ?
                              <Dropdown drop="end">
                                <Dropdown.Toggle>
                                  {foundMetro.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {
                                    foundMetro.stores.filter(store => store.stateAbbrev === state.abbreviation).map((store, s) => (
                                      <Dropdown.Item key={s} href={`${foundMetro.origin}/locations/${foundMetro.metroPath}/${store.pathname}`} >{store.marketingCity}</Dropdown.Item>
                                    ))
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                              :
                              <Dropdown.Item href={`${foundMetro.origin}/locations/${foundMetro.stores[0].pathname}`}>{foundMetro.name}</Dropdown.Item>
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </DropdownButton>
              </li>
            ))
          }
        </ol>
      </div>
    </section>
  )
}

export default StatesDropdownSection