import React from 'react';
import Card from 'react-bootstrap/Card';
import { truncateText } from '../../utils/';

export function PressMilanMediaContCard(media, minWidth) {
  const truncatedTitle = truncateText(media.media.title, minWidth, 80);
  return (
    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
      <Card className='mb-0'>
        <Card.Body className='tile-box d-flex flex-column align-items-between'>
          <Card.Title className='milan-text-navy'>{truncatedTitle}</Card.Title>
          <Card.Subtitle className='text-muted'>
            {media.media.publisher}
          </Card.Subtitle>
          <Card.Link
            href={media.media.source}
            target='_blank'
            className='mt-auto d-block milan-text-navy'
          >
            Read more
          </Card.Link>
        </Card.Body>
      </Card>
    </div>
  );
}
