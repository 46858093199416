import React from "react";

export function BestPracticesHero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="col-6"><h1>Photography Best Practices</h1></div>
          <div className="col-6"></div>
        </div>
        <div className="row bg-small"></div>
      </div>
    </section>
  );
}

export default BestPracticesHero;
