import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'

export function ConsultRadio(props) {
  const { label, name, value, btnColor, ...rest } = props
  return (
    <>
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return (
              <React.Fragment>
                <input
                  type='radio'
                  id={value}
                  className="btn-check"
                  autoComplete="off"
                  {...field}
                  value={value}
                  checked={field.value === value} />
                <label className={`milan-btn milan milan-${btnColor}-btn d-block w-100`} htmlFor={value}>{label}</label>
              </React.Fragment>
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )

}

export default ConsultRadio