import React from "react";
import Carousel from "react-bootstrap/Carousel";
import neutral1 from "../../images/bodymap/genderNeutral1.webp";
import neutral2 from "../../images/bodymap/genderNeutral2.webp";

export function BodymapNeutralCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={neutral1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={neutral2} alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  );
}

export default BodymapNeutralCarousel;
