import React from 'react';
import Card from 'react-bootstrap/Card';
import { truncateText } from '../../utils/';

export function PressReleaseCard({ release, minWidth }) {
  const truncatedTitle = truncateText(release.title, minWidth, 100); // Adjust the maximum length as needed

  return (
    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
      <Card className='mb-0'>
        <Card.Body className='tile-box d-flex flex-column align-items-between'>
          <h6 className='text-uppercase milan-text-navy'>{truncatedTitle}</h6>
          <Card.Link
            href={release.source}
            rel='noopener noreferrer'
            target='_blank'
            className='mt-auto d-block milan-text-navy'
          >
            Read more
          </Card.Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default PressReleaseCard;
