import React from "react";

export function CareersEEO() {
  return (
    <section>
      <div className="container">
        <p className="milan-text-primary mb-2 text-center">EEO Statement</p>
        <p className="pb-2 milan-fp">
          At Milan Laser Hair Removal, we're passionate about equity, inclusion, and diversity. We believe that a diverse team is essential for delivering excellent service and
          achieving health equity for our clients. We go beyond demographics, valuing the unique identities and experiences of our employees. Our commitment is to build a team that
          reflects the clients we serve. We're an equal opportunity employer, welcoming all qualified individuals, regardless of race, color, religion, gender, sexual orientation,
          and more. Milan Laser Hair Removal is dedicated to creating an inclusive, discrimination-free workplace where everyone can thrive.
        </p>
        <p className="milan-text-primary mb-2 text-center">ADA Statement</p>
        <p className="pb-5 milan-fp">
          Milan Laser Hair Removal complies with federal and state disability laws and makes reasonable accommodations for applicants and employees with disabilities. If reasonable
          accommodation is needed to participate in the job application or interview process, to perform essential job functions, and/or to receive other benefits and privileges of
          employment, please contact <a href="mailto:recruiting@milanlaser.com">recruiting@milanlaser.com</a>.
        </p>
      </div>
    </section>
  );
}

export default CareersEEO;
