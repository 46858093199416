import React from 'react'
import TimeConsuming from '../../../images/lp/waxing/time-consuming.webp'
import TemporaryResults from '../../../images/lp/waxing/temporary-results.webp'
import Painful from '../../../images/lp/waxing/painful.webp'
import Money from '../../../images/lp/waxing/money.webp'

export function ConsideringWaxing() {
  return (
    <section id="considering-waxing" className="milan-py">
      <h2 className="subheadlg milan-text-primary text-center pb-0">Considering Waxing? What You Need To Know</h2>
      <div className="container">
        <div className="row justify-content-center gy-5 g-4 g-lg-5 mt-0">
          <div className="col-md-6 col-lg-5">
            <div className="row justify-content-center gy-3">
              <div className="col-md-5 col-xl-4 text-center">
                <img src={TimeConsuming} alt="Time Consuming." className="mx-xxl-2" />
              </div>
              <div className="col-md-7">
                <p className="mb-0 text-center text-md-start"><strong>Time Consuming.</strong><br /> You’ll need to squeeze 10 or more appointments a year into your busy schedule. That’s about 8 hours of your life every year just getting waxes!</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="row justify-content-center gy-3">
              <div className="col-md-5 col-xl-4 text-center">
                <img src={TemporaryResults} alt="Temporary Results" className="mx-xxl-2" />
              </div>
              <div className="col-md-7">
                <p className="mb-0 text-center text-md-start"><strong>Temporary Results.</strong><br /> No matter how many times you wax, you’ll only ever get temporarily smooth skin. The hair will always grow back.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="row justify-content-center gy-3">
              <div className="col-md-5 col-xl-4 text-center">
                <img src={Painful} alt="Waxing is painful" className="mx-xxl-2" />
              </div>
              <div className="col-md-7">
                <p className="mb-0 text-center text-md-start"><strong>It’s Painful.</strong><br /> Ripping your unwanted hair out by the root with hot wax hurts! Some people may tolerate it better than others, but it’s never a ‘comfortable’ experience.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="row justify-content-center gy-3">
              <div className="col-md-5 col-xl-4 text-center">
                <img src={Money} alt="Money Honey" className="mx-xxl-2" />
              </div>
              <div className="col-md-7">
                <p className="mb-0 text-center text-md-start"><strong>Money Honey.</strong><br /> The cost of a monthly wax for the rest of your life will add up to an unfathomable expense without the satisfaction of any permanent results.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="works"></span>
    </section>
  )
}

export default ConsideringWaxing