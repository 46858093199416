import React from 'react'
import { Link } from 'gatsby'

export function LpMenHero() {
  return (
    <section className="hero">
      <div className="container py-lg-5">
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col col-md-9 offset-lg-6 col-lg-6 col-xxl-5 ">
            <h1 className="milan-text-primary">Always Smooth. Always Ready.</h1>
            <div className="row">
              <div className="col col-xl-11">
                <p className="me-md-5 me-lg-0 me-xl-5">Laser hair removal is a <strong>permanent solution to unwanted hair<sup>&dagger;</sup></strong>. We can remove hair from nearly any part of the body for<strong> less than the cost of a monthly wax</strong>. Treatments are fast and there’s no recovery time (it&nbsp;can be done over lunch!).</p>
              </div>
            </div>
            <div className="row mt-3 gy-3">
              <div className="col-sm-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="#works">See How LHR Works</Link>
              </div>
              <div className="col-sm-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="/specials/#guarantee">Get Unlimited Treatments</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="works"></span>
    </section>
  )
}

export default LpMenHero