import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function PcosSponsor() {
  return (
    <section
      id='pcos-sponsor'
      className='divider-section w-100 py-4 py-md-2 milan-bg-navy'
    >
      <div className='container'>
        <div className='row justify-content-center milan-text-white text-center'>
          <div className='col-sm-6'>
            <h3 className='subpointsm h-100 d-flex align-items-center justify-content-center justify-content-md-end'>
              Official Sponsor of
            </h3>
          </div>
          <div className='col-sm-6'>
            <figure className='mb-0 d-flex align-items-center justify-content-center justify-content-md-start'>
              <StaticImage
                src='../../images/pcos/pcosAA.png'
                alt='PCOS AA'
                height='120'
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PcosSponsor;
