import React from 'react';
import { Link } from 'gatsby';

export function HomeDeviceHero() {
  return (
    <section id='home-device-hero' className='hero position-relative py-0'>
      <div className='d-lg-none stacked-bg' />
      <div className='container py-lg-milan-50'>
        <div className='row'>
          <div className='offset-lg-6 col-lg-6 offset-xl-6 col-xl-6 py-4'>
            <h1 className='smalltitle col-xxl-11'>
              <span id='first-title'>DIY.</span> <br />
              <span id='second-title'>
                GOOD FOR HOME PROJECTS, <br /> BAD FOR LASER HAIR REMOVAL.
              </span>
            </h1>
            <p>
              You wouldn’t use a blow dryer to dry your clothes, so why use a
              DIY laser to remove unwanted hair? Medical laser hair removal is
              the best and quickest way to get hair-free for good. If you’ve
              researched professional laser hair removal, you’ve likely heard of
              at-home DIY laser hair removal devices. But are they a flash of
              genius or a flash in the pan?
            </p>
            <div className='my-4 text-center'>
              <Link className='milan-btn milan-cta-btn' to='#consult'>
                Book <span className='text-lowercase'>your</span> FREE
                <span className='text-lowercase'> consultation</span>!
              </Link>
            </div>
            <p className='mb-0 milan-fp'>
              *Free treatment included with completion of a consultation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HomeDeviceHero;
