import hardTrim from "./hardTrim"
import { MILAN_SITE_START, CORPORATE_SITE } from "../constants"

const trimLower = str => hardTrim(str).toLowerCase()

export const searchMilanStores = (milanStores, searchInput) => {
  let foundStores = []
  const value = trimLower(searchInput).toLowerCase()
  milanStores.forEach((state, s) => {
    if (trimLower(state.name).includes(value)) {
      foundStores.push({
        title: 'Milan Laser ' + state.name,
        url: `${CORPORATE_SITE}/locations/${trimLower(state.name)}`,
      })
    }
    if (state.metros.length !== 0) {
      state.metros.forEach((metro, m) => {
        if (trimLower(metro.name).includes(value)) {
          foundStores.push({
            title: 'Milan Laser ' + metro.name,
            url: `${MILAN_SITE_START}${metro.pathname}.com`,
          })
        }
        if (metro.stores.length !== 0) {
          metro.stores.forEach((store, x) => {
            if (trimLower(store.marketingCity).includes(value) || trimLower(store.addressCity).includes(value) || trimLower(store.clinicName).includes(value)) {
              foundStores.push({
                title: 'Milan Laser ' + store.marketingCity,
                url: `${MILAN_SITE_START}${metro.pathname}.com/locations/${metro.stores.length === 1 ? store.pathname : `${metro.metroPath}/${store.pathname}`}/`,
              })
            }
          })
        }
      })
    }
  })
  if (foundStores.length > 0) {
    foundStores.push({
      title: 'Milan Laser Locations',
      url: '/locations/'
    })
  }
  return foundStores
}