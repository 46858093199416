import React from 'react'

export function BB_Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="bg-transparent-5 p-4 rounded">
              <h1 className="milan-text-primary smalltitle">Laser Hair Removal For Bodybuilders</h1>
              <p> You’ve worked hard to build the most conditioned physique of your life; make sure unwanted hair doesn’t ruin your bodybuilding aesthetic with laser hair removal.</p>
              <ul className="d-none d-sm-block list-style-disc">
                <li>Results are permanent&#8230; <strong>making it the best hair removal method for bodybuilders.&dagger;</strong></li>
                <li>Our lasers are faster &amp; <strong>less painful than waxing.</strong></li>
                <li>Don't let stubble hide your muscle definition&#8212;<strong>treatments are done on cleanly shaven areas.</strong></li>
              </ul>
              <p className="mb-0">No more last-minute waxing!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BB_Hero