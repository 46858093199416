import React from "react"
import { Link } from "gatsby"
const BenefitsBeforeAfter = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Flp%2FBenefits-Before-After.webp?alt=media&token=452b5e55-b86b-4385-833c-1a26d00f5bd7'

export function LHRBenefits() {
  return (
    <section id="lhr-benefits" className="milan-bg-tur beach-bg-1">
      <div className="background spacer d-xl-none hr-divider hr-divider-reversed-flipped hr-divider-turq-light"></div>
      <div className="container pb-3 pb-md-0">
        <div className="row g-0">
          <div className="col-xl-5 col-xxl-6 vrt-divider vrt-divider-turq-alt"></div>
          <div className="col-xl-7 col-xxl-6 milan-bg-tur milan-text-white milan-py">
            <h2 className="subheadlg">Benefits of Laser Hair Removal</h2>
            <div className="row flex-md-row-reverse">
              <div className="col">
                <p><Link to="/areas/lipnchin/">Lips</Link> to <Link to="/areas/legs/">legs</Link>, laser hair removal at Milan can take care of any hair on your body with ease. Our laser can treat delicate areas (like <Link to="/areas/bikini/">Bikini</Link> and <Link to="/areas/brazilian/">Brazilian</Link>), and gently eliminates your hairiest concerns.</p>
                <p>Milan's staff is discreet and professional, so even the most sensitive areas will be well-cared for. <Link to="/locations/contact/"><strong>Got a question</strong> about a specific area? Don't be bashful, we've heard them all!</Link></p>
                <p className="mb-0"><Link to="#consult">Book your <strong>free consultation</strong></Link> to customize your hair removal so you can be smooth in all the right places.</p>
              </div>
              <div className="col-md-auto">
                <div className="text-center mt-4 mt-md-1">
                  <img src={BenefitsBeforeAfter} style={{ maxWidth: '240px' }} alt="Legs Laser Hair Removal Photo, Before & After" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default LHRBenefits
