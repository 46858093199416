import React from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import { WAXING_COST_YEAR } from "../../../utils"

export function WaxingCostPerYear({ globalData }) {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy overflow-hidden">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
          <div className="contain">
            <div className="row justify-content-center milan-text-white text-center">
              <div className="h-100 col-12 col-md-12 d-xl-flex align-items-center justify-content-center">
                <h3 className="subpointlg mb-0 d-inline">
                  Waxing costs{" "}
                  <span className="milan-text-tur width-fluid-1">
                    <strong>
                      $
                      {isVisible ? (
                        <CountUp
                          end={
                            globalData?.waxing_cost_per_year
                              ? globalData?.waxing_cost_per_year
                              : WAXING_COST_YEAR
                          }
                          duration={2}
                        />
                      ) : (
                        0
                      )}
                      ,000
                    </strong>
                  </span>
                </h3>
                {/* Inline show */}
                <p className="p-subhead d-none d-xl-inline ps-3">
                  over a lifetime on average.
                </p>
                {/* Stacked */}
                <p className="p-subhead d-block d-xl-none mb-0">
                  over a lifetime on average.
                </p>
              </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </section>
  )
}
export default WaxingCostPerYear
