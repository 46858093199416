import React from 'react'
import { Link } from 'gatsby'
import WaxingHeroImg from '../../../images/lp/waxing/waxing-vs-hero.webp'
import ArrowScroll from '../../../images/lp/waxing/3-arrows-scroll.webp'

export function WaxingHero() {
  return (
    <section className="hero py-0">
      <div id="hero-top" className="container-fluid background">
        <div className="row justify-content-center">
          <div className="col-auto">
            <img src={WaxingHeroImg} alt="Waxing vs. Laser" />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <p className="text-center p-subhead">There’s nothing like soft, freshly smooth legs. When we think of how much time and money is wasted on shaving, we cringe. And while we’ve thrown our razors out the window, we’re still wondering why waxing is the go-to method for hair removal.</p>
            <figure className="text-center mt-5">
              <Link to="#works"><img src={ArrowScroll} alt="Arrow Scroll" style={{ maxWidth: '100px' }} /></Link>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WaxingHero