import React from 'react'

export function FolliculitisHero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row justify-content-md-end">
          <div className="col-md-6">
            <div className="bg-transparent-5 p-4 bg-md-transparent p-md-0 rounded">
              <h1 className="milan-text-primary smalltitle">Laser Hair Removal For Folliculitis</h1>
              <p>Laser hair removal prevents the embarrassing red bumps and pimples caused by folliculitis.</p>
              <ul className="list-style-disc d-none d-sm-block">
                <li>Permanently remove unwanted hair <strong>without skin irritation.</strong></li>
                <li><strong>Eliminate</strong> the need for <strong>medications</strong> or special creams.</li>
              </ul>
              <p className="mb-0">Prevent future folliculitis outbreaks and get <strong>permanent results</strong> with laser hair removal.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FolliculitisHero