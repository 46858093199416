import { FullStory } from '@fullstory/browser';
import { NETLIFY_FUNCTIONS_PATH } from '../constants';

export class GetEmailTemplate {
  static customQuoteEmailTemplate = (name, city) => {
    return `<html>
      <head>
      <title>Milan Laser Estimate</title>
      <style>
      
      </style>
      </head>
      <body >
      <p>Hello ${name}, </p>
      <p>Thank you for contacting Milan Laser for your custom quote! We'll be reaching out to you soon.
      Have another question? Just reply to this email and we'll get back to you.</p>
      
      <p>We look forward to helping you never shave again!</p>
      
      
      <p>Thanks again!
      <br>The Milan Laser ${city} Team
      <br>1-833-NO-RAZOR<br>
      <a href=\"www.milanlaser.com\">www.MilanLaser.com</a>
      </p>
      </body>
      </html>`;
  };
  static couponCodeEmailTemplate = (discount, expDate) => {
    return `
      <html>
        <head>
        <title>Your Milan Savings!</title>
        <style>

        </style>
        </head>
        <body >

        <p><strong>Congratulations!</strong></p>

        <p>You've successfully claimed your ${discount}% off discount at Milan Laser Hair Removal! You can redeem your discount at any Milan Laser location through ${expDate} </p>

        <p>Just present this email or your postcard during your free consultation and your savings will automatically be applied! <strong>Simply reply to this email to schedule your free consult today</strong>!</p>


        <p>Thank you,
        <br>Milan Laser
        <br>1-833-NO-RAZOR<br>
        <a href=\"www.milanlaser.com\">www.MilanLaser.com</a>
        </p>
        </body>
      </html>
    `;
  };
}
// utility function to send email using Netlify function
export async function sendEmail(to, subject, htmlTemplate, source) {
  try {
    const response = await fetch(`${NETLIFY_FUNCTIONS_PATH}sendEmail`, {
      method: 'POST',
      body: JSON.stringify({ to, subject, htmlTemplate }),
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }
    const result = await response.json();

    // FullStory Event Tracking
    FullStory('trackEvent', {
      name: 'Email Sent',
      properties: {
        name: 'Email Sent Successfully',
        value: {
          source: source,
        },
        result: result,
      },
    });
  } catch (error) {
    // FullStory Event Tracking
    FullStory('trackEvent', {
      name: 'Unable to send email',
      properties: {
        name: 'Failed Sending Email',
        value: {
          errorMessage: error.message,
          email: to,
          source: source,
        },
      },
    });
    console.error('Error sending email:', error);
  }
}
