import React from 'react';

import join from '../../images/share/join.svg';
import refer from '../../images/share/refer.svg';
import earn from '../../images/share/earn.svg';
import shop from '../../images/share/shop.svg';

export function HowItWorks() {
  //return jsx that accepts three props: image, title, and description
  const card = (image, title, description) => {
    return (
      // <div className='col-12 col-md-3 text-center'>
      <div className='col-12 col-md-3 d-flex align-items-stretch text-center'>
        <div className='container bg-white p-4 rounded'>
          <img
            src={image}
            alt={title + 'logo image'}
            className='py-5'
            style={{ maxHeight: '160px' }}
          />
          <p className='p-subhead milan-text-primary text-uppercase pb-3'>
            <strong>{title}</strong>
          </p>
          <p className=''>{description}</p>
        </div>
      </div>
    );
  };

  return (
    <section id='share-how-it-works' className='milan-py milan-bg-secondary'>
      <div className='container'>
        <h2 className='subheadlg text-center'>Here's How It Works</h2>
        <div className='row g-2'>
          {card(
            join,
            'Join',
            'Purchase at any of our 350+ clinics to be automatically enrolled.'
          )}
          {card(
            refer,
            'Refer',
            'Tell your friends, and give them 50% OFF unlimited laser hair removal.'
          )}
          {card(
            earn,
            'Earn',
            'Get a $100 gift card if they purchase a laser hair removal package.'
          )}
          {card(
            shop,
            'Shop',
            'Use your digital gift card at any 200+ participating retailers!'
          )}
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
