import MILITARY from '../pressReleasePDFs/PressRelease_MilanLaserHairRemoval-MilitaryMakeoverwithMontel.pdf';
import INC_5000_SECOND from '../pressReleasePDFs/Press_Release_Inc_5000-2024.pdf';
import SWIM_WEEK from '../pressReleasePDFs/Press_Release_Milan_Laser_Miami_Swim_Week.pdf';
import COO from '../pressReleasePDFs/press-release-milan-laser-hair-removal-announces-new-COO.pdf';
import ICON_AWARD from '../pressReleasePDFs/Jared-Widseth-of-Milan-Laser-Hair-Removal-Honored-with-OnCon-Icon-Corporate-Counsel-Award.pdf';
import BAIRD_HOLM from '../pressReleasePDFs/Baird-Holm-LLP-Names-Milan-Laser-Hair-Removal-One-of-the-Best-Places-to-Work-in-Omaha.pdf';
import INC_5000 from '../pressReleasePDFs/Milan-Laser-Ranks-No.-1125-on-the-2023-Inc.-5000-List-of-America_s-Fastest-Growing-Private-Companies.pdf';
import LOCATION_300 from '../pressReleasePDFs/Milan-Laser-Hair-Removal-Opens-300th-Location.pdf';
import EY from '../pressReleasePDFs/EY-Announces-Dr.-Shikhar-Saxena-of-Milan-Laser-Hair-Removal-as-an-Entrepreneur-of-the-Year-2023-Heartland-Award-Winner.pdf';
import STRATEGIC_INVESTMENT from '../pressReleasePDFs/Milan-Laser-continues-Growth-with-New-Strategic-Investment-from-Sixth-Street-and-Wildcat-Capital-Management-in-Partnership-with-LGP.pdf';
import CDO from '../pressReleasePDFs/Milan-Laser-Hair-Removal-Announces-New-Chief-Development-Officer.pdf';
import PRESIDENT from '../pressReleasePDFs/Milan-Laser-Hair-Removal-Announces-New-Company-President.pdf';

export const pressReleasesList = [
  {
    title:
      'Milan Laser Hair Removal Partners with Military Makeover with Montel to Server Veterans and Their Families',
    source: MILITARY,
  },
  {
    title: 'Milan Laser Named to the Inc. 5000 for Second Consecutive Year',
    source: INC_5000_SECOND,
  },
  {
    title:
      'Milan Laser Hair Removal Bares Its Brand at Miami Swim Week® The Shows',
    source: SWIM_WEEK,
  },
  {
    title: 'Milan Laser Hair Removal Announces New Chief Operating Officer',
    source: COO,
  },
  {
    title:
      'Jared Widseth of Milan Laser Hair Removal Honored with OnCon Icon Corporate Counsel Award',
    source: ICON_AWARD,
  },
  {
    title:
      'Baird Holm LLP Names Milan Laser Hair Removal One of the Best Places to Work in Omaha®',
    source: BAIRD_HOLM,
  },
  {
    title:
      "Milan Laser Ranks No. 1125 on the 2023 Inc. 5000 List of America's Fastest-Growing Private Companies",
    source: INC_5000,
  },
  {
    title: 'Milan Laser Hair Removal Opens 300th Location',
    source: LOCATION_300,
  },
  {
    title:
      'EY Announces Dr. Shikhar Saxena of Milan Laser Hair Removal as an Entrepreneur of the...',
    source: EY,
  },
  {
    title:
      'Milan Laser continues Growth with New Strategic Investment from Sixth Street and Wildcat Captial...',
    source: STRATEGIC_INVESTMENT,
  },
  {
    title: 'Milan Laser Hair Removal Announces New Chief Development Officer',
    source: CDO,
  },
  {
    title: 'Milan Laser Hair Removal Announces New Company President',
    source: PRESIDENT,
  },
];
