import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import { LocationsDropdown } from '../shared';
import { TextError } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';

export function SelectLocation(props) {
  const { label, name, siteData, ...rest } = props;
  const [field, meta] = useField(props);

  useEffect(() => {
    // The if condition is important. Otherwise, it will start an infinite loop when navigating between pages
    // Note: Only update the selected store in the siteData when a store is actually selected // NOT an empty selection
    if (field.value) siteData?.updateSelectedStore(field.value);
  }, [field.value]);

  return (
    <div>
      {label && (
        <label htmlFor={name}>
          {label}{' '}
          <sup>
            <FontAwesomeIcon icon={faAsterisk} />
          </sup>
        </label>
      )}
      <Field
        as='select'
        id={name}
        name={name}
        {...rest}
        className={`form-select ${meta.error && meta.touched ? 'border-error' : ''}`}
      >
        <LocationsDropdown milanStores={siteData?.milanStores} />
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default SelectLocation;
