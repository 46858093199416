import React from 'react'
import { Link } from 'gatsby'
import { HOME_PAGE_COPY } from '..'

const SpecialsBanner = () => (
  <Link to="/specials/" className="specials-banner milan-text-white">
    <span className="text-uppercase">
      <strong>{HOME_PAGE_COPY.default.split('|')[0].trim()}</strong> <span>{HOME_PAGE_COPY.default.split('|')[1].trim()}</span>
    </span>
  </Link>
)

export default SpecialsBanner