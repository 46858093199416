import React from 'react'
import { ContactForm } from '../../forms'
import { MILAN_PHONE, NO_RAZOR } from '../../../utils'

export function ContactHero({ siteData }) {
  return (
    <section className="background hero contact-hero">
      <div className="container">
        <h1 className="milan-text-primary text-center mb-4">Contact Us</h1>
        <div className="row">
          <div className="col-md-4 col-lg-6"></div>
          <div className="col-11 col-md-8 col-lg-6 shadow-sm mx-auto bg-transparent-7">
            <div className="text-center">
            <h2 className="mt-3 py-3 subheadsm">Feel Free to Ask a Question</h2>
              <h5 className="ribbon">Call Us <a href={`tel:${MILAN_PHONE}`} className="milan-text-white stretched-link">{NO_RAZOR}</a></h5>
              <span name="askQ" id="askQ" ></span>
            </div>
            <div className="text-center py-3">
              {
                siteData.selectedStore.store && siteData.selectedStore.store.phone !== '' &&
                <>
                  <p className="mt-4 mb-2 p-subhead">To Re-schedule a Treatment</p>
                  <a href={`tel:${siteData.selectedStore.store.phone}`} className="milan-text-primary d-block ">{siteData.selectedStore.store.phone}</a>
                </>
              }
            </div>
            <p className="mt-4 mb-2 p-subhead text-center">Or Send Us a Question</p>
            <ContactForm siteData={siteData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactHero