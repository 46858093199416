import React from "react";
import { Link } from "gatsby";

export function LHRSafeForDark() {
  return (
    <section id="lhr-safe-dark" className="milan-bg-tur">
    <div className="container">
      <div className="row">
        <div className="col-xl-7 milan-bg-tur milan-text-white py-milan-50">
          <div className="row">
            <div className="col-auto">
              <h2 className="subheadlg">Why Laser Hair Removal Is Safe for All Skin Tones</h2>
            </div>
            <div className="col-12 z-index-100 order-2 order-md-1">                
              <p>It’s a myth that laser hair removal doesn’t work for darker skin tones. While this may have been true when the FDA first approved laser hair removal in the 1990s, technology has advanced, and <strong>laser hair removal is safe for the entire skin tone spectrum.</strong></p>
              <p>However, some providers advertise laser hair removal as intense pulsed light (IPL), the same technology found in at-home devices. IPL does not use real laser technology and <strong>should never be used on darker skin tones.</strong> The IPL can’t differentiate between pigment in the hair and the skin, leading to <strong>burns, blisters, and discoloration.</strong></p>
              <p className="d-none d-sm-block">The <strong>GentleMax Pro</strong> at Milan Laser uses two lasers — the Alexandrite for lighter skin and the <strong>Nd:YAG for darker skin.</strong> The Nd:YAG technology has a weaker melanin absorption rate and longer wavelengths, making it <strong>safe for deeper skin tones.</strong></p>
              <p>Are you ready to get the smooth, hair-free skin you’ve always wanted? Schedule your <strong>FREE consultation</strong> today!</p>
            </div>
          </div>
        </div>
        <div className="col-xl-5 vrt-divider vrt-divider-reversed vrt-divider-turq" />
      </div>
    </div>
    <div className="spacer d-xl-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
  </section>
  );
}

export default LHRSafeForDark;