import React from 'react';

export function LocationsDropdown({ milanStores }) {
    return (
        <>
            <optgroup>
                <option value="">Select a location</option>
            </optgroup>
            {milanStores?.length !== 0 &&
                milanStores?.map((state, s) => (
                    <optgroup key={s} label={state.name}>
                        {state.metros.map((metro) =>
                            metro.stores.map((store, x) => (
                                <option key={x} value={store.salesforceValue} zip={store.zipCode}>
                                    {/* original, this assums it's all omaha west but we have something like Salem (west salem) */}
                                    {/* {metro.name} {store.marketingCity.includes(metro.name) ? store.marketingCity.slice(metro.name.length + 1) : `(${store.marketingCity})`} {store.open === false ? '/ Coming Soon' : ''} */}
                                    {/* FIX: the goal is to keep the 'west' ex. Omaha West -> West and West Salem -> West */}
                                    {metro.name}{' '}
                                    {store.marketingCity.includes(metro.name)
                                        ? store.marketingCity.replace(metro.name, '').trim()
                                        : `(${store.marketingCity})`}{' '}
                                    {store.open === false ? '/ Coming Soon' : ''}
                                </option>
                            )),
                        )}
                        {state.borrowed_metros?.length > 0 &&
                            state.borrowed_metros.map((borrowed, b) => {
                                let borrowedMetro = milanStores
                                    .find((item) => item.abbreviation === borrowed.borrow_from)
                                    .metros.find((metro) => metro.pathname === borrowed.pathname);
                                let filteredStores = borrowedMetro.stores.filter(
                                    (store) => store.stateAbbrev === state.abbreviation,
                                ); // return stores of this state only
                                return filteredStores.map((store, x) => (
                                    <option key={x} value={store.salesforceValue} zip={store.zipCode}>
                                        {/* original */}
                                        {/* {borrowedMetro.name} {store.marketingCity.includes(borrowedMetro.name) ? store.marketingCity.slice(borrowedMetro.name.length + 1) : `(${store.marketingCity})`} {store.open === false ? '/ Coming Soon' : ''} */}
                                        {/* fix */}
                                        {borrowedMetro.name}{' '}
                                        {store.marketingCity.includes(borrowedMetro.name)
                                            ? store.marketingCity.replace(borrowedMetro.name, '').trim()
                                            : `(${store.marketingCity})`}{' '}
                                        {store.open === false ? '/ Coming Soon' : ''}
                                    </option>
                                ));
                            })}
                    </optgroup>
                ))}
        </>
    );
}

export default LocationsDropdown;
