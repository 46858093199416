export const consultInitialValues = {
  first_name: '',
  last_name: '',
  phoneMask: '',
  email: '',
  description: '',
  '00N1L00000F9eBV': '',
  updates: true,
  consultType: '',
};
