import { useState, useEffect } from 'react'
import algoliasearch from 'algoliasearch';
import { searchMilanStores } from '../utils'

const searchClient = algoliasearch('F98JFW16WV', 'e197b42c5373d40ca2a3331cb642323f');

const defaultResults = [
  { title: 'Milan Laser Locations', url: '/locations/' },
  { title: 'Contact Us', url: '/locations/contact/' },
  { title: 'FAQs', url: '/process/faqs/' },
  { title: 'Are you a good candidate for laser hair removal?', url: '/process/quiz/' },
]

export function useAlgolia(searchInput, milanStores) {
  const [hits, setHits] = useState([])
  let timeout = null

  useEffect(() => {
    if (searchInput.length > 0 && searchInput.length < 3) {
      setHits([{ loading: true }])
    } else if (searchInput.length >= 3) {
      timeout = setTimeout(() => {
        setHits([{ loading: true }])
        const index = searchClient.initIndex('featured')
        index.search(searchInput, { hitsPerPage: 6 }).then(res => {
          // if NO results returned -> Search in Milan Locations 
          if (res.hits.length === 0) {
            if (isFinite(searchInput)) { // It's more likely searching for an address
              setHits([{ title: 'Milan Laser Locations', url: '/locations/' }])
            } else { // Search in Milan Locations
              let foundStores = searchMilanStores(milanStores, searchInput)
              if (foundStores.length > 0) {
                setHits(foundStores)
              } else {
                // setHits([{ message: "We can’t find what you’re looking for. Please try again or check out our most popular searches below." }])
                setHits(defaultResults)
              }
            }
          } else { // Display returned results
            setHits([...res.hits])
          }
        }).catch(err => {
          setHits([{
            message: 'Something went wrong. Please check your connection and try again.',
            error: err
          }])
        })
      }, 1000)
    } else {
      setHits([])
    }
    return () => clearTimeout(timeout)
  }, [searchInput])

  return hits
}

export default useAlgolia