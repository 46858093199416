import React from 'react';
import {FooterBottom, FooterTop} from '.';
import './footer.css';

export function Footer({siteData, setShowSignUpModal}) {
  return (
    <footer>
      <FooterTop milanStores={siteData.milanStores} />
      <FooterBottom siteData={siteData} setShowSignUpModal={setShowSignUpModal} />
    </footer>
  );
}
export default Footer;
