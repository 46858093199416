import React from 'react'
import { AreasSignUpForm } from '../../forms'

export function MilanDeals({siteData}) {
  return (
    <section className="w-100 py-5 navy-bg">
      <div className="container ">
        <div className="row milan-text-white align-items-center">
          <div className="col-lg-6 text-center">
          <h3 className="subpointsm milan-text-primary mb-3">Get Laser Hair Removal <br /> <span className="subpointlg">Deals in Your Inbox</span></h3>
          </div>
          <div className="col-lg-6">
            <AreasSignUpForm siteData={siteData} />
          </div>
          </div>
      </div>
    </section>
  )
}

export default MilanDeals
