import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons";

export function Input(props) {
  const { label, name, optional, ...rest } = props
  return (
    <>
      {label && <label htmlFor={name}>{label} {!optional && <sup><FontAwesomeIcon icon={faAsterisk} /></sup>}</label>}
      <Field name={name}>
        {
          props => {
            // const { field, meta } = props
            const { field, meta } = props
            return (
              <input
                className={`form-control ${meta.error && meta.touched ? 'border-error' : ''}`} id={name}
                {...field} {...rest} />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export default Input