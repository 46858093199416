import React from 'react';
import { Link } from 'gatsby';

export function EDLPHero() {
  return (
    <section className='hero milan-bg-white py-xl-milan-50'>
      {/* bg when stacked*/}
      <div className='d-xl-none stack-hero-bg' />
      <div className='container'>
        <div className='row'>
          <div className='offset-xl-5 col-xl-7 offset-xxl-7 col-xxl-5'>
            <h1 className='milan-text-primary pt-5 pt-xl-0'>
              All the ways to smooth & save.
            </h1>
            <p>
              With unlimited treatments and guaranteed results, we offer
              unbeatable everyday low pricing (no waiting for extra discounts or
              sales — you’re always getting the lowest price possible!) and the
              most zap for your cash on our most popular packages:
            </p>
            <ul className='list-style-disc'>
              <li>
                <strong>Full-body laser hair removal</strong>
                <br />
                Get bare everywhere! Our full-body package gets you smooth from
                head to toe.
              </li>
              <li>
                <strong>
                  Glamour + Glow Bundle (Full face and neck, underarms, and
                  female Brazilian)
                </strong>
                <br />
                On the glow? Make mornings a breeze with this best-selling
                bundle!
              </li>
              <li>
                <strong>
                  Bare Down There Bundle (Female Brazilian+, full legs, navel,
                  and feet/toes)
                </strong>
                <br />
                Save on the faves! With our exclusive bundles, you’re always
                ready to go.
              </li>
            </ul>
            <p className='mb-0'>
              Learn more about our process, everyday low pricing, and more when
              you book a FREE consultation!
            </p>
            <div className='row'>
              <div className='col text-center'>
                <Link
                  className='milan-btn milan-primary-btn milan-btn-width my-4'
                  to='#consult'
                >
                  Book Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EDLPHero;
