import React from 'react'
import Carousel from "react-bootstrap/Carousel";

export function SayHelloTo() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <div className="container ">
        <div className="row align-items-center justify-content-end">
          <div className="col-lg-6 col-xl-4">
          <h3 className="subpointlg milan-text-white text-center text-lg-end mb-lg-0">Say Hello To</h3>
          </div>
          <div className="col-lg-6">
            <Carousel
              controls={false}
              indicators={false}
              interval="4000">
              <Carousel.Item className="milan-text-secondary">
              <h3 className="subpointsm text-center text-lg-start text-uppercase mb-0">a low-maintenance routine</h3>
              </Carousel.Item>
              <Carousel.Item className="milan-text-secondary">
              <h3 className="subpointsm text-center text-lg-start text-uppercase mb-0">no ingrown hairs</h3>
              </Carousel.Item>
              <Carousel.Item className="milan-text-secondary">
              <h3 className="subpointsm text-center text-lg-start text-uppercase mb-0">no skin irritations</h3>
              </Carousel.Item>
              <Carousel.Item className="milan-text-secondary">
              <h3 className="subpointsm text-center text-lg-start text-uppercase mb-0">saving time & money</h3>
              </Carousel.Item>
              <Carousel.Item className="milan-text-secondary">
              <h3 className="subpointsm text-center text-lg-start text-uppercase mb-0">smooth, beautiful skin!</h3>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SayHelloTo