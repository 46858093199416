import React from 'react';
import { GATSBY_DOMAIN } from '../../utils';
import { ClientSaleForm } from '../forms';

export function EnterHero({ siteData, variant }) {
  return (
    <section className='hero py-milan-50 py-md-milan-100 py-lg-milan-150'>
      <ClientSaleForm siteData={siteData} variant={variant} />
    </section>
  );
}

export default EnterHero;
