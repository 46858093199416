import React from "react";
import { Link } from "gatsby";
import badgeDesktop from "../../../images/lp/permanence/hero-badge-desktop.webp";
import badgeMobile from "../../../images/lp/permanence/hero-badge-mobile.webp";
import ArrowScroll from '../../../images/lp/waxing/3-arrows-scroll.webp'


export function PermanenceHero() {
  return (
    <section className="hero py-0">
      <div className="hero-top w-100">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="d-block d-sm-none">
            <img
              src={badgeMobile}
              style={{ maxWidth: "300px" }}
              alt="Ditch Your Razor... Get Permanent Results With Laser Hair Removal"
            ></img>
          </div>
          <div className="d-none d-sm-block">
            <img
              src={badgeDesktop}
              style={{ maxWidth: "610px" }}
              alt="Ditch Your Razor... Get Permanent Results With Laser Hair Removal"
            ></img>
          </div>
        </div>
      </div>
      <div className="hero-bot w-100">
        <div className="container d-flex justify-content-center pt-5">
          <p className="hero-p text-center">
            When we think of how much time—not to mention money—is wasted on temporarily removing unwanted hair, we cringe. You
            deserve to have smooth, hair-free skin without constantly torturing your skin with molten wax and razors. Unlike your
            waxing or shaving routine, laser hair removal will give you permanent results!
          </p>
        </div>
        <figure className="text-center mt-5">
          <Link to="#lhr-steps">
            <img src={ArrowScroll} alt="Arrow Scroll" style={{ maxWidth: "100px" }} />
          </Link>
        </figure>
      </div>
    </section>
  );
}

export default PermanenceHero;
