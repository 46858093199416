import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { PressCustomToggle, PressReleaseCard } from '.';
import { pressReleasesList } from './util/pressReleasesList';
import { BREAKPOINTS } from '../../utils/constants';
import { useBreakpoint } from 'use-breakpoint';

export function PressReleases() {
  const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs');
  let numToShow =
    minWidth >= BREAKPOINTS.lg && minWidth < BREAKPOINTS.xl ? 3 : 4;
  const releaseShow = pressReleasesList.slice(0, numToShow);
  const releaseHidden = pressReleasesList.slice(numToShow);

  return (
    // Press Release
    <section
      id='press-releases'
      className='milan-bg-tur py-5 hr-divider hr-divider-consult hr-divider-reversed'
    >
      <div className='container'>
        <h2 className='subheadlg'>Press Releases</h2>
        {/* first we put the first videos based on screen size */}
        <Accordion defaultActiveKey='1'>
          <div className='row'>
            {releaseShow.map((release, i) => (
              <PressReleaseCard release={release} key={i} minWidth={minWidth} />
            ))}
            {/* after the first set, it will be collapsable */}
            <Accordion.Collapse eventKey='0'>
              <div className='row justify-content-md-center'>
                {releaseHidden.map((release, i) => (
                  <PressReleaseCard
                    release={release}
                    key={i}
                    minWidth={minWidth}
                  />
                ))}
              </div>
            </Accordion.Collapse>
          </div>
          <PressCustomToggle />
        </Accordion>
      </div>
    </section>
  );
}

export default PressReleases;
