import React from "react"
import { Link } from "gatsby"

export function Legs() {
  return (
    <section id="areas-legs" className="milan-bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 milan-bg-primary milan-text-white py-milan-50 py-xl-milan-150">
            <div className="row">
              <div className="offset-xl-1 col-auto col-xl-11">
                <h2 className="subheadlg">Laser Hair Removal for Legs</h2>
              </div>
              <div className="col-12 offset-xl-1 z-index-100 order-2 order-md-1">
                <p>Never shave again. With laser hair removal, your legs don’t have to be victims of nicks or itchy razor burn anymore. Your legs will be softer and look incredible without hair follicles creating shadows in the skin. Unlike messy creams, sticky at-home-kits, or cheap light devices, laser hair removal is the real never shave again solution. Stop negotiating the terrain around your knees and ankles and start your journey to smooth.</p>
                <p className="mb-xl-0 pb-5 pb-xl-0"><Link to="/areas/legs/">Learn more about <strong>laser hair removal for legs.</strong></Link></p>
              </div>
            </div>
          </div>
          <div className="col-xl-7 vrt-divider vrt-divider-reversed vrt-divider-blue" />
        </div>
      </div>
      <div className="stack-hero-bg d-xl-none hr-divider hr-divider-blue"></div>
    </section>

    // <section id="areas-legs" className="milan-bg-primary">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-5 col-xxl-6 vrt-divider vrt-divider-blue"></div>
    //       <div className="col-lg-7 col-xxl-6 milan-bg-primary milan-text-white milan-py-lg">
    //         <div className="row">
    //           <div className="col z-index-100">
    //             <h2 className="subheadlg">Laser Hair Removal for Legs</h2>
    //             <p>Never shave again. With laser hair removal, your legs don’t have to be victims of nicks or itchy razor burn anymore. Your legs will be softer and look incredible without hair follicles creating shadows in the skin. Unlike messy creams, sticky at-home-kits, or cheap light devices, laser hair removal is the real never shave again solution. Stop negotiating the terrain around your knees and ankles and start your journey to smooth.</p>
    //             <p className="mb-lg-0"><Link to="/areas/legs/">Learn more about <strong>laser hair removal for legs.</strong></Link></p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="background spacer d-lg-none hr-divider hr-divider-blue"></div>
    // </section>
  )
}
export default Legs