import React from 'react'
import { Link } from 'gatsby'

export function LHRIdealForTrans() {
  return (
    <section id="ideal-for-trans">
      <div className="container milan-py">
        <div className="row justify-content-lg-end">
          <div className="col-lg-7 col-xxl-6">
            <div className="bg-transparent-6 p-4">
              <h2 className="subheadlg milan-text-primary">Why Laser Hair Removal is <br className="d-none d-xl-inline" /> Ideal for Transitioning</h2>
              <p>Laser hair removal is the best solution to unwanted hair during your transition.</p>
              <ul className="list-style-disc">
                <li><strong>Go With Confidence.</strong> Save time and stop worrying about shaving or tweezing before leaving the house. You’re always ready to go!</li>
                <li><strong>Comfortable Treatments.</strong> Unlike painful electrolysis, you’ll comfortably remove unwanted hair without having to let it grow out first.<span className="d-none d-md-inline"> Laser treatments, on average, take less than 30 minutes while electrolysis typically takes several hours to complete.</span></li>
                <li><strong>Never Shave Again.</strong> With Milan’s exclusive <Link to="/specials/#guarantee" className="milan-text-primary">Unlimited Package™</Link>, your results are guaranteed for life. As in, forever and beyond, at no additional cost.<span className="mobileHide"> We believe in our lasers, and we’re willing to prove it by offering unlimited sessions with every package.</span> A stray hair shows up 2, 5 or even 10 years down the road…come back and we’ll take care of it. No questions asked. No touch-up fees.</li>
              </ul>
              <p>Laser hair removal, because you’ve waited long enough to show the world the real you! Smooth, permanently hairless skin is in your future.</p>
              <p>Have unanswered questions about laser hair removal for transitioning? Our friendly, knowledgable staff are happy to answer any questions you may have. </p>
              <p className="mb-0"><Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LHRIdealForTrans