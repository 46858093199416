import React, { useState } from 'react'
import { useCouponData } from '../../hooks'
import Countdown from 'react-countdown';
import { TimeRenderer, CouponInput, ClaimDiscount, BookConsult } from '.'
import { variations } from './utils/variations';
const Sixty = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcoupon%2F60.webp?alt=media&token=90076f2c-8314-49ff-afd4-dead3c670644'
const Fifty = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcoupon%2F50.webp?alt=media&token=4b56622a-f7f4-44a1-b2a6-cac467d1aa27'
const BrochureMock = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcoupon%2Fbrochuremock.png?alt=media&token=21ce6e74-67d0-46a1-a7f1-bad433c228ff'
const BrochureOffer = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcoupon%2FbrochureOffer.webp?alt=media&token=01829e63-1468-40f7-bbc6-091a5ac99eb0'

export function CouponHero({ siteData, type }) {
  const [coupon, setCoupon] = useCouponData(siteData, variations, type)
  const [action, setAction] = useState({ claim: false, booking: false })

  return (
    <section className="hero milan-bg-off-white">
      <div className="container">
        {
          !coupon.fetchedData &&
          <h1 className="smalltitle text-center milan-text-primary">
            <span className="h2 fw-light d-block">Enter Your Code to</span>
            <span className="d-block milan-text-primary">See Your {type === 'offer' ? 'Offers' : 'Savings'}</span>
        </h1>
        }
        <div className="row">
          <div className="col-md-6 text-center">
            <figure className="w-100 h-100 d-flex justify-content-center align-items-center">
              <img src={type === 'offer' ? BrochureOffer : BrochureMock} alt="Coupon Brochure Mock" className="mw-100" />
            </figure>
          </div>
          <div className="col-md-6 offset-xl-1 col-xl-5">
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              {
                !coupon.fetchedData ?
                  <div className="text-center">
                    <p className="d-none d-md-block">Say goodbye to razors or costly waxes, and say hello to the fastest way to permanently smooth skin. Laser hair removal means you can bare it all without the hassles of ingrown hairs killing the mood.</p>
                    <CouponInput {...{ coupon, setCoupon }} />
                  </div>
                  :
                  coupon.isExpired ?
                    <div className="text-center">
                      <p className="milan-text-red">Coupon Expired!</p>
                      <button
                        type="button" className="milan-btn milan-secondary-btn mt-4 mx-auto"
                        onClick={() => setCoupon({
                          value: '',
                          valid: true,
                          errorMsg: '',
                          submit: false,
                          isSubmitting: false,
                          fetchedData: null,
                          isExpired: false
                        })}
                      >Try a different code?</button>
                    </div>
                    :
                    <div className="text-center">
                      <h1 className="milan-text-primary">Congrats!
                      <span><h1 className="smalltitle milan-text-primary pb-0">Your Discount is</h1></span></h1>
                      {
                        coupon.fetchedData.discount === 60 ?
                          <figure className="mx-auto" style={{ maxWidth: '200px' }}>
                            <img src={Sixty} alt="60% Discount" className="w-100" />
                            <figcaption><small>60% Off cannot be used with Full Body.</small></figcaption>
                          </figure>
                          :
                          <figure className="mx-auto" style={{ maxWidth: '200px' }}>
                            <img src={Fifty} alt="50% Discount" className="w-100" />
                          </figure>
                      }
                      <h2 className="subheadsm">Time Left</h2>
                      <Countdown date={coupon.fetchedData.expDate.seconds * 1000} renderer={TimeRenderer} />
                      {action.claim && !action.booking && <ClaimDiscount {...{ siteData, coupon, type }} />}
                      {!action.claim && action.booking && <BookConsult {...{ siteData, coupon, type }} />}
                      {
                        !action.claim &&
                        <div className="post-coupon-actions">
                          <button type="button" className="milan-btn milan-cta-btn mx-auto" onClick={() => setAction({ claim: true, booking: false })}>Claim Your {coupon.fetchedData.discount}% Discount</button>
                        </div>
                      }
                      {
                        !action.booking &&
                        <div className="post-coupon-actions">
                          <button type="button" className="milan-btn milan-primary-btn mt-4 mx-auto" onClick={() => setAction({ claim: false, booking: true })}>Book your Consult with {coupon.fetchedData.discount}% Off</button>
                        </div>
                      }
                    </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CouponHero