import React from 'react';
import { Link } from 'gatsby';

export function HomeDeviceLocation() {
  return (
    <section id='home-device-location' className='position-relative py-0'>
      <div className='d-lg-none stacked-bg' />
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-xxl-4 offset-lg-6 milan-py'>
            <h2 className='subheadlg milan-text-primary'>The Bottom Line</h2>
            <p>
              It’s your skin — don’t risk it. Most procedures involving your
              body shouldn’t be done on a whim in your bathroom. You could, but
              we don’t recommend it.
            </p>
            <p>
              Be the smoothest, most confident version of yourself with expert
              care and permanent results! Visit Milan Laser Hair Removal and
              give your skin the VIP treatment it deserves.
            </p>
            <div className='my-4 text-center'>
              <Link className='milan-btn milan-primary-btn' to='/locations/'>
                Find A Location Near You
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeDeviceLocation;
