import React from 'react';
import { Link } from 'gatsby';
import packageOpen from '../../images/clientsale/clientSale.gif';

export function CaDrawing({ variant }) {
  return (
    <section id='ca-drawing' className='bg-secondar milan-py'>
      <div className='container pt-0'>
        <div className='row'>
          <div className='col-12 col-md-5 order-md-last text-center d-flex justify-content-center align-items-center pb-4 pb-md-0'>
            <img
              src={packageOpen}
              style={{ maxWidth: '330px', maxHeight: '330px' }}
            ></img>
          </div>
          <div className='col-12 col-md-7 order-md-second offset-xl-1 col-xl-6'>
            <h2 className='subheadlg milan-text-primary pb-2'>
              Start Fall With A WIN!
            </h2>
            {variant === 'sale' ? (
              <p>
                We appreciate YOU, so we’re giving away 10 FREE laser hair
                removal packages† or a $500 gift card this month.
              </p>
            ) : (
              <p>
                We appreciate YOU, so we’ve extended our giveaway! Enter to win
                1 of 10 FREE laser hair removal packages† or a $500 gift card
                through October 31.
              </p>
            )}
            {variant === 'sale' ? (
              <p className='milan-fp'>
                † Prize package awarded as a refund of any body area or areas
                purchased between October 1-20, 2024. No purchase necessary to
                enter. If no purchase was made between those dates, the winner
                will receive a $500 gift card instead.
              </p>
            ) : (
              <p className='milan-fp'>
                † Prize package awarded as a refund of any body area or areas
                purchased between October 1-31, 2024. No purchase necessary to
                enter. If no purchase was made between those dates, the winner
                will receive a $500 gift card instead.
              </p>
            )}

            <p className='milan-fp'>See the official contest rules.</p>
            <div className='my-4 text-center'>
              <Link className='milan-btn milan-cta-btn' to='/enter'>
                Enter to Win
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaDrawing;
