import React from 'react';
import { Link } from 'gatsby';

import LARGEST_CO_BADGE from '../../images/shared/LargestUSCoBadge.webp';

export function LargestLHRBadge() {
  return (
    <section>
      <div className='container position-relative'>
        <div className='row'>
          <div className='col'>
            <div
              id='largest-lhr-badge'
              className='mx-auto pb-5 pb-md-0'
              style={{ marginTop: '-20px' }}
            >
              <Link to='/locations/' className='d-block'>
                <img
                  alt='Badge for Milan showing it has 380+ locations, making it the largest laser hair removal company in the United States'
                  style={{ width: '175px', height: '183px' }}
                  src={LARGEST_CO_BADGE}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LargestLHRBadge;
