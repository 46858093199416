import React from 'react'
const mLogo = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fhero-MIcon.svg?alt=media&token=94a32faf-760c-473b-9e97-1caaa1881b35'

export function CareersHero() {
  return (
    <section className="hero py-0">
      <div className="d-block d-md-none before-hero-bg"></div>

      <div className="row hero-row g-0">
        <div className="col-12 col-md-5 position-relative">
          <div className="position-absolute top-50 start-100 translate-middle d-none d-md-block">
            <img src={mLogo} alt="M logo"></img>
          </div>
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="hero-col-1-width py-5 py-md-0 text-center text-md-start">
              <h1 className="text-white pb-2">Careers at Milan Laser</h1>
              <button type="button" className="milan-btn milan-cta-btn text-white">
                <a href="#open-positions" className="text-white">View Open Positions</a>
              </button>
            </div>
          </div>
        </div>
        <div className="col-7 d-none d-sm-block hero-bg"></div>
      </div>
    </section>
  );
}

export default CareersHero;