import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import UNLIMITED_PACKAGE_IMG from '../../../images/lp/Unlimited-Package.webp'

export function TouchUpTreatment() {
  return (
    <section id="touch-up-treatment" className="background bg-center position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="arrow-right" src="../../../images/process/home/arrow-right.png" imgClassName="middle-icon" />
        <div className="row flex-sm-row-reverse">
          <div className="col-md-6">
            <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">
            <h3 className="subpointlg">Touch-Up</h3>
                 <h3 className='subpointsm'>
                 Treatments</h3>
              <p className="mb-0 pt-4">New hair growth is possible due to hormones, age, and genetics, but with us, your results are guaranteed for life! So, simply make an appointment, come on in, and we’ll take care of that new hair at no additional cost.</p>
            </div>
          </div>
          <div className="col-md-6 border-right">
            <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
              <img alt="Milan Laser Hair Removal Process - Touch Up Treatment" src={UNLIMITED_PACKAGE_IMG} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TouchUpTreatment