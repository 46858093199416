import React from 'react'
import { Link } from 'gatsby'

export function LHRForAmputees() {
  return (
    <section id="lhr-for-amputees">
      <div className="spacer d-md-none"></div>
      <div className="container milan-py">
        <h2 className="subheadlg milan-text-primary">Laser Hair Removal <br className="d-xl-none" /> for Amputees</h2>
        <div className="row">
          <div className="col-md-8 col-lg-7 col-xxl-6">
            <p>The benefits of laser hair removal for amputees go beyond simply removing unwanted hair and have <strong>proven to help improve the quality of everyday life</strong>. For more than a decade, laser hair removal has been used to improve skin conditions that are common for amputees. </p>
            <p>Many individuals who have experienced an amputation struggle with infections, abscesses, itching, and pain at the joint interface of their prosthetic. Unlike shaving or waxing, <strong>laser hair removal doesn’t damage the skin</strong>—eliminating the risk of <span className="d-none d-sm-inline">ingrown hairs, razor bumps, or other </span>skin conditions that would make wearing a prosthetic very uncomfortable. </p>
            <p>In fact, amputees who have laser hair removal tend to experience <strong>less itching, pain, irritation, pulling of hair, and surprisingly sweating</strong> in the treated area. <span className="d-none d-sm-inline">While a reduction in sweating is an unexpected benefit of laser hair removal treatments it does help reduce prosthetic slippage which can improve performance in recreational activities, work, and exercise.</span></p>
            <p className="mb-0">Have unanswered questions <span className="d-none d-sm-inline">about how laser hair removal can help manage skin issues related to your amputation</span>? Our friendly, knowledgeable staff are happy to answer any questions you may have. <Link className="milan-text-primary" to="#consult">Find out if laser hair removal is right for you at your free consultation.</Link></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LHRForAmputees