import React from 'react';
import SocialHeroContent from './SocialHeroContent';

export function SocialHero({ setShowSignUpModal }) {
    return (
        <section id='social-hero' className='hero position-relative py-0 overflow-hidden'>
            <div className='d-lg-none stack-hero-bg' />
            <div className='d-block hero-bg'>
                <SocialHeroContent setShowSignUpModal={setShowSignUpModal} />
            </div>
        </section>
    );
}

export default SocialHero;
