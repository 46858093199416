import React from "react";

export function TransHero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="bg-transparent-5 bg-lg-transparent p-4 p-lg-0 rounded">
              <h1 className="milan-text-primary smalltitle">Laser Hair Removal For Transgender Clients</h1>
              <p>
                Reveal your true self by permanently removing unwanted body hair with laser hair removal.<sup>&dagger;</sup>{" "}
                <span className="d-none d-md-inline">
                  With a nearly pain-free process and permanent results, you’ll have smooth, hairless skin in a matter of months.
                </span>
              </p>
              <ul className="d-none d-sm-block list-style-disc">
                <li>
                  Avoid embarrassing hair growth — <strong>treatments require the area to be cleanly shaven.</strong>
                </li>
                <li>
                  Nearly a pain free process. Our lasers are much <strong>less painful than electrolysis or waxing.</strong>
                </li>
                <li>
                  All treatments are <strong>performed by highly-trained medical professionals</strong> under the supervision of a medical doctor.
                </li>
                <li>
                  For clients <strong>preparing for phalloplasty or vaginoplasty</strong>, we can help you prepare by treating custom body areas based
                  on the recommendations of your surgeon.
                </li>
              </ul>
              <p className="mb-0">Never worry about shaving or waxing again.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TransHero;
