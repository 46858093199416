import React from 'react'
import { Link } from 'gatsby'

export function NotFoundHero() {
  return (
    <section className="full-section hero background">
      <div className="container-fluid">
        <div className="row">
        <div className="col milan-text-white text-center">
            <h3 className="subpointlg"><strong>Things Are Getting Hairy</strong></h3>
            <p className="mt-4 p-subhead">The page you are looking for doesn't exist.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
          <div><Link className="milan-btn milan-navy-btn mb-3" to="#consult">Ask A Question</Link></div>
          <div><Link className="milan-btn milan-navy-btn mb-3" to="/process/faqs/">FAQS</Link></div>
          <div><Link className="milan-btn milan-navy-btn mb-3" to="/specials/">Current Specials</Link></div>
        </div>
      </div>
    </section>
  )
}

export default NotFoundHero