import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export function UnlimitedPackagePcos() {
  return (
    <section className="milan-py background ocean-bg">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-lg-center">The Unlimited Package™</h2>
        <div className="row mt-4">
          <div className="col-lg-6 text-center">
            <StaticImage src="../../images/shared/Unlimited-Package.png" alt="The Unlimited Package with Lifetime Guarantee" className="mw-100" />
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <div className="bg-transparent-5 bg-sm-transparent p-3 p-sm-0 rounded">
              <p>Your results are guaranteed for life with our exclusive <Link to="/specials/#guarantee" className="milan-text-primary">Unlimited Package™</Link> (included, at no additional cost, with every area!). A stray or new hair shows up 6 months, or even 10 years down the road… we’ll take care of it permanently for you. No questions asked. No touch-up fees, EVER.</p>
              <p className="mb-0"><Link to="/specials/#guarantee" className="milan-text-primary">Learn why the Unlimited Package is perfect for managing your PCOS hair growth.</Link></p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
          <div><Link className="milan-btn milan-navy-btn text-uppercase mb-3" to="/specials/">CURRENT SPECIALS</Link></div>
          <div><Link className="milan-btn milan-navy-btn text-uppercase mb-3" to="/process/">Learn How It Works</Link></div>
          <div><Link className="milan-btn milan-navy-btn text-uppercase mb-3" to="/areas/"><span className="d-sm-none d-lg-inline">See Treatable</span> Body Areas</Link></div>
        </div>
      </div>
    </section>
  )
}

export default UnlimitedPackagePcos