import React from 'react'
import { Link } from '../../utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faSquareFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

export function SubMenu({ menuItem }) {

  return (
    <div className="sub-menu-wrapper">
      { /* menuItem ex: Locations -> subMenuItems : {All Locations, Contact Us, About Us, ...etc} */
        menuItem?.items &&
        <ul className="sub-menu ps-0">
          {
            menuItem.items.map((subMenuItem, x) => {
              return (
                <li key={x}> {/* Ex: <li>All Locations</li> */}
                  <Link to={subMenuItem.pathname}>
                    {subMenuItem.link}
                  </Link>
                  {
                    subMenuItem?.items &&
                    <>
                      <span className="caret-icon caret-icon--right"><FontAwesomeIcon icon={faAngleRight} /></span>
                      <div className="sub-sub-menu-wrapper">
                        <ul className={`sub-sub-menu ps-0 mt-lg-2 mx-2 mx-lg-0`}>
                          {
                            subMenuItem.items.map((subSubMenuItem, index) => (
                              <li key={index}>
                                <Link to={subSubMenuItem.pathname}>
                                  {subSubMenuItem.link === 'Facebook' ? <i className="me-2"><FontAwesomeIcon icon={faSquareFacebook} /></i> :
                                    subSubMenuItem.link === 'Instagram' ? <i className="me-2"><FontAwesomeIcon icon={faInstagram} /></i> :
                                      subSubMenuItem.link === 'TikTok' ? <i className="me-2"><FontAwesomeIcon icon={faTiktok} /></i> :
                                        null}
                                  {subSubMenuItem.link}
                                </Link>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </>
                  }
                </li>
              )
            })
          }
        </ul>
      }
    </div>
  )
}

export default SubMenu
