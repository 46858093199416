import React from 'react'
import { Link } from 'gatsby'

export function ManagingPcos() {
  return (
    <section id="managing-pcos" className="milan-py">
      <div className="container">
        <h2 className="subheadlg milan-text-primary"><span className='line-break'>How Laser Hair Removal Fits</span> <span className='line-break'>Into Managing Your PCOS</span></h2>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="p-3 bg-transparent-5 rounded">
              <p>While there is no cure for polycystic ovary syndrome, it’s important to manage your symptoms<span className="d-none d-sm-inline"> to lower your risk for long-term health problems like heart disease or fertility issues</span>. </p>
              <p>No two cases of PCOS are exactly the same, so it’s essential to work closely with your doctor to develop a plan to minimize the effects this disease has on your health. Most treatment plans include:</p>
              <ul className="list-style-disc">
                <li>Weight management</li>
                <li>Hormone therapy</li>
                <li>Fertility planning</li>
                <li>Hair removal</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 bg-transparent-5 rounded">
              <p>Following the plan developed by your personal physician will not only help you maintain your overall health, but it will also improve the results of your laser hair removal treatments. <span className="d-none d-sm-inline">Our lasers eliminate every hair treated, but unmanaged PCOS will cause more follicles to become “active” leading to new hair growth.</span> Laser hair removal is just one aspect of properly managing your PCOS symptoms.</p>
              <p>With your hair gone for good, you’ll have more time to focus on other preparations such as hitting your required poses perfectly!</p>
              <p>Have unanswered questions about how laser hair removal related to managing your PCOS? Our friendly, knowledgable staff are happy to answer any questions you may have.</p>
              <p className="mb-0"><Link to="#consult" className="milan-text-primary">Find out if laser hair removal is right for you at your free consultation.</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ManagingPcos