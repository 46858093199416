import React, { useRef } from 'react';
import { shaving_waxing_cost } from './utils/shaving_waxing_cost';
import { Formik, Form } from 'formik';
import { FormikControl } from '../forms';
// import { FaPlus, FaMinus } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

export function ShavingWaxingCalc({ isHero }) {
    const calcRef = useRef(null);
    const calculateCost = (formik) => {
        let totalCost = 0;
        formik.values.body_areas.forEach(
            (area) => (totalCost += (75 - formik.values.age) * shaving_waxing_cost[area][formik.values.method]),
        );
        const areasLength = formik.values.body_areas.length;
        const percentageFactor = areasLength <= 1 ? 0 : areasLength >= 5 ? 4 : areasLength - 1;
        return new Intl.NumberFormat().format(Math.floor(totalCost - (percentageFactor / 10) * totalCost));
    };
    const handleAgeChange = (method, formik) => {
        if (method === 'decrease') {
            if (formik.values.age > 18) {
                formik.setFieldValue('age', formik.values.age - 1);
            } else return;
        } else if (method === 'increase') {
            if (formik.values.age < 75) {
                formik.setFieldValue('age', formik.values.age + 1);
            } else return;
        }
    };
    return (
        <section className={`milan-py position-relative ${isHero ? 'hero' : ''}`}>
            <span id="shave-wax-calc" className="anchor-tag"></span>
            <div className="container">
                <Formik initialValues={{ method: 'shaving', body_areas: [], age: 35 }}>
                    {(formik) => {
                        return (
                            <Form id="shaving-waxing-cost" className="row gx-md-5 gx-xxl-3">
                                <div className="col-md-7 offset-lg-1 col-lg-6 col-xl-5 offset-xxl-2 col-xxl-4">
                                    <h2 className="subheadlg milan-text-primary text-center text-md-start">
                                        The Real Cost Of <br /> Shaving & Waxing
                                    </h2>

                                    <div className="row justify-content-center mt-md-4 mb-4 mb-md-5">
                                        <div className="col-md-6">
                                            <h6 className="text-center text-md-start mb-3 my-md-1">
                                                Hair removal method
                                            </h6>
                                        </div>
                                        <div className="col-md-6 text-center text-md-end">
                                            <FormikControl
                                                control="radioBtn"
                                                label="Shave"
                                                value="shaving"
                                                name="method"
                                                formik={formik}
                                            />
                                            <FormikControl
                                                control="radioBtn"
                                                label="Wax"
                                                value="waxing"
                                                name="method"
                                                formik={formik}
                                            />
                                        </div>
                                    </div>

                                    <div className="row gx-3 gx-md-1">
                                        <div className="col-12">
                                            <h6 className="text-center text-md-start mb-5 pb-3">Select your age</h6>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                type="button"
                                                className="age-btn"
                                                onClick={() => handleAgeChange('decrease', formik)}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                        </div>
                                        <div className="col slider-pt">
                                            <FormikControl control="rangeSlider" name="age" formik={formik} />
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                type="button"
                                                className="age-btn"
                                                onClick={() => handleAgeChange('increase', formik)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center mt-4 mt-md-5">
                                        <div className="col-12">
                                            <h6 className="text-center text-md-start mb-3">
                                                Select the body areas you wax or shave
                                            </h6>
                                        </div>
                                        <div className="col-12">
                                            <ul className="row g-2 checkboxes-wrapper mb-0 ps-0">
                                                <FormikControl
                                                    control="checkboxBtns"
                                                    name="body_areas"
                                                    options={shaving_waxing_cost}
                                                    formik={formik}
                                                    calcRef={calcRef}
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5 offset-xxl-1 col-xxl-4 position-relative">
                                    <span ref={calcRef} id="calc-anchor"></span>
                                    <div id="calc-outcome-wrapper" className="mt-4 mt-md-0">
                                        <div id="calc-outcome" className="text-center py-4 py-xl-5 px-3 px-xl-4">
                                            <h3 className="milan-text-primary subpointsm">
                                                Your Projected <br />{' '}
                                                <span>
                                                    {formik.values.method}
                                                    <strong> Expenses</strong>
                                                </span>
                                            </h3>
                                            <div className="milan-text-tur mb-2">
                                                <span className="d-inline-block mt-md-1 align-top fs-2 me-1">$</span>
                                                <span id="cost" className="p-oversized">
                                                    {formik.values.body_areas.length > 0
                                                        ? `${calculateCost(formik)}`
                                                        : '_ _ _ _'}
                                                </span>
                                            </div>
                                            <p className="px-xxl-3">
                                                Ready to stop wasting money on temporary results?{' '}
                                                <Link to="/specials/estimate" className="milan-text-primary">
                                                    Request a custom quote for
                                                </Link>{' '}
                                                laser hair removal.
                                            </p>
                                            <div className="text-center">
                                                <Link className="milan-btn-sm milan-cta-btn mt-2 px-5" to="#consult">
                                                    Book A Consult
                                                </Link>
                                            </div>
                                        </div>
                                        <div id="calc-disclaimer">
                                            <p className="mb-0 mt-3 milan-fp lh-base">
                                                Disclaimer: Estimated costs are calculated using average waxing prices
                                                and average costs associated with shaving along with the frequency of
                                                each hair removal method in a year. Lifetime is calculated using the
                                                national life expectancy of 75. All costs are approximate. Actual costs
                                                will vary.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </section>
    );
}

export default ShavingWaxingCalc;
