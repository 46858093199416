import React from "react";
import Modal from "react-bootstrap/Modal";
import { BodymapMenCarousel, BodymapWomenCarousel, BodymapNeutralCarousel } from "./";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal id="bodymap-modal" {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="modal-padding-bg p-3 p-md-4">
        <Modal.Header closeButton />
        <Modal.Body className="p-0">
          <span aria-hidden="true" />
          {props.menShow && <BodymapMenCarousel />}
          {props.womenShow && <BodymapWomenCarousel />}
          {props.neutralShow && <BodymapNeutralCarousel />}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export function BodymapHero() {
  const [modalShow, setModalShow] = React.useState(false);
  const [menShow, setMenShow] = React.useState(false);
  const [womenShow, setWomenShow] = React.useState(false);
  const [neutralShow, setNeutralShow] = React.useState(false);
  return (
    <section className="hero">
      <div className="container">
        <h1 className="text-center milan-text-primary">Body Maps</h1>
        <div className="row d-flex justify-content-around px-2">
          <button
            className="select-gender col-12 col-md-4 mt-3 rounded"
            onClick={() => {
              setModalShow(true);
              setNeutralShow(true);
            }}
          >
            <div className="row">
              <div className="col-6 col-md-12 d-flex align-items-center justify-content-center">
                <h2 className="text-center milan-text-primary subheadsm pt-3">Gender Neutral</h2>
              </div>
              <div className="col-6 col-md-12 bodies body3" />
            </div>
          </button>

          <button
            className="select-gender col-12 col-md-4 mt-3 rounded"
            onClick={() => {
              setModalShow(true);
              setWomenShow(true);
            }}
          >
            <div className="row">
              <div className="col-6 col-md-12 d-flex align-items-center justify-content-center">
                <h2 className="text-center milan-text-primary subheadsm pt-3">Women</h2>
              </div>
              <div className="col-6 col-md-12 bodies body2" />
            </div>
          </button>

          <button
            className="select-gender col-12 col-md-4 mt-3 rounded"
            onClick={() => {
              setModalShow(true);
              setMenShow(true);
            }}
          >
            <div className="row">
              <div className="col-6 col-md-12 d-flex align-items-center justify-content-center">
                <h2 className="text-center milan-text-primary subheadsm pt-3">Men</h2>
              </div>
              <div className="col-6 col-md-12 bodies body1" />
            </div>
          </button>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          menShow={menShow}
          womenShow={womenShow}
          neutralShow={neutralShow}
          onHide={() => {
            setModalShow(false);
            setMenShow(false);
            setWomenShow(false);
            setNeutralShow(false);
          }}
        />
      </div>
    </section>
  );
}

export default BodymapHero;
