import React from "react"
import { Link } from "gatsby"

export function BrazilianBikini() {
  return (
    <section id="areas-brazilian" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 milan-bg-tur milan-text-white py-milan-50 py-xl-milan-150">
            <div className="row">
              <div className="offset-xl-1 col-auto">
                <h2 className="subheadlg">Brazilian and Bikini Laser Hair Removal</h2>
              </div>
              <div className="col-12 offset-xl-1 z-index-100 order-2 order-md-1"> 
                <p>Your <Link to="/areas/bikini/">bikini</Link> line and <Link to="/areas/brazilian/">Brazilian</Link> require special maintenance every time you’re planning on baring it all in your swimwear or birthday suit. Don’t waste time on sticky appointments to master your unruly hair. We've got the only no-mess, gentle, permanent solution for your bikini area </p>
                <p>Now is the time to tame your wild bikini line with a gentle, permanent answer to never shaving again.</p>
                <p className="pb-4 pb-xl-0">Learn more about <Link to="/areas/brazilian/">Brazilian</Link> and <Link to="/areas/bikini/">bikini</Link> laser hair removal.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-7 vrt-divider vrt-divider-reversed vrt-divider-turq" />
        </div>
      </div>
      <div className="stack-hero-bg d-xl-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
    // <section id="areas-brazilian" className="milan-bg-tur">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-6 offset-xl-1 col-xl-5 milan-bg-tur milan-text-white milan-py-lg">
    //         <div className="row">
    //           <div className="col-auto">
    //             <h2 className="subheadlg">Brazilian and Bikini Laser Hair Removal</h2>
    //           </div>
    //           <div className="col-auto z-index-100">
    //             <p>Your <Link to="/areas/bikini/">bikini</Link> line and <Link to="/areas/brazilian/">Brazilian</Link> require special maintenance every time you’re planning on baring it all in your swimwear or birthday suit. Don’t waste time on sticky appointments to master your unruly hair. We've got the only no-mess, gentle, permanent solution for your bikini area </p>
    //             <p>Now is the time to tame your wild bikini line with a gentle, permanent answer to never shaving again.</p>
    //             <p className="">Learn more about <Link to="/areas/brazilian/">Brazilian</Link> and <Link to="/areas/bikini/">bikini</Link> laser hair removal.</p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-5 col-xl-6 vrt-divider vrt-divider-reversed vrt-divider-turq"></div>
    //     </div>
    //   </div>
    //   <div className="spacer d-lg-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    // </section>
  )
}
export default BrazilianBikini
