import React from 'react'
import { Link } from 'gatsby'

export function HidradenitisSuppurativa() {
  return (
    <section id="hidradenitis">
      <div className="container milan-py">
        <div className="row justify-content-lg-end">
          <div className="col-lg-7 col-xxl-6">
            <h2 className="subheadlg milan-text-primary">Best Hair Removal Option <br /> for Hidradenitis Suppurativa</h2>
            <p>Depending on your symptoms and the stage of your hidradenitis suppurativa, your physician may recommend laser hair removal as part of your <span className="d-none d-sm-inline">hidradenitis suppurativa</span> treatment plan. Laser hair removal has <strong>proven to be an ideal treatment</strong> for those with moderate and localized hidradenitis suppurativa.</p>
            <p><span className="d-none d-sm-inline">However, while not everyone’s hidradenitis suppurativa symptoms will improve with laser hair removal, most </span><span className="d-md-none">Most </span>medical professionals agree that <strong>laser hair removal is the safest method of hair removal for hidradenitis suppurativa sufferers.</strong> Traditional hair removal methods like shaving and waxing carry inherent risks<span className="d-none d-sm-inline"> for anyone struggling with hidradenitis suppurativa</span>. </p>
            <ul className="list-style-disc">
              <li><strong>Waxing.</strong><span className="d-none d-sm-inline"> Applying molten wax to your skin may inflame your symptoms and cause further irritation. It can also put you at a greater risk for infection if the entire hair isn’t removed when the strip rips off of your skin.</span><span className="d-sm-none"> May damage your skin inflaming your symptoms, causing further irritation and increasing your risk for infections.</span>.</li>
              <li><strong>Shaving.</strong><span className="d-none d-sm-inline"> While safer than waxing, shaving is also risky.</span> A simple nick from your razor—who hasn’t had one of those—can create new lesions or infections.<span className="d-none d-sm-inline"> Additionally, the damage the razor does to your skin may lead to a consistent flare-ups.</span></li>
              <li><strong>Laser Hair Removal.</strong> Unlike outdated hair removal methods, it removes unwanted hair at the root without damaging the surface of the skin. Allowing you to get rid of your unwanted hair without the risk of flare-ups, new lesions, or infections.</li>
            </ul>
            <p>For individuals who struggle with hidradenitis suppurativa, laser hair removal is the <strong>safest and most effective way</strong> to say goodbye to unwanted hair. PERMANENTLY.</p>
            <p className="mb-0"><Link className="milan-text-primary" to="#consult">Schedule a free consultation today to find out if laser hair removal is right for you.</Link></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HidradenitisSuppurativa