import React from "react"
import { Link } from 'gatsby'

export function AboutLHR() {
  return (
    <section id="about-lhr" className="milan-bg-tur beach-bg-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xxl-6 vrt-divider vrt-divider-turq-alt"></div>
          <div className="col-lg-7 col-xxl-6 milan-bg-tur milan-text-white milan-py-lg">
            <div className="row">
              <div className="col">
                <h2 className="subheadlg">About Laser Hair Removal</h2>
                <p>Laser hair removal is a popular alternative to waxing and shaving. This is largely due to the fact that laser hair removal provides permanent results<sup>&dagger;</sup> and is <Link to="/lp/cost/">less expensive than maintaining a waxing and shaving routine</Link> for a lifetime. Additionally, there’s no recovery time needed after a treatment—so no more doing the waxing-waddle or battling razor burn.</p>
                <p className="mb-0">Our treatments are safe, and all of our procedures are performed by Authorized Candela Practitioners. We use <Link to="/process/">FDA-cleared lasers</Link> that are tailored to your <Link to="/areas/#tone">specific skin type &amp; hair color</Link>. </p>
              </div>
            </div>
            <div className="row gy-3 gx-lg-2 gx-xl-4 pb-4 pb-md-0 pt-5">
              <div className="col-12 col-md-auto text-center z-index-100"><Link className="milan-btn milan-navy-btn" to="/process/">The Process</Link></div>
              <div className="col-12 col-md-auto text-center z-index-100"><Link className="milan-btn milan-navy-btn" to="/process/faqs/">Laser Hair Removal FAQs</Link></div>
              <div className="col-12 col-md-auto text-center z-index-100"><Link className="milan-btn milan-navy-btn" to="/locations/about-us/">About Milan Laser</Link></div>
            </div>
          </div>
        </div>
      </div>
      <div className="background spacer d-lg-none hr-divider hr-divider-reversed hr-divider-turq-light"></div>
    </section>
  );
}
export default AboutLHR
