import React from "react";
const valuesLogoCollab = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-collaboration.svg?alt=media&token=e9061aab-c91b-4ff5-923f-db6cfa69caf7";
const valuesLogoConsis = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-consistency.svg?alt=media&token=eddbe924-7b67-497d-96c8-3fe80ec7db90";
const valuesLogoPos = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-positivity.svg?alt=media&token=2b19c8e7-4a1d-488b-8329-b876f1729b42";
const valuesLogoPride = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-pride.svg?alt=media&token=2486fb18-48f2-4dcc-b288-ef7816ad2e6f";
const valuesLogoRespect = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-respect.svg?alt=media&token=292bdbc0-2949-4d6c-b51c-edc63a5ea4f8";
const valuesLogoTrust = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-trust.svg?alt=media&token=49e17afe-18e3-48ec-be52-67abe727d4c1";
const valuesLogoWow = "https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fcareers%2Fvalues-logo-wowCustomers.svg?alt=media&token=89719e6f-a663-47d6-9f04-ea06d77e2705";

export function CareersValues() {
  return (
    <section id="our-values" className="our-values-bg d-flex align-items-center py-3">
      <div className="container section-width text-white">
        <h2 className="text-center pb-3 pb-5-sm subheadlg">Our Values</h2>
        <p className="pb-4">Our core values govern the manner in which we work together, conduct our business and make decisions.</p>
        <div className="row text-center values-logos">
          <div className="col col-md-3 pb-4">
            <img src={valuesLogoWow} alt="values wow logo"></img>
            <div>
              <p className="pt-2 mb-0"><strong>WOW CUSTOMERS</strong></p>
              <p>With world-class customer service</p>
            </div>
          </div>
          <div className="col-12 col-md-3 pb-4">
            <img src={valuesLogoTrust} alt="values trust logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>TRUST</strong></p>
              <p>In each other and follow through on commitments.</p>
            </div>
          </div>
          <div className="col-12 col-md-3 pb-4">
            <img src={valuesLogoCollab} alt="values collaboration logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>COLLABORATION</strong></p>
              <p>To build relationships and fuel innovation.</p>
            </div>
          </div>
          <div className="col-12 col-md-3 pb-4">
            <img src={valuesLogoConsis} alt="values consistency logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>CONSISTENCY</strong></p>
              <p>In the service and products we provide.</p>
            </div>
          </div>
          <div className="col-12 col-md-4 pt-md-3 pb-4 pb-md-0">
            <img src={valuesLogoRespect} alt="values respect diversity logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>RESPECT DIVERSITY</strong></p>
              <p>And treat each other with dignity.</p>
            </div>
          </div>
          <div className="col-12 col-md-4 pt-md-3 pb-4 pb-md-0">
            <img src={valuesLogoPos} alt="values positivity logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>POSITIVITY</strong></p>
              <p>And fun are encouraged and embraced.</p>
            </div>
          </div>
          <div className="col-12 col-md-4 pt-md-3 pb-md-0">
            <img src={valuesLogoPride} alt="values pride logo"></img>
            <div>
            <p className="pt-2 mb-0"><strong>PRIDE</strong></p>
              <p>In what we do and who we are.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareersValues;
