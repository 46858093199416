import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export function BA_Preview_Link({ area }) {
  return (
    <div className="col-sm-6 col-md-4 mb-3 px-2">
      <Link
        to={`/process/beforeafter/beforeafter_${area.area}/`}
        className="d-block text-center">
        {/* Desktop */}
        <GatsbyImage
          imgClassName="img-thumbnail p-1"
          className="d-none d-sm-block"
          image={getImage(area.preview_link_icon.desktop.node)}
          alt={`Before and after preview for ${area.area}`} />
        {/* Mobile */}
        <figure className="d-inline-block mb-0 position-relative d-sm-none">
          <GatsbyImage
            image={getImage(area.preview_link_icon.mobile.node)}
            alt={`Before and after preview for ${area.area}`}
            objectFit="contain" />
          <h5 className="b-a-label position-absolute milan-text-navy text-uppercase">
           {area.caption}
          </h5>
        </figure>
      </Link>
      <Link
        to={`/process/beforeafter/beforeafter_${area.area}/`}
        className="milan-btn milan-primary-btn my-3 w-100 d-none d-sm-block">
        {area.caption}
      </Link>
    </div>
  );
}
export default BA_Preview_Link;
