import React from "react";
import { FooterSocialIcons } from "../../../components";

// import { FACEBOOK_URL_START } from "../../../utils";
// import FB_ICON_WHITE from "../../../images/shared/Facebook_Icon_White.png";
// import TIKTOK_ICON_WHITE from "../../../images/shared/Tiktok_Icon_White.png";
// import TWITTER_ICON_WHITE from "../../../images/shared/Twitter_Icon_White.png";
// import INSTA_ICON_WHITE from "../../../images/shared/Instagram_Icon_White.png";

export function SocialMediaDivider({ globalData }) {
  return (
    <section className="w-100 py-3 divider-section milan-bg-navy social-media-divider">
      <div className="container">
        <div className="row milan-text-white text-center">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <h3 className="subpointsm mb-0 px-3 px-sm-5">Follow Us</h3>
            <FooterSocialIcons/>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SocialMediaDivider;