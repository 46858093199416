import React from "react";
import { Link } from "gatsby";

export function ActiveLifestyleConfident() {
  return (
    <section id="activelifestyle-confident">
      <div className="container d-flex h-100 milan-py-lg">
        <div className="row align-self-center">
          <div className="col offset-lg-6 col-lg-6">
            <div className="row">
              <div className="col">
                <h2 className="subheadlg milan-text-primary">Get more confident</h2>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col col-xl-10">
                <p>
                  Add another mile to your run or extra laps in the pool when you’re feeling your hair-free best. Stubble, nicks, and razor burn are
                  unsightly and can distract from your busy routine. See the difference laser hair removal makes—check out <Link to="/process/beforeafter" className="milan-text-primary text-decoration-none"><strong>real client photos!</strong></Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer d-lg-none"></div>
    </section>
  );
}
export default ActiveLifestyleConfident;
