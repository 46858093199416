import React from 'react'
// import { FaInstagram, FaFacebookSquare, FaTiktok } from 'react-icons/fa'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faSquareFacebook, faTiktok } from "@fortawesome/free-brands-svg-icons";


export function SocialMedia() {
  return (
    <section id="social" className="milan-py background">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-center">Milan Laser Social Media</h2>
        <div className="row gy-4 mt-1">
          <div className="col-md-6 col-lg-4">
            <div id="facebook">
              <p className="p-subhead text-center">
                <FontAwesomeIcon icon={faSquareFacebook} className="me-1 fs-2" /> See Us on Facebook
              </p>
              <div className="embedsocial-hashtag" data-ref="9a407aeec4a9ee5da7366e15a039dfe897b21b20" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div id="tiktok">
              <p className="p-subhead text-center">
                <FontAwesomeIcon icon={faTiktok} className="me-1 fs-2" /> See Us on TikTok
              </p>
              <div className="embedsocial-hashtag" data-ref="cbecc999c626f9d99ecd153174f025b7295b72cb" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div id="instagram">
              <p className="p-subhead text-center">
                <FontAwesomeIcon icon={faInstagram} className="me-1 fs-2" /> See Us on Instagram
              </p>
              <div className="embedsocial-hashtag" data-ref="c016e7ff10d133a877eb187294c7f917da2fd1f7" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SocialMedia

