import React from "react";

export function LegsHero() {
  return (
    <section className="hero pt-milan-50 pb-0 py-md-milan-100 py-xl-milan-150 milan-bg-white">
      <div className="container">
        <div className="row">
          <div className="offset-md-6 col-md-6 offset-lg-5 col-lg-7">
            <h1 className="milan-text-primary">Laser Hair Removal For Legs</h1>
            <p>Ready to stop torturing your legs with razors, hot wax, and depilators? You’ve come to the right place!</p>
            <div className="d-none d-sm-block">
              <p>Laser hair removal&#8230;</p>
              <ul className="list-style-disc">
                <li>
                  {/* is the real <strong>never shave again</strong> (as in never ever) solution. */}
                  is the real <strong>never-shave-again</strong> solution
                </li>
                <li>
                  {/* targets all of your leg hair <strong>without the pain of waxing or risk of itchy razor burn.</strong> */}
                  removes leg hair <strong>without the pain of waxing or razor burn from shaving</strong>
                </li>
                <li>
                  {/* is <strong>less expensive</strong> than maintaining a shaving/waxing routine. */}
                  is <strong>less expensive</strong> than maintaining a lifetime of shaving and waxing
                </li>
              </ul>
            </div>
            <p className="mb-0">Get ready for the smooth, hair-free legs you've always wanted.</p>
          </div>
        </div>
      </div>
      {/* bg when stacked*/}
      <div className="d-md-none stack-hero-bg" />
    </section>
  );
}

export default LegsHero;