import React from 'react';
import { Layout } from './src/templates';
import { GlobalContextProvider } from './src/context';

// To navigate to the right element // Usefull when searching

// export function onRouteUpdate({ location, prevLocation }) {
//     if (prevLocation && prevLocation.pathname !== location.pathname && prevLocation?.hash !== location.hash) {
//         scrollToAnchor(location);
//     }
// }

// function scrollToAnchor(location) {
//     // Check for location so build does not fail
//     if (location && location.hash) {
//         // Make sure there's an element with that id // Otherwise, it will throw an error
//         const item = document.querySelector(`${location.hash}`);
//         if (item) {
//             navigate(location.pathname + location.hash);
//         }
//     } else {
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//     }
//     return true;
// }

// export const onRouteUpdate = ({ location }) => {
//     const hash = location.hash;
//     if (hash) {
//         const id = location.hash.replace('#', '');
//         const element = document.getElementById(id); //this will be undefined, for the builder pages because of the delay in getting the page data
//         // if the element exists, scroll to it, else scroll to the top of the page
//         if (element) {
//             console.log({ element });
//             return element?.scrollIntoView({ behavior: 'smooth' });
//         }
//     } else {
//         window.scrollTo(0, 0);
//     }
// };

//shouldUpdateScroll makes it so when navagating to a new page, it'll always go to the top
//It also still keeps the functionality when the link directs to a specific section in a page.

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//     console.log('shouldUpdateScroll');
//     const currentPosition = getSavedScrollPosition(location);
//     if (location.action === 'PUSH' && location.hash) {
//         setTimeout(() => {
//             const id = location.hash.substring(1);
//             const element = document.getElementById(id);
//             //checks to see if there is an element on the page with the ID specified in the URL hash
//             if (element) {
//                 element.scrollIntoView({ behavior: 'smooth' });
//             }
//         }, 0);
//     } else {
//         setTimeout(() => {
//             window.scrollTo(...(currentPosition || [0, 0]));
//         }, 0);
//     }
//     return false;
// };

// ----------------------------------------------

export function wrapPageElement({ element, props }) {
    return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
    return <GlobalContextProvider>{element}</GlobalContextProvider>;
}
