import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import FIRST_TREATMENT_ICON from '../../../images/process/home/icon-first-treatment.webp'

export function YourFirstTreatment() {
  return (
    <section id="your-first-treatment" className="background bg-center position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="arrow-left" src="../../../images/process/home/arrow-left.png" imgClassName="middle-icon" />
        <div className="row">
          <div className="col-md-6 border-right">
            <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">             
            <h3 className="subpointlg">Your First</h3>
                <h3 className='subpointsm'>Treatment</h3>
              <p className="mb-2 py-4">We’re so excited for you! Here’s how your first treatment will go:</p>
              <ul className="ps-4 text-start">
                <li>A temporary grid will be drawn on the treatment area to ensure accuracy &amp; coverage.</li>
                <li>Safety goggles will be provided.</li>
                <li>Your provider will adjust the laser to the exact specifications.</li>
                <li>Our laser uses a cooling technology to provide the most comfortable treatments possible.</li>
                <li>Post-treatment instructions are provided.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
              <img alt="Milan Laser Hair Removal Process - Your First Treatment" src={FIRST_TREATMENT_ICON} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default YourFirstTreatment