import React, { useState } from 'react'
import { useDynamicMenu, useAlgolia } from '../../hooks'
import { CORPORATE_MENU } from '.'
import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'
import SearchContainer from './SearchContainer'
import MobileMenu from './MobileMenu'

import './Header.css'

export function Header({ siteData }) {
  const [isOpen, setIsOpen] = useState(false)
  const dynamicMenu = useDynamicMenu(siteData, CORPORATE_MENU)

  // Algolia Search
  const [searchView, setSearchView] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const searchHandler = e => setSearchInput(e.target.value)
  const hits = useAlgolia(searchInput, siteData.milanStores)

  return (
    <header>
      <HeaderTop {...{ isOpen, setIsOpen }} />
      <HeaderBottom menu={dynamicMenu} {...{ searchView, setSearchView }} />
      <SearchContainer {...{ hits, searchInput, searchView, setSearchView, searchHandler }} />
      <MobileMenu
        {...{ searchInput, hits, searchHandler, isOpen }}
        menu={dynamicMenu}
        closeMenu={() => setIsOpen(false)} />
    </header>
  )
}

export default Header
