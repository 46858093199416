import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { PressCustomToggle, PressMilanMediaContCard } from '.';
import { pressMilanMediaContList } from './util/pressMilanMediaContList';
import { BREAKPOINTS } from '../../utils/constants';
import { useBreakpoint } from 'use-breakpoint';

// Milan In The Media Cont.
export function PressMilanMediaCont() {
  const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs');
  let numToShow =
    minWidth >= BREAKPOINTS.lg && minWidth < BREAKPOINTS.xl ? 3 : 4;
  const mediaShow = pressMilanMediaContList.slice(0, numToShow);
  const mediaHidden = pressMilanMediaContList.slice(numToShow);

  return (
    <section id='press-releases' className='milan-bg-secondary py-5'>
      <div className='container'>
        <h2 className='subheadlg'>Milan In The Media Cont.</h2>
        {/* first we put the first videos based on screen size */}
        <Accordion defaultActiveKey='1'>
          <div className='row'>
            {mediaShow.map((media, i) => (
              <PressMilanMediaContCard media={media} key={i} />
            ))}
            {/* after the first set, it will be collapsable */}
            <Accordion.Collapse eventKey='0'>
              <div className='row justify-content-md-center'>
                {mediaHidden.map((media, i) => (
                  <PressMilanMediaContCard media={media} key={i} />
                ))}
              </div>
            </Accordion.Collapse>
          </div>
          <PressCustomToggle />
        </Accordion>
      </div>
    </section>
  );
}

export default PressMilanMediaCont;
